// extracted by mini-css-extract-plugin
export var actionBtn = "EditEvent-module--actionBtn--Ll4ox";
export var container = "EditEvent-module--container--vNY+5";
export var dishDescription = "EditEvent-module--dishDescription--JAjAL";
export var form = "EditEvent-module--form--4gFLl";
export var formFeaturedImg = "EditEvent-module--formFeaturedImg--6zkvy";
export var formFooter = "EditEvent-module--formFooter--kv3mV";
export var header = "EditEvent-module--header--ACMHl";
export var infoSection = "EditEvent-module--infoSection--ET4uU";
export var section = "EditEvent-module--section--UdytV";
export var sectionHeading = "EditEvent-module--sectionHeading--BecKd";
export var tabContent = "EditEvent-module--tabContent--PtXBh";
export var tabs = "EditEvent-module--tabs--2ruzm";
export var wrapper = "EditEvent-module--wrapper--Tg8sV";