import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextInput from "components/forms/TextInput"
import { ModalContext } from "context/modal/ModalContext"
import { ArrayHelpers, FieldArray } from "formik"
import React, { useContext, useState } from "react"

//@ts-ignore
import * as styles from "./EditElements.module.css"
import MeasureSelect from "./MeasureSelect"
import OtherIngredientInfo from "./ElementInfo/OtherIngredientInfo"
import ProductInfo from "./ElementInfo/ProductInfo"
import { formatCurrencyValue } from "services/helpers"
import ProductSelectModal from "./modals/ProductSelectModal"
import RecipeSelectModal from "./modals/RecipeSelectModal"
import RecipeInfo from "./ElementInfo/RecipeInfo"
import { RecipeContainer } from "services/dishes/types"
import { EventElementType } from "services/events/types"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import {
  faExclamationCircle,
  faPlus,
  faTrashAlt,
  faPencil,
} from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"
import DishSelectModal from "./modals/DishSelectModal"
import DishInfo from "./ElementInfo/DishInfo"

const itemTypes: Record<string, any> = {
  INGREDIENT: {
    type: "INGREDIENT",
    ingredient: {
      addedQty: 0,
      consumedCost: undefined,
      consumedQty: 0,
      cost: undefined,
      initialQty: 0,
      product: {
        name: "",
        barcode: "",
        category: "",
        subCategory: "",
        measure: "L",
        size: 0,
        price: 0,
        supplierName: "",
        supplierId: "",
      },
      totalCost: undefined,
      wasteMeasure: "L",
      wasteQty: 0,
    },
  },
  RECIPE: {
    type: "RECIPE",
    recipe: {
      addedQty: 0,
      consumedCost: undefined,
      consumedQty: 0,
      cost: undefined,
      initialQty: 0,
      recipe: {
        name: "recipe",
        barcode: "",
        category: "",
        subCategory: "",
        measure: "each",
        size: 0,
        totalCost: 0,
      },
      totalCost: undefined,
      wasteMeasure: "L",
      wasteQty: undefined,
    },
  },
  DISH: {
    type: "DISH",
    addedQty: 0,
    consumedCost: undefined,
    consumedQty: 0,
    cost: undefined,
    initialQty: 0,
    dish: {
      name: "dish",
      barcode: "",
      category: "",
      subCategory: "",
      measure: "each",
      size: 0,
      totalCost: 0,
    },
    totalCost: undefined,
    wasteMeasure: "each",
    wasteQty: undefined,
  },
  OTHER_INGREDIENT: {
    type: "OTHER_INGREDIENT",
    otherIngredient: {
      cost: 0,
      measure: "L",
      name: "",
      size: 0,
    },
  },
}

const PriceSelect = ({ element, path, disabled = false }) => (
  <div>
    {/*<p>Cost</p>*/}
    <div className="form-group">
      <div className="input-container">
        <TextInput
          required={true}
          name={`${path}.cost`}
          label="Cost"
          type="text"
          disabled={disabled}
          value={element.cost}
          showValidations={false}
          placeholder="Cost"
          className="form-control form-control--smaller form-control--first form-control--last"
        />
      </div>
    </div>
  </div>
)

const decreaseFullQuantity = (prevQty) => {
  return prevQty > 0 ? Number(prevQty) - 1 : 0
}

const increaseFullQuantity = (prevQty) => {
  return Number(prevQty ?? 0) + 1
}

const QuantityInput = ({
  elementPath,
  elementProps,
  elementValueKey,
  onChange,
  disabled = false,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  return (
    <>
      <span
        className={` text-right mb-1  ${
          editMode
            ? "text-sm mb-1 text-gray-700"
            : "text-xs text-gray-600 uppercase font-semibold font-sansSemiBold"
        }`}
      >
        Added
      </span>
      <button
        type="button"
        onClick={() => setEditMode(!editMode)}
        className={`${editMode ? "hidden" : ""} p-1`}
      >
        {!elementProps[elementValueKey] ? (
          <FontAwesomeIcon
            icon={faPlus}
            className="text-primaryGreenDarker"
            size="lg"
          />
        ) : (
          <span className="flex items-center">
            <span className="mr-2 border-b font-sansSemiBold font-semibold border-dashed border-primaryBlueLighter">
              {elementProps[elementValueKey]}
            </span>
            <FontAwesomeIcon icon={faPencil} className="text-primaryGray" />
          </span>
        )}
      </button>
      <div
        className={`${styles.inputCol} ${!editMode ? styles.hideControl : ""}`}
      >
        <button
          className={styles.roundButton}
          type="button"
          onClick={() => {
            const newQty = decreaseFullQuantity(elementProps[elementValueKey])
            onChange(newQty)
          }}
          disabled={disabled}
        >
          -
        </button>
        <TextInput
          name={`${elementPath}.${elementValueKey}`}
          label={elementValueKey}
          type="number"
          step="any"
          disabled={disabled}
          value={elementProps[elementValueKey]}
          showValidations={false}
          placeholder="XX"
          className={styles.textInput}
        />
        <button
          className={styles.roundButton}
          type="button"
          onClick={() => {
            const newQty = increaseFullQuantity(elementProps[elementValueKey])
            onChange(newQty)
          }}
          disabled={disabled}
        >
          +
        </button>
      </div>
    </>
  )
}

const WasteInput = ({
  element,
  elementPath,
  sizeValueKey,
  measureValueKey,
  disabled = false,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  return (
    <>
      <span
        className={` text-right mb-1  ${
          editMode
            ? "text-sm mb-1 text-gray-700"
            : "text-xs text-gray-600 uppercase font-semibold font-sansSemiBold"
        }`}
      >
        Waste
      </span>
      <button
        type="button"
        onClick={() => setEditMode(!editMode)}
        className={`${editMode ? "hidden" : ""} p-1`}
      >
        {!element[sizeValueKey] ? (
          <FontAwesomeIcon
            icon={faPlus}
            className="text-primaryOrange"
            size="lg"
          />
        ) : (
          <span className="flex items-center">
            <span className="mr-2 border-b font-sansSemiBold font-semibold border-dashed border-primaryBlueLighter">
              {element[sizeValueKey]} {element[measureValueKey] || ""}
            </span>
            <FontAwesomeIcon icon={faPencil} className="text-primaryGray" />
          </span>
        )}
      </button>

      <div className={`${!editMode ? "hidden" : ""}`}>
        <MeasureSelect
          element={element}
          path={elementPath}
          isRequired={false}
          sizeValueKey={sizeValueKey}
          measureValueKey={measureValueKey}
          disabled={disabled}
        />
      </div>
    </>
  )
}

const PriceDisplay = ({ element, elementKey }) => {
  const cost = element[elementKey]
  if (cost !== undefined) {
    return (
      <div>
        <div className={styles.costField}>{formatCurrencyValue(cost)}</div>
      </div>
    )
  } else {
    return (
      <div className="text-xs text-orange-600 table-cel flex flex-col justify-center">
        Save to view
      </div>
    )
  }
}

const typesToProps: Record<EventElementType, string> = {
  [EventElementType.INGREDIENT]: "ingredient",
  [EventElementType.RECIPE]: "recipe",
  [EventElementType.DISH]: "dish",
  [EventElementType.OTHER_INGREDIENT]: "otherIngredient",
}

const elementPickers: Record<
  EventElementType,
  React.FunctionComponent<any> | undefined
> = {
  [EventElementType.RECIPE]: RecipeSelectModal,
  [EventElementType.INGREDIENT]: ProductSelectModal,
  [EventElementType.DISH]: DishSelectModal,
  [EventElementType.OTHER_INGREDIENT]: undefined,
}

export default ({
  name,
  elements,
  disabled,
}: {
  name: string
  elements: Array<EventElementType>
  disabled?: boolean
}) => {
  const modal = useContext(ModalContext)

  const pick = (type, multiSelectEnabled = true): Promise<any> =>
    new Promise((onSelect) =>
      modal.showModal(elementPickers[type], {
        onSelect,
        multiSelect:
          multiSelectEnabled !== undefined ? multiSelectEnabled : false,
        qtyPicker: false,
      })
    )

  const updateProduct = (
    arrayHelpers: ArrayHelpers,
    index,
    fullProduct,
    updatedProduct
  ) => {
    const item = JSON.parse(JSON.stringify(fullProduct))
    item.ingredient.product = updatedProduct
    arrayHelpers.replace(index, item)
  }

  const changeProduct =
    (arrayHelpers: ArrayHelpers, index, fullProduct) => () =>
      pick("INGREDIENT", false).then((product) => {
        updateProduct(arrayHelpers, index, fullProduct, product[0])
      })

  const changeRecipe = (arrayHelpers: ArrayHelpers, index, fullProduct) => () =>
    pick("RECIPE", false).then((product) => {
      const item = JSON.parse(JSON.stringify(fullProduct))
      item.recipe.recipe = product
      arrayHelpers.replace(index, item)
    })

  const changeDish = (arrayHelpers: ArrayHelpers, index, fullProduct) => () =>
    pick("DISH", false).then((dish) => {
      const item = JSON.parse(JSON.stringify(fullProduct))
      item.dish.dish = dish
      item.dish.wasteMeasure = "each"
      arrayHelpers.replace(index, item)
    })

  const CreateButtons = ({
    arrayHelpers,
    index,
    label = undefined,
    buttonClass,
    title = undefined,
    dropDirection = "top-right",
    containerClass = "m-0",
    disabled = false,
  }: {
    arrayHelpers: ArrayHelpers
    index: number
    label?: string | React.JSX.Element
    buttonClass: string
    title?: string
    dropDirection?: "top-right" | "top-left" | undefined
    containerClass?: string
    disabled?: boolean
  }) => {
    const functions = {
      addOtherIngredient: (arrayHelpers, index) => {
        arrayHelpers.insert(index, itemTypes.OTHER_INGREDIENT)
      },
      addRecipe: (arrayHelpers, index) =>
        pick("RECIPE").then((recipes) => {
          recipes.forEach((recipe, innerIndex) => {
            const item: RecipeContainer = JSON.parse(
              JSON.stringify(itemTypes.RECIPE)
            )
            item.recipe.recipe = recipe
            arrayHelpers.insert(index + innerIndex, item)
          })
        }),

      addProduct: (arrayHelpers, index) =>
        pick("INGREDIENT").then((products) => {
          products.forEach((product, innerIndex) => {
            const item = JSON.parse(JSON.stringify(itemTypes.INGREDIENT))
            item.ingredient.product = product
            //Setting original product measure to object property
            if (product.measure) item.ingredient.measure = product.measure
            arrayHelpers.insert(index + innerIndex, item)
          })
        }),
      addDish: (arrayHelpers, index) =>
        pick("DISH").then((dishes) => {
          dishes.forEach((dish, innerIndex) => {
            const item = JSON.parse(JSON.stringify(itemTypes.DISH))
            item.dish.dish = dish
            item.dish.wasteMeasure = "each"
            arrayHelpers.insert(index + innerIndex, item)
          })
        }),
    }
    return (
      <DropdownButton
        containerClass={containerClass}
        options={[
          {
            title: "Product",
            key: "addProduct",
            disabled: disabled,
          },
          {
            title: "Recipe",
            key: "addRecipe",
            disabled: disabled,
          },
          {
            title: "Dish",
            key: "addDish",
            disabled: disabled,
          },
          {
            title: "Other ingredient",
            key: "addOtherIngredient",
            disabled: disabled,
          },
        ]}
        label={label}
        title={title}
        dropDirection={dropDirection}
        buttonClass={buttonClass}
        mobileIcon={faPlus}
        onActionClick={({ type }) => {
          functions[type](arrayHelpers, index)
        }}
      />
    )
  }

  return (
    <FieldArray
      name={`${name}.elements`}
      render={(arrayHelpers) => (
        <div className="w-full pl-0 md:pl-4 pt-2">
          {elements.map((element: any, index) => {
            const type = element.type
            const elementPropsPath = typesToProps[type]
            const elementPath = [
              name,
              "elements",
              index,
              elementPropsPath,
            ].join(".")
            const elementProps = element[elementPropsPath]

            return (
              <div
                className={styles.ingredientContainer}
                key={`Product-${element?.ingredient?.product?.id}-${index}`}
              >
                <div className={`${styles.info} ${styles.column}`}>
                  <div className="flex items-center">
                    {element?.ingredient?.product?.isDeleted && (
                      <>
                        <span
                          data-for="deleted_product_tooltip"
                          data-tip="This ingredient is deleted."
                        >
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            size="lg"
                            className="text-error mr-px"
                          />
                        </span>

                        <ReactTooltip
                          id={"deleted_product_tooltip"}
                          type="light"
                          place="right"
                          effect="float"
                          border={true}
                          borderColor="#e2e8f0"
                        />
                      </>
                    )}

                    {
                      {
                        INGREDIENT: (
                          <ProductInfo
                            element={elementProps}
                            change={() =>
                              changeProduct(arrayHelpers, index, element)
                            }
                          />
                        ),
                        RECIPE: (
                          <RecipeInfo
                            element={elementProps}
                            change={() =>
                              changeRecipe(arrayHelpers, index, element)
                            }
                          />
                        ),
                        DISH: (
                          <DishInfo
                            element={elementProps}
                            change={() =>
                              changeDish(arrayHelpers, index, element)
                            }
                          />
                        ),
                        OTHER_INGREDIENT: (
                          <OtherIngredientInfo
                            path={elementPath}
                            disabled={disabled}
                            element={elementProps}
                          />
                        ),
                      }[type]
                    }
                  </div>
                </div>
                <div className={`${styles.measure} ${styles.column}`}>
                  <span className="text-sm mb-1 text-gray-700">
                    Size & measure
                  </span>
                  <MeasureSelect
                    disabled={disabled}
                    path={elementPath}
                    sizeValueKey="size"
                    measureValueKey="measure"
                    element={elementProps}
                    onUpdate={(data) => {
                      updateProduct(arrayHelpers, index, element, data)
                    }}
                  />
                </div>

                {type !== "OTHER_INGREDIENT" && (
                  <div className={`${styles.initialQty} ${styles.column}`}>
                    <span className="text-sm mb-1 text-gray-700">
                      Initial quantity
                    </span>
                    <div className="input-container" style={{ width: "5rem" }}>
                      <TextInput
                        required={true}
                        name={`${elementPath}.initialQty`}
                        label="Initial quantity"
                        type="number"
                        step="any"
                        disabled={disabled}
                        value={elementProps?.initialQty}
                        showValidations={false}
                        placeholder="XX"
                        className="form-control form-control--smaller form-control--first form-control--last"
                      />
                    </div>
                  </div>
                )}

                {type === "OTHER_INGREDIENT" ? (
                  <div className={`${styles.costEdit} ${styles.column}`}>
                    <span className="text-sm mb-1 text-gray-700">Cost</span>
                    <PriceSelect
                      disabled={disabled}
                      path={elementPath}
                      element={elementProps}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.costDisplay} ${styles.column} items-start`}
                    >
                      <QuantityInput
                        elementPath={elementPath}
                        elementValueKey="addedQty"
                        elementProps={elementProps}
                        disabled={disabled}
                        onChange={(newQty) => {
                          const itemType = type.toLowerCase()
                          const item = JSON.parse(JSON.stringify(element))
                          item[itemType] = {
                            ...item[itemType],
                            addedQty: newQty,
                          }
                          arrayHelpers.replace(index, item)
                        }}
                      />
                    </div>
                    <div
                      className={`${styles.costDisplay} ${styles.column} items-start`}
                    >
                      <WasteInput
                        element={elementProps}
                        elementPath={elementPath}
                        sizeValueKey="wasteQty"
                        measureValueKey={"wasteMeasure"}
                        disabled={disabled}
                      />
                    </div>
                    <div
                      className={`${styles.costDisplay} ${styles.column} items-end`}
                    >
                      <span className="text-xs text-right font-semibold font-sansSemiBold uppercase mb-1 text-gray-600">
                        Consumed
                      </span>
                      <PriceDisplay
                        element={elementProps}
                        elementKey={"consumedCost"}
                      />
                    </div>
                    <div
                      className={`${styles.costDisplay} ${styles.column} items-end`}
                    >
                      <span className="text-xs text-right font-semibold font-sansSemiBold uppercase mb-1 text-gray-600">
                        Cost
                      </span>
                      <PriceDisplay
                        element={elementProps}
                        elementKey={elementProps?.cost ? "cost" : "totalCost"}
                      />
                    </div>
                  </>
                )}

                <div className={styles.itemActions}>
                  <button
                    type="button"
                    className={styles.itemRemoveButton}
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={disabled}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>

                  <CreateButtons
                    arrayHelpers={arrayHelpers}
                    index={index + 1}
                    buttonClass={styles.itemActionsButton}
                    disabled={disabled}
                    title={"Add item below:"}
                    containerClass={styles.itemActionsButtonContainer}
                  />
                </div>
              </div>
            )
          })}
          {elements.length === 0 && (
            <div>
              <div className={styles.noIngredients}>No ingredients yet.</div>
              <CreateButtons
                arrayHelpers={arrayHelpers}
                index={0}
                label={
                  <>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                    Ingredient
                  </>
                }
                dropDirection="top-left"
                disabled={disabled}
                buttonClass="button--lightBlue button--smaller"
              />
            </div>
          )}

          {/*
           */}
        </div>
      )}
    />
  )
}
