import { faTimes, faPlus } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, FieldArray, ArrayHelpers } from "formik"
import React, { useContext } from "react"
import EditElements from "../EditElements/EditElements"
import { ModalContext } from "context/modal/ModalContext"
//@ts-ignore
import * as styles from "components/dishes/EditElements/EditElements.module.css"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

export default ({ sections }) => {
  const modal = useContext(ModalContext)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const removeSection = (onConfirm) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Remove section?",
      text: "Are you sure you want to remove this section?",
      confirmButtonText: "Remove",
      onConfirm: () => {
        onConfirm()
      },
      onReject: (e) => {
        e.preventDefault()
        e.stopPropagation()
        return
      },
    })
  }

  return (
    <FieldArray
      name="sections"
      render={(arrayHelpers: ArrayHelpers) => (
        <div className="pt-2">
          {sections?.map((section, index) => (
            <div className={styles.sectionContainer} key={index}>
              <div className={styles.sectionHeader}>
                <Field
                  className={styles.sectionHeading}
                  name={`sections.${index}.name`}
                  placeholder="Enter a section name"
                  disabled={!permissionObj?.permissions.modify}
                />

                <div className={styles.sectionDelete}>
                  <button
                    type="button"
                    tabIndex={-1}
                    className="rounded-full w-10 h-10 hover:bg-gray-100 text-lg bg-transparent text-primaryPinkLighter hover:text-primaryPink"
                    onClick={(e) => {
                      e.preventDefault()
                      removeSection(() => arrayHelpers.remove(index))
                    }}
                    disabled={!permissionObj?.permissions.modify}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <div className={styles.editIcon} tabIndex={-1}>
                  <FontAwesomeIcon icon={faPencil} />
                </div>
              </div>

              <EditElements
                name={`sections.${index}`}
                elements={section.elements}
                disabled={!permissionObj?.permissions.modify}
              />
            </div>
          ))}

          <div className="mb-24 border-t -mt-px border-gray-200 border-dashed hover:border-gray-500">
            <button
              type="button"
              className="button button--transparent button--text-left -ml-4 uppercase font-sansBold font-semibold text-sm"
              onClick={() => arrayHelpers.push({ name: "", elements: [] })}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Section
            </button>
          </div>
        </div>
      )}
    />
  )
}
