import React, { useState, useEffect, useContext } from "react"
import { navigate } from "@reach/router"
import { usePromise, usePrevious } from "react-use"
import {
  faPlusCircle,
  faPlus,
  faCloudUpload,
  faCamera,
  faFileDownload as faFileDownloadLight,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload, faEllipsisH } from "@fortawesome/pro-regular-svg-icons"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"

import Header from "components/dashboard/Header/Header"
import FilterSelect from "components/forms/FilterSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import ListFiltersModalButton from "components/common/ListFiltersModal/ListFiltersModalButton"
import { AsideContext } from "context/aside/AsideContext"
import DropdownButton from "components/common/DropdownButton/DropdownButton"

import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import {
  getProducts,
  removeProduct,
  removeMultipleProducts,
  exportProductsToCsv,
  exportProductsToXlsx,
  groupProducts,
  getProductsByGroupId,
  assignProductGroupsToOrgs,
  unAssignProductGroupsFromOrgs,
  reassignProductGroupToOrgs,
} from "services/products/products"
import ProductInput from "components/products/ProductInput/ProductInput"
import { searchSuppliers } from "services/suppliers/suppliers"
import { productUnits, productSearchQueryParameters } from "services/constants"
import { showError, showSuccess } from "services/toast"
import { ModalContext } from "context/modal/ModalContext"
import ListFiltersModal from "components/common/ListFiltersModal/ListFiltersModal"
import CategorySelect from "components/common/CategorySelect/CategorySelect"
import SearchByParamInput from "components/forms/SearchByParamInput/SearchByParamInput"
import usePermissions from "hooks/usePermissions"

import * as styles from "./Products.module.css"

import ModalProductMarkGroup from "../ModalProductMarkGroup/ModalProductMarkGroup"
import { toast } from "react-toastify"
import EditPreferredProduct from "components/suppliers/EditPreferredProduct/EditPreferredProduct"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import ProductsGlobalCartButton from "../ProductsGlobalCartButton/ProductsGlobalCartButton"
import Checkbox from "components/common/Checkbox/Checkbox"
import ModalUnmarkItems from "components/common/ModalUnmarkItems/ModalUnmarkItems"
import {
  itemIsOrderedAsCase,
  itemIsOrderedAsSingleAndCase,
} from "../../../services/orders/orders"
import ModalAssignProductLocations from "components/common/ModalAssignLocations/ModalAssignLocations"
import SelectCell from "../Table/SelectCell"
import useProductTableColumns from "../Table/Columns"
import { Category, Permission } from "services/types"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import usePagination from "hooks/usePagination"
import { faWineBottle } from "@fortawesome/pro-duotone-svg-icons"
import { getProductsDummy } from "services/dummy"
import CustomExpandIconComponent from "../Table/CustomExpandIcon"
import RecipeGeneratorAiModal from "components/recipes/RecipeGeneratorAi/RecipeGeneratorAiModal"
import { getExportFileName } from "services/export"
import useCategories from "hooks/useCategories"

const copyProduct = (product, aside, onAsideClose) => {
  const values = {
    barcode: product.barcode,
    category: product.category,
    description: product.description,
    measure: product.measure,
    size: product.size,
    name: `Copy - ${product.name}`,
    unit: product.unit,
    subCategory: product.subCategory,
    minQtyInStock: product.minQtyInStock,
  }

  aside.showAside(EditProduct, {
    initialValues: values,
    headerText: "Duplicate product",
    createProduct: true,
    isCopy: true,
    onClose: onAsideClose,
  })
}

const handleGroupActionClick = (
  action,
  selectedProducts,
  modal,
  getData,
  setSelectedProducts,
  onDeleteMultiple
) => {
  const { type } = action
  /* eslint-disable */
  const products = selectedProducts ?? []

  const allItems = products
    .map((p) => {
      if (p.children) {
        return [p, ...p.children]
      } else {
        return p
      }
    })
    .flat()

  switch (type) {
    case "items.markSame":
      if (products.length < 2)
        return toast.error(`At least 2 products must be selected`)
      modal.showModal(ModalProductMarkGroup, {
        // items: products,
        items: allItems,
        onSubmit: (preferredProductGroupId, childrenIds) =>
          groupProducts(preferredProductGroupId, childrenIds)
            .catch((err) => {
              toast.error(err)
            })
            .finally(() => {
              getData()
              setSelectedProducts([])
            }),
      })
      break

    case "items.delete":
      onDeleteMultiple(selectedProducts.map((a) => a.id))
      break

    case "items.manageLocations":
      //Assign locations
      // if (products.length < 2)
      //   return toast.error(`At least 2 products must be selected`)
      modal.showModal(ModalAssignProductLocations, {
        // items: products,
        items: allItems,
        onSubmit: (groupIds, orgIds, action) => {
          // If only 1 product is selected, choose the re-assign request
          const req: any =
            products.length === 1
              ? reassignProductGroupToOrgs
              : action === "assign"
              ? assignProductGroupsToOrgs
              : unAssignProductGroupsFromOrgs

          const params = {
            groupsIds: groupIds,
            organizations: orgIds,
          }

          // Not needed for re-assign request
          if (products.length === 1) {
            delete params.groupsIds
          }

          const nextReq =
            products.length === 1
              ? req(products[0].groupId, params) // different request when only 1 is selected
              : req(params)

          return nextReq
            .catch((err) => {
              toast.error(err)
            })
            .finally(() => {
              getData()
              setSelectedProducts([])
            })
        },
        type: "item",
        showActionPicker: products.length > 1,
        initialOrgs: products.length === 1 ? products[0].organizations : [],
      })
      break

    default:
      break
  }
}
/* eslint-enable */

const SelectColumnHeader = ({
  selectedProducts,
  setSelectedProducts,
  products,
}) => {
  const multiSelectActive = selectedProducts?.length > 0
  const handleSelect = (e) => {
    if (!e.target.checked) {
      setSelectedProducts([])
    } else {
      setSelectedProducts(products.content.filter((p) => p.preferred))
    }
  }
  return (
    <div className="mr-4">
      <input
        type="checkbox"
        value={""}
        onChange={handleSelect}
        name="multiSelect"
        className="form-checkbox text-primaryPink"
        checked={multiSelectActive}
      />
    </div>
  )
}

const Products = ({
  supplierId,
  supplierData = {},
  requestPreferredOnly = true,
}) => {
  const modal = useContext(ModalContext)
  const { organization } = useContext(GlobalStateContext)
  const stockOnHandOrgIds = [
    "639b015dda9f22141717a5ec", // Signoreli Deli
    "64103c63da9f22141717a60c", // The Grand
    "6513dbc213fa695f26b07283", // Unknown
    "6089a6858b9dd32340f366be", // Unknown
    "644152140674034111333e29", // The George Hotel
    "6555d3d2c1373e390dade78b", // Manor by the Lake
    "660bd51f62a8da6142dd8ba7", // Unknown
    "663a743862a8da6142dd8bdc", // Bricklood Balham
    "66d05f7e05c683631509d7ff", // Dirty Sixth
    "631b1f06c354912f3395964a", // GoEPOS Location 1
    "62d9b6ced354b418ee179907", // GoEPOS Location 2
    "65a79f57c1373e390dade844", // The International
    "67161aece83a2063b53aa520", // Roots
  ]
  const showStockOnHandAllowed =
    stockOnHandOrgIds.indexOf(organization?.id) > -1 // Enabling "Show stock on hand" functionality only for hardcoded prod orgs

  const [products, setProducts] = useState({
    content: [],
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const pagination = usePagination()

  const startEditProduct = async (product: any, onAsideClose?: void) => {
    const values = { ...product, generateBarcode: false }

    if (product.preferred) {
      const products = product.children
        ? [product, ...product.children]
        : await getProductsByGroupId(product.groupId)

      aside.showAside(EditPreferredProduct, {
        products: products,
        fetchProducts: getData,
        onClose: () => {
          aside.hideAside()
        },
      })
    } else {
      aside.showAside(EditProduct, {
        headerText: "Edit product",
        initialValues: values,
        onClose: onAsideClose ? onAsideClose : aside.hideAside,
      })
    }
  }

  const { globalOrder, organizationPermissions } =
    useContext(GlobalStateContext)

  const [showingStockOnHandData, setShowingStockOnHandData] = useState(false)

  //Filters
  const [packaging, setPackaging] = useState<any | null>(null)
  const previousPackaging = usePrevious(packaging)
  const [supplier, setSupplier] = useState(null)
  const previousSupplier = usePrevious(supplier)
  const [queryParameter, setQueryParameter] = useState({
    label: "name",
    value: "partialProductName",
  })

  const fromPromise = usePromise()
  const [loading, setLoading] = useState(true)
  const aside = useContext(AsideContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [showListFiltersModal, setShowListFiltersModal] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])

  const filterFunction = (categories: Category[]) => {
    return categories.map((category) => ({
      ...category,
      active: false,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        active: false,
      })),
    }))
  }
  const { categories } = useCategories(filterFunction)
  const [mainCategories, setMainCategories] = useState<Category[]>(categories)
  const filtersSelected =
    supplier?.value || packaging?.value || mainCategories?.length

  const permissionObj: any = usePermissions("Items") as Permission
  const isMainOrg = organizationPermissions?.general?.isMain
  const isSubOrg = organizationPermissions?.general?.isSub
  const canManageProducts = isMainOrg || !isSubOrg // If is an enterprise account, check if user is main org, otherwise allow (for standard accounts)
  const [sortState, setSortState] = useState<{ [x: string]: any }>({
    barcode: "asc",
  })

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const addExpandRowKey = (id) => {
    const found = expandedRowKeys.find((a) => a === id)
    if (!found) {
      setExpandedRowKeys((p) => {
        return [...p, id]
      })
    }
  }

  const removeExpandRowKey = (id) => {
    setExpandedRowKeys((p) => p.filter((a) => a !== id))
  }

  const onColumnSort = ({ key, order }) => setSortState({ [key]: order })

  const handleActionClick = (action, item) => {
    const parent = products.content.find((a) => a.groupId === item.groupId)
    const children = parent?.children ?? []

    const { type } = action
    switch (type) {
      case "item.edit":
        startEditProduct(item, onAsideClose)
        break
      case "item.duplicate":
        copyProduct(item, aside, onAsideClose)
        break
      case "item.delete":
        onDelete(item)
        break
      case "item.unmarkAsSame":
        onUnmarkAsSame(item, children)
        // onUnmarkAsSameItem(item, 'single')
        break
      case "items.unmarkAsSame":
        // onUnmarkAsSameItem(item, 'group')
        break
      case "item.generateRecipeAi":
        modal.showModal(RecipeGeneratorAiModal, { product: item })
        break
      default:
        break
    }
  }

  //  MUST MATCH COLUMN KEY
  const expandColumnKey = "foldableToggler"

  const getData = async () => {
    setLoading(true)

    const params: any = {
      page: pagination.page,
      size: pagination.size,
      sort: `${Object.keys(sortState)[0]},${Object.values(sortState)[0]}`,
      extended: true,
      preferred: filtersSelected ? false : requestPreferredOnly, //Requesting only preferred/parent products. Children are being fetched when parent is expanded
      stockOnHand: showingStockOnHandData,
    }

    if (mainCategories) {
      const categories = mainCategories
        .filter((category) => category.active)
        .map((category) => category.name)
      params.categories = categories

      const subCategories = _getSubCategories(mainCategories)
      params.subCategories = subCategories
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialCode":
          params.partialCode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        default:
          params.partialProductName = q
          break
      }
      if (previousQ !== q) {
        params.page = 0
      }
      params.preferred = false
    }

    if (packaging) {
      if (packaging.value === "Case") {
        params.hasCase = true
      } else {
        params.unit = packaging.value
      }

      if (previousPackaging && previousPackaging.value !== packaging.value) {
        params.page = 0
      }
    }

    if (supplier) {
      params.supplierId = supplier.value

      if (previousSupplier && previousSupplier.value !== supplier.value) {
        params.page = 0
      }
    }

    if (supplierId) {
      params.supplierId = supplierId
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getProducts(params))

    if (result && !result.error) {
      setProducts(formatProducts(result))
      pagination.setFromResult(result)

      for (const key of expandedRowKeys) {
        const found = result.content.find((prod) => key === prod.id)

        if (found) {
          if (found.countOfProductsInGroup > 1) {
            fetchChildData(found.groupId, found)
          } else {
            removeExpandRowKey(key)
          }
        }
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const formatProducts = (result) => {
    return {
      ...products,
      ...result,
      content: result.content.map((p) => ({
        ...p,
        uniqueId: p.id,
        _selectedCase: (() => {
          const productInGlobalOrder = globalOrder.products.find(
            (a) => a.id === p.id
          )
          if (productInGlobalOrder) {
            return productInGlobalOrder._selectedCase
          } else {
            return itemIsOrderedAsCase(p) || itemIsOrderedAsSingleAndCase(p)
              ? "multiple"
              : "single"
          }
        })(),
      })),
    }
  }

  const onAsideClose = (result) => {
    if (
      result === "product-created" ||
      result === "products-imported" ||
      result === "product-updated"
    ) {
      getData()
    }
  }

  const deleteProduct = async (product) => {
    const deleted = await removeProduct(product.id)

    if (deleted.message) {
      showError(deleted.message)
    } else {
      showSuccess("Product deleted!")
      getData()
    }
  }

  const onDelete = (product) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${product.name || "Product"}`,
      text: "Are you sure you want to delete this product?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteProduct(product),
    })

  const deleteMultipleProducts = async (products) => {
    const deleted = await removeMultipleProducts({ productsIds: products })
    if (deleted) {
      showSuccess("Products deleted!")
      setSelectedProducts([])
      getData()
    }
  }

  const onUnmarkAsSame = (item, groupChildren) => {
    // Operation should not be allowed for preferred product also on FE
    const isPreferred: boolean = item.preferred

    if (isPreferred) return toast.error("Cannot unmark a preferred product.")

    modal.showModal(ModalUnmarkItems, {
      item: item,
      groupChildren: groupChildren,
      onRequestClose: modal.hideModal(),
      onSuccess: () => {
        getData()
      },
    })
  }

  const onDeleteMultiple = (products) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${products.length} products`,
      text: "Are you sure you want to delete the selected products?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteMultipleProducts(products),
    })

  const handleAddItemButtonClick = ({ type }) => {
    switch (type) {
      case "create":
        aside.showAside(EditProduct, {
          headerText: "Create product",
          supplierData,
          onClose: onAsideClose,
          createProduct: true,
        })
        break
      case "scan":
        // ProductInput
        modal.showModal(ProductInput, {
          onSave: () => {
            // console.log("save")
          },
          onClose: () => {
            // console.log("close")
          },
        })
        break
      case "import":
        navigate("/dashboard/products/items/import", {
          state: { supplierData },
        })
        break
      case "export.csv":
      case "export.xlsx":
        handleExportClick(type)
        break
      default:
        aside.showAside(EditProduct, {
          createProduct: true,
          headerText: "Add new product",
        })
    }
  }

  const fetchChildData = async (groupId, rowData) => {
    if (groupId) {
      // globalOrder

      //stockOnHand = true - filtering/searching data
      //stockOnHand = false - default view, only preferred products have stockOnHand data assigned
      //filtersSelected = Number of selected filters
      //q = current query string
      const childrenWithoutSource = await getProductsByGroupId(groupId, {
        stockOnHand:
          filtersSelected || q.length ? showingStockOnHandData : false,
      }).then((data) => data.filter((a) => a.id !== rowData.id))

      await setProducts((previousProducts: any) => {
        const temp = [...previousProducts.content]
        // const target = temp.find(a => a.groupId === rowData.groupId);
        const target = temp.find(
          (a) => a.name === rowData.name && a.id === rowData.id
        )
        if (target) {
          //Appending additional items to current product
          //Unique id is used to assing unique keys to each jsx element when rendering
          target.children = childrenWithoutSource.map((p) => {
            const globalProduct = globalOrder.products.find(
              (a) => a.barcode === p.barcode && a.id === p.id
            )
            const _selectedCase =
              globalProduct?._selectedCase ||
              (itemIsOrderedAsCase(p) || itemIsOrderedAsSingleAndCase(p)
                ? "multiple"
                : "single")
            const _basketQuantity = globalProduct?._basketQuantity
            return {
              ...p,
              uniqueId: p.id + rowData.supplierId + "-sub",
              _isSub: true,
              _selectedCase,
              _basketQuantity,
            }
          })
        }
        return {
          content: temp,
        }
      })
      return childrenWithoutSource
    }
  }

  const handleSelectChange = (
    checked: boolean,
    selectedIndex: number,
    rowData: any
  ) => {
    const latestItems: any[] = [...selectedProducts]
    if (checked && selectedIndex <= -1) {
      latestItems.push(rowData)
      // previously we awaited below function,
      // but this hurts perceived performance because checbox get checked on delay
      if (rowData.preferred) {
        fetchChildData(rowData.groupId, rowData)
      }
    } else {
      latestItems.splice(selectedIndex, 1)
    }
    setSelectedProducts(latestItems)
  }

  const columns = useProductTableColumns(
    permissionObj,
    getData,
    setProducts,
    showingStockOnHandData,
    globalOrder,
    selectedProducts.length > 0,
    handleActionClick,
    mainCategories
  )

  if (permissionObj?.permissions.modify && canManageProducts) {
    columns.unshift({
      key: "select",
      title: "Select",
      dataKey: "select",
      width: 45,
      flexGrow: 0,
      flexShrink: 0,
      headerRenderer: (
        <SelectColumnHeader
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          products={products}
        />
      ),
      cellRenderer: ({ rowData }) => {
        const selectedIndex: number = selectedProducts?.findIndex(
          (a: any) => a.id === rowData.id
        )
        return (
          <SelectCell
            rowData={rowData}
            isSelected={selectedIndex > -1}
            onChange={(checked) =>
              handleSelectChange(checked, selectedIndex, rowData)
            }
            // setSelectedProducts={setSelectedProducts}
          />
        )
      },
    })
  }

  const rowSpanIndex = 0

  columns[rowSpanIndex].rowSpan = ({ rowData, rowIndex }) => {
    let currentSpan = 0
    for (let i = rowIndex; i < products.content.length; i++) {
      // eslint-disable-next-line react/prop-types
      if (products.content[i].barcode === rowData.barcode) {
        currentSpan++
      } else {
        break
      }
    }

    return currentSpan
  }

  const dropdownOptions = [
    {
      key: "create",
      title: "Create new",
      icon: faPlusCircle,
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "scan",
      title: "Scan to add",
      icon: faCamera,
      disabled: !permissionObj?.permissions.modify,
    },
    {
      key: "import",
      title: "Bulk import",
      icon: faCloudUpload,
      disabled: !permissionObj?.permissions.modify,
    },
  ]

  if (isTabletOrMobile) {
    dropdownOptions.push(
      {
        key: "export.csv",
        title: "Export to .CSV",
        icon: faFileDownloadLight,
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "export.xlsx",
        title: "Export to .XLSX",
        icon: faFileDownloadLight,
        disabled: !permissionObj?.permissions.read,
      }
    )
  }

  const handleExportClick = (type) => {
    const exportParams = {} as any

    if (supplierId) {
      exportParams.supplierId = supplierId
    }
    const ext = [type, type?.type].includes("export.csv") ? "csv" : "xlsx"
    const reportName = supplierId ? `supplier-${supplierId}` : "all"
    const fileName = getExportFileName("products", reportName, ext)

    //  1st for Products.js , 2nd is for SupplierDetails.js
    if (ext == "csv") {
      return exportProductsToCsv(exportParams, fileName)
    } else {
      return exportProductsToXlsx(exportParams, fileName)
    }
  }

  const _getSubCategories = (mainCategories: Category[]) => {
    const subCategories: string[] = []
    mainCategories.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        if (subCategory.active) {
          subCategories.push(subCategory.name)
        }
      })
    })

    return subCategories
  }

  useEffect(() => {
    getData()
  }, [sortState, q, packaging, supplier, mainCategories, pagination.page])

  useEffect(() => {
    if (!mainCategories.length && categories.length) {
      setMainCategories(categories)
    }
  }, [categories])

  useEffect(() => {
    if (true === showingStockOnHandData) {
      getData()
    }
  }, [showingStockOnHandData])

  const ProductFilters = ({ categories }) => {
    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-4 w-full">
          <FilterSelect
            options={productUnits}
            value={packaging && packaging.value}
            onChange={(val) => {
              setPackaging(val)
            }}
            placeholder="All packaging"
            isClearable={true}
            isSearchable={isTabletOrMobile ? false : true}
            className="my-2 lg:my-0 w-full mr-4 md:mr-6"
          />
          {!supplierId && (
            <AsyncSelect
              promise={searchSuppliers}
              placeholder={"All suppliers"}
              isClearable={true}
              isSearchable={isTabletOrMobile ? false : true}
              optionLabel="name"
              optionValue="id"
              onChange={(val) => {
                setSupplier(val)
              }}
              className="my-2 lg:my-0 w-full mr-4 md:mr-6"
              defaultValue={
                supplier
                  ? { value: supplier.value, label: supplier.label }
                  : null
              }
              staticOptions={{
                label: "No Supplier",
                value: "no-supplier-id",
              }}
            />
          )}
        </div>

        <div className="mt-6">
          {categories.length > 0 && (
            <CategorySelect
              categories={categories}
              onSelectCategories={(categories) => {
                setMainCategories(categories)
                if (!isTabletOrMobile) {
                  showFiltersAside(categories)
                }
              }}
              groupCategoriesInDropdowns={true}
            />
          )}
        </div>
      </div>
    )
  }

  const getFilterCount = () => {
    let count = 0

    if (supplier) {
      count++
    }
    if (packaging) {
      count++
    }
    const selectedCty = mainCategories?.reduce((acc, value) => {
      if (value.name === "No category") {
        return (acc = acc + 1)
      }
      return (acc =
        acc + value.subCategories.filter((subCat) => subCat.active).length)
    }, 0)

    count += selectedCty

    return count
  }

  const filterCount = getFilterCount()

  const showFiltersAside = (categories) => {
    const renderFiltersComponent = () => {
      return (
        <div className={styles.asideContainer}>
          <div className={styles.body}>
            <ProductFilters categories={categories} />
          </div>
          <footer className={styles.footer}>
            <button
              type="button"
              className="mr-6 font-semibold font-sansSemiBold text-gray-700"
              onClick={(e) => {
                e.preventDefault()
                aside.hideAside()
              }}
            >
              Close
            </button>
          </footer>
        </div>
      )
    }

    aside.showAside(renderFiltersComponent, {
      headerText: "Filter items",
      categories: mainCategories,
    })
  }

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <div className={styles.container}>
        {!supplierId && <Header title="Products" />}
        <div className={styles.subHeader}>
          <ListFiltersModalButton
            showFilters={() => {
              if (isTabletOrMobile) {
                setShowListFiltersModal(true)
              } else {
                mainCategories.length && showFiltersAside(mainCategories)
              }
            }}
            filterCounter={filterCount}
          />
          <ListFiltersModal
            className={supplierId ? styles.supplierDetailsFiltersModal : ""}
            title={"Filters"}
            total={pagination.totalElements}
            isOpen={showListFiltersModal}
            onClose={() => setShowListFiltersModal(false)}
          >
            <ProductFilters categories={mainCategories} />
          </ListFiltersModal>

          <SearchByParamInput
            className="w-full md:w-40 mr-4 md:mr-6"
            currentParam={queryParameter}
            setCurrentParam={setQueryParameter}
            setSearchValue={setQ}
            paramOptions={productSearchQueryParameters}
          />

          {canManageProducts && (
            <DropdownButton
              containerClass="md:mr-6"
              label={
                <>
                  <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add item
                </>
              }
              options={dropdownOptions}
              mobileIcon={faEllipsisH}
              onActionClick={handleAddItemButtonClick}
            />
          )}

          {!isTabletOrMobile && (
            <DropdownButton
              onActionClick={handleExportClick}
              label={<FontAwesomeIcon icon={faFileDownload} />}
              mobileIcon={faEllipsisH}
              options={[
                {
                  title: "Export to .CSV",
                  key: "export.csv",
                  disabled: !permissionObj?.permissions.read,
                },
                {
                  title: "Export to .XLSX",
                  key: "export.xlsx",
                  disabled: !permissionObj?.permissions.read,
                },
              ]}
            />
          )}
        </div>

        {selectedProducts?.length > 0 && (
          <div className={styles.actionBar}>
            <div className={styles.actionBarText}>
              <span className="font-semibold mr-4">
                <span className="text-primaryBlue">
                  {selectedProducts.length}{" "}
                  {selectedProducts.length === 1 ? "product" : "products"}
                </span>{" "}
                <span className="text-gray-600">selected</span>
              </span>
            </div>

            <DropdownButton
              dropDirection="top-left"
              label="Apply action"
              buttonClass={
                "button button--primaryGreen button--smallest text-sm"
              }
              options={[
                {
                  key: "items.markSame",
                  title: "Mark as the same item",
                  // type: "danger",
                  disabled: !permissionObj?.permissions.modify,
                },
                {
                  key: "items.manageLocations",
                  title: "Manage locations",
                  disabled:
                    !permissionObj?.permissions.modify ||
                    !organizationPermissions?.general?.isMain,
                },
                {
                  key: "items.delete",
                  title: "Delete",
                  type: "danger",
                  disabled:
                    !permissionObj?.permissions.delete ||
                    !organizationPermissions?.products?.remove,
                },
              ]}
              onActionClick={(action) =>
                handleGroupActionClick(
                  action,
                  selectedProducts,
                  modal,
                  getData,
                  setSelectedProducts,
                  onDeleteMultiple
                )
              }
            />
          </div>
        )}

        <div className={styles.content}>
          <div className="flex w-full px-5 mb-2 items-center">
            <div className="ml-auto">
              {showStockOnHandAllowed && (
                <Checkbox
                  name="stock-on-hand"
                  label="Show stock on hand"
                  isChecked={showingStockOnHandData}
                  onSelect={() => setShowingStockOnHandData((prev) => !prev)}
                />
              )}
            </div>
          </div>

          <ExtBaseTable
            loading={loading}
            pagination={pagination}
            columns={columns}
            data={products.content}
            onColumnSort={onColumnSort}
            sortState={sortState}
            estimatedRowHeight={70}
            rowKey={"uniqueId"}
            title="Products"
            id="products"
            empty={{
              icon: faWineBottle,
              color: "blue",
              getDummy: () => getProductsDummy,
            }}
            //EXPANDABLE PROPS
            expand={{
              isQuerying: q !== "",
              component: CustomExpandIconComponent,
            }}
            expandedRowKeys={expandedRowKeys}
            expandColumnKey={expandColumnKey}
            expandIconProps={({ rowData }) => {
              return {
                rowData,
              }
            }}
            onRowExpand={(obj) => {
              const expanded = obj.expanded

              if (expanded) {
                fetchChildData(obj.rowData.groupId, obj.rowData)
                addExpandRowKey(obj.rowKey)
              } else {
                removeExpandRowKey(obj.rowKey)
              }
            }}
          />
        </div>
        <ProductsGlobalCartButton />
      </div>
    </>
  )
}

export default Products
