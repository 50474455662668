import React from "react"

interface SwitchProps {
  on: boolean
  setOn: React.Dispatch<React.SetStateAction<boolean>>
  label?: string
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"]
}

const Switch = ({ on, setOn, label, buttonType = "button" }: SwitchProps) => {
  const handleClick = () => setOn(!on)
  return (
    <div className="flex items-center">
      <button
        type={buttonType}
        onClick={handleClick}
        className={`${
          on ? "justify-end bg-primaryGreen" : "justify-start bg-collapsible"
        } p-0.5 w-10 h-5 rounded-full flex transition duration-500 shadow-2xl mr-3`}
      >
        <span
          className={`bg-white transition duration-500 rounded-full w-4 h-4 m-0 p-0 shadow-xl`}
        ></span>
      </button>
      {label && <label className="text-darkBlue">{label}</label>}
    </div>
  )
}

export default Switch
