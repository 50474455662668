import React, { useState, useEffect } from "react"
import { usePrevious, useUpdateEffect } from "react-use"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import { faCalendarAlt } from "@fortawesome/pro-duotone-svg-icons"
import BaseList from "components/baseList/BaseList"
import usePagination from "hooks/usePagination"
import { getDeleveryNotesDummy } from "services/dummy"
import { eventQueryParameters } from "services/constants"
import FilteredSearchInput from "components/forms/FilteredSearchInput"
import { getEvents } from "services/events/events"
import EventItem from "../EventItem/EventItem"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { navigate } from "gatsby"

const Events = () => {
  const [loading, setLoading] = useState(false)
  const [eventsData, setEventsData] = useState({
    content: [],
  })
  const pagination = usePagination()

  const [q, setQ] = useState<{ value: string; queryParamName: string }>({
    value: "",
    queryParamName: eventQueryParameters[0].value,
  })
  const previousQ = usePrevious(q)
  const previousPage = usePrevious(pagination.page)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const getData = async () => {
    setLoading(true)

    const params = {
      page: pagination.page,
      size: pagination.size,
      sort: "createdDate,desc",
    }

    if (q && q.value) {
      params[q.queryParamName] = q.value
      if (previousQ !== q) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await getEvents(params)
    if (result && !result.error) {
      setEventsData({ ...eventsData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useUpdateEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useUpdateEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  return (
    <>
      <Helmet>
        <title>Events</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <Header title="Events" />

        <div className="px-4 py-1 flex items-center flex-wrap border-b flex-shrink-0 gap-x-4 lg:px-8 z-10">
          <div className="flex items-center flex-grow relative my-2 order-last sm:order-none w-full sm:w-max">
            <FilteredSearchInput
              filterOptions={eventQueryParameters}
              onChange={(params) => {
                setQ(params)
              }}
            />
          </div>

          <div className="ml-auto">
            <button
              className="button my-2 ml-auto button--autoWidth button--primaryGreen"
              onClick={(e) => {
                e.preventDefault()
                navigate("new")
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">Add event</span>
            </button>
          </div>
        </div>

        <div className="px-0 pb-6 -mt-px relative flex-grow w-full h-full overflow-auto lg:px-8 lg:py-5">
          <BaseList
            title="events"
            id="events"
            loading={loading}
            pagination={pagination}
            empty={{
              icon: faCalendarAlt,
              color: "blue",
              getDummy: () => getDeleveryNotesDummy,
            }}
            data={eventsData.content}
            rowRender={(event) => (
              <EventItem key={event.id} event={event} onDeleted={getData} />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default Events
