import React from "react"
import BaseTable, { AutoResizer } from "react-base-table"
import {
  Dish,
  OtherIngredientContainer,
  ProductContainer,
  RecipeContainer,
  Section,
} from "services/dishes/dishes"
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import { formatCurrencyValue } from "services/helpers"
import { useMediaQuery } from "usehooks-ts"
import * as styles from "./DishItem.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"

interface DishItemContentProps {
  dish: Dish
  updateParentTable: () => void
}

const DishItemContent = ({ dish, updateParentTable }: DishItemContentProps) => {
  const mappedData = dish.sections
    ? dish.sections.map((s: Section, index) => {
        const parentId = `${s.name}-${index}`
        const children = s.elements
          ? s.elements.map(
              (
                e:
                  | OtherIngredientContainer
                  | RecipeContainer
                  | ProductContainer,
                index
              ) => {
                const childId = `${parentId}-${index}`
                return {
                  id: childId,
                  parentId,
                  type: e.type,
                  ingredient: e.ingredient,
                  ...{
                    OTHER_INGREDIENT: {
                      name: (e as OtherIngredientContainer).otherIngredient
                        ?.name,
                      usedQty: (e as OtherIngredientContainer).otherIngredient
                        ?.usedQty,
                      measure: (e as OtherIngredientContainer).otherIngredient
                        ?.measure,
                      cost: (e as OtherIngredientContainer).otherIngredient
                        ?.cost,
                    },
                    INGREDIENT: {
                      name: (e as ProductContainer).ingredient?.product?.name,
                      usedQty: (e as ProductContainer).ingredient?.usedQty,
                      measure: (e as ProductContainer).ingredient?.measure,
                      cost: (e as ProductContainer).ingredient?.cost,
                    },
                    RECIPE: {
                      name: (e as RecipeContainer).recipe?.recipe?.name,
                      usedQty: (e as RecipeContainer).recipe?.usedQty,
                      measure: (e as RecipeContainer).recipe?.measure,
                      cost: (e as RecipeContainer).recipe?.cost,
                    },
                  }[e.type],
                }
              }
            )
          : []
        return {
          name: s.name,
          id: parentId,
          usedQty: `${s.elements ? s.elements.length : 0} item${
            s.elements && s.elements.length > 1 ? "s" : ""
          }`,
          cost: children.reduce((acc, elem) => acc + elem.cost, 0),
          children: children,
        }
      })
    : []

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex items-center">
          {rowData.parentId && rowData.ingredient?.product.isDeleted && (
            <>
              <span
                data-for="deleted_product_tooltip"
                data-tip="This ingredient is deleted."
              >
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="text-error mr-2"
                />
              </span>

              <ReactTooltip
                id={"deleted_product_tooltip"}
                type="light"
                place="right"
                effect="float"
                border={true}
                borderColor="#e2e8f0"
              />
            </>
          )}

          <div className="flex flex-col my-2 pl-0">
            <span
              className={`text-sm  ${rowData.name ? "" : "italic"} ${
                rowData.children
                  ? "font-sansSemiBold font-semibold text-primaryBlue"
                  : "font-sans font-normal text-gray-800"
              }`}
            >
              {rowData.name ?? "Untitled section"}
            </span>
          </div>
        </div>
      ),
    },
    {
      key: "usedQty",
      width: 80,
      title: "Qty",
      dataKey: "usedQty",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {rowData.usedQty} {rowData.measure}
        </span>
      ),
    },
    {
      key: "cost",
      width: 80,
      title: "Cost",
      dataKey: "cost",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {formatCurrencyValue(rowData.cost)}
        </span>
      ),
    },
  ]

  const isTabletOrMobile = useMediaQuery("{ maxWidth: 1023 }")

  return (
    <AutoResizer height={Infinity}>
      {({ width, height }) => (
        <BaseTable
          fixed={isTabletOrMobile ? true : false}
          style={{ backgroundColor: "transparent" }}
          width={width}
          maxHeight={mappedData.length === 0 ? undefined : Infinity}
          height={mappedData.length === 0 ? 80 : height}
          components={{
            ExpandIcon: (props) => {
              const { expandable, expanded, onExpand } = props

              if (!expandable) {
                return null
              }

              return (
                <FontAwesomeIcon
                  className="cursor-pointer text-primaryBlueLighter mr-4"
                  size="sm"
                  icon={expanded ? faChevronUp : faChevronDown}
                  onClick={() => {
                    onExpand(!expanded)
                    updateParentTable()
                  }}
                />
              )
            },
          }}
          ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
          expandColumnKey="name"
          data={mappedData}
          columns={columns}
          emptyRenderer={<div className={styles.noItems}>No items found</div>}
        />
      )}
    </AutoResizer>
  )
}

export default DishItemContent
