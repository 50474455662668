import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useField } from "formik"
import React from "react"
import TimeField from "react-simple-timefield"

interface TimeInputProps {
  [key: string]: any
}

const TimeInput = ({ ...props }: TimeInputProps) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field] = useField(props)

  // const handleTimeChange = (event, value) => {
  //   event.preventDefault()
  //   setValue(value, true)
  //   onChange(value)
  // }

  const customStyles = {
    width: "100%",
    // borderRadius: "5px",
    // backgroundColor: "#F8F8FF",
    // borderColor: "#e2e8f0",
  }

  return (
    <>
      <TimeField style={customStyles} {...field} {...props} />
      <FontAwesomeIcon
        icon={faClock}
        className="absolute right-4 top-4 z-10 text-gray-700"
      />
    </>
  )
}

export default TimeInput
