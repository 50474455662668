import React, { useState, useContext, useEffect, useRef, useMemo } from "react"
import { useBeforeUnload } from "react-use"
import * as queryString from "query-string"
import { useLocation } from "@reach/router"

import {
  faTruck,
  faWineBottle,
  faShoppingCart,
  faRedo,
} from "@fortawesome/pro-duotone-svg-icons"

import Helmet from "react-helmet"
import { FormikValues } from "formik"
import { useMediaQuery } from "react-responsive"

import Header from "components/dashboard/Header/Header"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import SupplierCatalog from "components/suppliers/SupplierCatalog/SupplierCatalog"
import OrderSteps from "components/common/OrderStepsLayout/OrderSteps/OrderSteps"
import StandingOrderRecurrence from "../StandingOrderRecurrence"
import StandingOrderDetails from "../StandingOrderDetails"

import { showError, showSuccess } from "services/toast"
import {
  apiResponseIsError,
  formatLocalDate,
  handleError,
} from "services/helpers"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

import { Permission } from "services/types"
import usePermissions from "hooks/usePermissions"

import {
  createOrderRecurrence,
  createOrderTemplate,
  getOrderTemplate,
  updateOrderRecurrence,
  updateOrderTemplate,
} from "services/order-templates/order-templates"

import { ExtendedProduct } from "services/products/types"
import {
  OrderRecurrenceRes,
  OrderTemplateItem,
  OrderTemplateWithRecurrence,
} from "services/order-templates/types"

import OrderStepsSidebar from "components/common/OrderStepsLayout/OrderStepsSidebar/OrderStepsSidebar"
import OrderStepsItemList from "components/common/OrderStepsLayout/OrderStepsItemList"
import { OrderStep, OrderStepItem } from "components/orders/types"
import { navigate } from "gatsby"
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const EditStandingOrder = ({ orderTemplateId }) => {
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false)
  const [showItems, setShowItemList] = useState(false)
  const [standingOrderPlaced, setStandingOrderPlaced] = useState(false)
  const [orderTemplate, setOrderTemplate] =
    useState<OrderTemplateWithRecurrence>({ items: [] })
  const [orderRecurrence, setOrderRecurrence] = useState<OrderRecurrenceRes>({})
  const [editingAddress, setEditingAddress] = useState(false)

  /**
   * HOOKS
   */
  const location = useLocation()
  const permissionObj = usePermissions("Orders") as Permission
  const { organization, user } = useContext(GlobalStateContext)
  useBeforeUnload(
    !standingOrderPlaced,
    "You have unsaved changes, are you sure?"
  )

  /**
   * CONSTANTS
   */
  const queryParams = queryString.parse(location.search)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const recurrenceForm = useRef<FormikValues>(null)

  const userCanModifyOrder =
    permissionObj?.permissions.modify && !standingOrderPlaced

  const itemCount = useMemo(
    () => orderTemplate.items?.filter((item) => item.quantity).length,
    [orderTemplate]
  )

  /**
   * HANDLERS
   */

  const handleSupplierSelect = (supplier) => {
    if (orderTemplate?.supplier && orderTemplate?.supplier.id !== supplier.id) {
      setOrderTemplate({
        ...orderTemplate,
        supplier,
        items: [],
      })
    } else {
      setOrderTemplate({ ...orderTemplate, supplier })
    }
  }

  const saveOrder = async () => {
    setLoading(true)

    try {
      const formattedOrderRecurrence = { ...orderRecurrence }

      if (formattedOrderRecurrence?.startDate) {
        if (!formattedOrderRecurrence?.endDate) {
          /**
           * If user has not set endDate, set it to one year in the future from startDate due to currently being a required field on the BE
           */
          const endDate = new Date(formattedOrderRecurrence?.startDate)
          endDate.setFullYear(endDate.getFullYear() + 1)

          //@ts-ignore swagger gen ts date type
          formattedOrderRecurrence.endDate = endDate.toISOString()
        }

        /**
         * Format start date
         * */

        formattedOrderRecurrence.startDate = formatLocalDate(
          formattedOrderRecurrence?.startDate,
          user?.zoneId
        )

        if (formattedOrderRecurrence?.endDate) {
          /**
           * Format end date
           * */

          formattedOrderRecurrence.endDate = formatLocalDate(
            formattedOrderRecurrence?.endDate,
            user?.zoneId
          )
        }

        const orderTemplateResult = orderTemplate?.id
          ? await updateOrderTemplate(orderTemplate?.id, orderTemplate)
          : await createOrderTemplate(orderTemplate)

        if (apiResponseIsError(orderTemplateResult)) {
          if (orderTemplateResult?.message) {
            showError(orderTemplateResult.message)
          } else {
            showError("Something went wrong...")
          }
          return
        } else {
          showSuccess(
            `Standing order ${orderTemplate?.id ? "updated" : "created"}`
          )
        }

        const orderRecurrenceResult = formattedOrderRecurrence?.id
          ? await updateOrderRecurrence(
              orderTemplateResult?.id,
              formattedOrderRecurrence
            )
          : await createOrderRecurrence(
              orderTemplateResult?.id,
              formattedOrderRecurrence
            )

        if (apiResponseIsError(orderRecurrenceResult)) {
          if (orderRecurrenceResult?.message) {
            showError(orderRecurrenceResult.message)
          } else {
            showError("Something went wrong...")
          }
        } else {
          setStandingOrderPlaced(true)
        }
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const removeOrderItem = (itemId) => {
    const newOrderItems = orderTemplate?.items?.filter((item) => {
      return item.productId !== itemId
    })
    setOrderTemplate({ ...orderTemplate, items: newOrderItems })
  }

  const handleSelectionChange = (
    items: Array<ExtendedProduct | OrderTemplateItem>
  ) => {
    const formattedOrderTemplateItems: OrderTemplateItem[] = items.map(
      (item) => {
        if ("id" in item) {
          const formatted: OrderTemplateItem = {
            ...item,
            productId: item.id,
          }
          return formatted
        } else {
          return item
        }
      }
    )

    setOrderTemplate({ ...orderTemplate, items: formattedOrderTemplateItems })
  }

  const moveStep = async (
    direction: "next" | "prev" = "next",
    currentStep: OrderStep
  ) => {
    if (recurrenceForm?.current) {
      /**
       * If StandingOrderRecurrence form is mounted, try to imperatively submit the form here, updating the orderRecurrence state.
       * */
      try {
        await recurrenceForm?.current?.submitForm()
      } catch (e) {
        /**
         *  If the form validation has errors then the promise will reject and we end up here in the catch block
         */
        console.log("Recurrence validation error:", e)
        return
      }
    }

    const foundStep = steps.findIndex((s) => s.key === currentStep)

    const newStepKey =
      direction === "next" ? steps[foundStep + 1].key : steps[foundStep - 1].key

    const url = new URL(location.href)
    url.searchParams.set("step", newStepKey)
    history.pushState({}, "", url)
    setStep(newStepKey)
  }

  const displayActionLabel = (userCanModifyOrder: boolean) => {
    if (!userCanModifyOrder) {
      return "Order history"
    } else if (step === "supplier") {
      //step is supplier
      return "Select items"
    } else if (step === "items") {
      //step is items
      return "Choose recurrence"
    } else if (step === "recurrence") {
      //step is recurrence
      return "Review order"
    } else if (step === "review") {
      //step is review
      return "Finish order"
    }
  }

  const sidebarContent = isTabletOrMobile ? (
    <button
      className="text-primaryPink font-sansSemiBold font-semibold w-1/3 text-left order-first"
      onClick={() => setShowItemList(true)}
    >
      {itemCount} {itemCount !== 1 ? "items" : "item"}
      <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
    </button>
  ) : (
    <OrderStepsItemList
      order={orderTemplate}
      setShowItemList={setShowItemList}
      removeOrderItem={removeOrderItem}
      modifyPermission={userCanModifyOrder}
    />
  )

  const steps: Array<OrderStepItem> = useMemo(
    () => [
      {
        key: "supplier",
        title: "Select a supplier",
        icon: faTruck,
        content: (
          <div className="px-0 h-full lg:px-8 pb-4 lg:pb-6">
            <SupplierSelect
              selected={orderTemplate?.supplier}
              onSelect={handleSupplierSelect}
            />
          </div>
        ),
      },
      {
        key: "items",
        title: "Select your items",
        icon: faWineBottle,
        content: (
          <div className="-mt-px">
            {!loading &&
              orderTemplate?.supplier?.id &&
              orderTemplate?.items && (
                <SupplierCatalog
                  selectedItems={orderTemplate?.items}
                  onSelectionChange={handleSelectionChange}
                  supplier={orderTemplate?.supplier}
                />
              )}
          </div>
        ),
        sidebarContent,
      },
      {
        key: "recurrence",
        title: "Choose recurrence",
        icon: faRedo,
        content: (
          <StandingOrderRecurrence
            orderRecurrence={orderRecurrence}
            setOrderRecurrence={setOrderRecurrence}
            ref={recurrenceForm}
          />
        ),
        sidebarContent,
      },
      {
        key: "review",
        title: "Review order",
        icon: faShoppingCart,
        content: (
          <StandingOrderDetails
            step={"review"}
            orderTemplate={orderTemplate}
            setOrderTemplate={setOrderTemplate}
            orderRecurrence={orderRecurrence}
            isEditable={userCanModifyOrder}
            moveStep={moveStep}
            placed={standingOrderPlaced}
            editingAddress={editingAddress}
            setEditingAddress={setEditingAddress}
          />
        ),
      },
    ],
    [
      orderTemplate,
      orderTemplate?.supplier,
      orderTemplate?.items,
      orderRecurrence,
      userCanModifyOrder,
      standingOrderPlaced,
      loading,
      editingAddress,
      itemCount,
      isTabletOrMobile,
    ]
  )

  const initStep = () => {
    if (typeof queryParams.step === "string") {
      const foundStep = steps.find((s) => s.key === queryParams.step)

      if (foundStep) {
        if (orderTemplate.supplier) {
          return foundStep.key
        }
      }
    }
    return "supplier"
  }

  const [step, setStep] = useState<OrderStep>(initStep())

  const handleNext = userCanModifyOrder
    ? () => {
        if (step === "review") {
          return saveOrder()
        } else {
          return moveStep("next", step)
        }
      }
    : async () => {
        await navigate("/dashboard/purchases/orders?tab=standing-orders", {
          replace: true,
        })
      }

  const disableNext =
    loading ||
    (step === "supplier" && !orderTemplate.supplier) ||
    (step === "items" && !itemCount) ||
    (step === "review" && editingAddress)

  const sidebarHeader = (
    <>
      {step === "supplier" && !isTabletOrMobile && (
        <h3 className="h-4 text-primaryBlue font-bold font-sansBold text-sm mb-3 p-0 text-left w-full leading-none block lg:mb-6">
          Select a supplier
        </h3>
      )}

      <div className="hidden items-start w-full lg:flex">
        <span className="text-gray-700 flex-grow truncate">
          Supplier:{" "}
          {orderTemplate.supplier ? (
            <span className="font-semibold font-sansSemiBold">
              {orderTemplate.supplier.name}
            </span>
          ) : (
            "not selected"
          )}
        </span>
        {step !== "supplier" && userCanModifyOrder && (
          <button
            type="button"
            className="text-primaryPink button--autoWidth font-semibold font-sansSemiBold"
            onClick={() => navigate(`?step=supplier`)}
            disabled={!userCanModifyOrder}
          >
            (Change)
          </button>
        )}
      </div>
    </>
  )

  /**
   * EFFECTS
   */

  useEffect(() => {
    let isMounted = true

    if (isMounted && orderTemplateId) {
      if (!orderTemplate?.id || orderTemplateId !== orderTemplate?.id) {
        setLoading(true)
        getOrderTemplate(orderTemplateId)
          .then((res) => {
            setOrderTemplate(res)
            if (res.recurrence) {
              setOrderRecurrence({ orderTemplateId: res.id, ...res.recurrence })
            }
          })
          .finally(() => setLoading(false))
      }
    }

    return () => {
      isMounted = false
    }
  }, [orderTemplateId])

  useEffect(() => {
    let isMounted = true

    if (
      isMounted &&
      organization &&
      !orderTemplate.deliveryAddress?.addressLine1
    ) {
      /**
       * User address/delivery address of current org as default address of the standing order
       */
      const { deliveryAddress, address } = organization
      if (deliveryAddress) {
        setOrderTemplate({ ...orderTemplate, deliveryAddress })
      } else if (address) {
        setOrderTemplate({ ...orderTemplate, deliveryAddress: address })
      }
    }

    return () => {
      isMounted = false
    }
  }, [organization])

  return (
    <>
      <Helmet>
        <title>Standing order details</title>
      </Helmet>
      <div className="w-full flex flex-col h-full overflow-hidden">
        <Header title={`${orderTemplateId ? "Edit" : "New"} standing order`} />

        <div className="flex items-start w-full h-full flex-col flex-grow flex-nowrap relative overflow-hidden lg:flex-nowrap lg:flex-row">
          {isTabletOrMobile && showItems && (
            <OrderStepsItemList
              order={orderTemplate}
              modifyPermission={userCanModifyOrder}
              setShowItemList={setShowItemList}
              removeOrderItem={removeOrderItem}
            />
          )}

          <OrderSteps
            steps={steps}
            step={step}
            moveStep={moveStep}
            modifyPermission={userCanModifyOrder}
            loading={loading}
            isTabletOrMobile={isTabletOrMobile}
          />

          <OrderStepsSidebar
            steps={steps}
            step={step}
            moveStep={moveStep}
            modifyPermission={userCanModifyOrder}
            isTabletOrMobile={isTabletOrMobile}
            displayActionLabel={displayActionLabel}
            disableNext={disableNext}
            handleNext={handleNext}
            sidebarHeader={sidebarHeader}
          />
        </div>
      </div>
    </>
  )
}

export default EditStandingOrder
