import { get, post, put, remove } from "../api"
import { exportResponse } from "../export"
import { Dish } from "./types"

type DishResponse = Promise<Dish>

const url = "/dishes/"
export const createDish = (dish: Dish) => post(url, dish)
export const saveDish = (id, dish): DishResponse => put(url + id, dish)
export const getDish = (id): DishResponse => get(url + id)
export const getDishes = (params) => get(url, params)
export const removeDish = (id): DishResponse => remove(url + id)

export const updateDishFeaturedImage = (id, params): DishResponse => {
  return post(`${url}${id}/featured-file`, params)
}

export const updateDishFiles = (id, params): DishResponse => {
  return post(`${url}${id}/files`, params)
}
export const getDishesByIds = (params: {
  dishesIds: string[]
}): DishResponse => {
  return post(`${url}get-by-ids`, params)
}

export const exportDishes = async (format, fileName, deleted = false) => {
  const subString = deleted ? "/deleted-ingredients" : ""
  const response = await get(
    `/dishes${subString}/export-as-${format}`,
    {},
    {
      download: true,
    }
  )
  exportResponse(response, fileName)
}

export const reassignDishToOrg = (dishId, params) => {
  return post(`/dishes/${dishId}/reassign`, params)
}

export const getDishLocations = (dishId) => {
  return get(`/dishes/${dishId}/locations`)
}

export const reassingDishesToOrgs = (
  dishesIds: string[] = [],
  organizations: string[] = []
) => {
  return post(`/dishes/assign`, {
    organizations: organizations,
    dishesIds: dishesIds,
  })
}

export const unassignDishesFromOrgs = (
  dishesIds: string[] = [],
  organizations: string[] = []
) => {
  return post(`/dishes/unassign`, {
    organizations: organizations,
    dishesIds: dishesIds,
  })
}
