export const initialOrder = {
  supplier: null,
  po: null,
  id: null,
  status: undefined,
  notes: "",
  deliveryAddress: {},
  expectedDeliveryDate: null,
  items: [],
}

export const initialDeliveryNote = {
  supplier: null,
  deliveryNoteNumber: "",
  dateOfScanning: "",
  deliveryDate: "",
  files: null,
  extractedFile: null,
  selectedFiles: [],
  isCreatedManuallyWithoutOrder: false,
  globalDiscrepancies: [],
  hasDNQtyDiscrepancies: false,
  hasNoDeliveredProducts: false,
  hasNoOrderedProducts: false,
  hasReceivedQtyDiscrepancies: false,
  hasReceivedOrderQtyDiscrepancies: false,
  id: null,
  po: null,
  products: [],
  status: "DRAFT",
}

export const initialInvoice = {
  supplier: null,
  invoiceNumber: "",
  dateOfScanning: "",
  deltaTotalCost: null,
  expectedTotalCost: null,
  dateOfIssue: "",
  deliveryDate: "",
  files: null,
  extractedFile: null,
  selectedFiles: [],
  globalDiscrepancies: [],
  hasNoInvoicedProducts: false,
  hasNoOrderedProducts: false,
  hasNoReceivedProducts: false,
  hasProductPriceDiscrepancies: false,
  hasQtyDiscrepancies: false,
  hasTotalCostDiscrepancy: false,
  deliveryNoteNumber: null,
  message: "",
  totalCost: null,
  id: null,
  po: null,
  products: [],
  status: "DRAFT",
}

export const initialStockTake = {
  completedAt: "",
  createdAt: "",
  id: null,
  name: "",
  note: "",
  key: null,
  unsynced: true,
  products: [],
  recipes: [],
  totalAmount: 0,
  discrepancyReportId: null,
  totalDiscrepancyAmount: null,
  status: undefined,
  subStockTakeReports: [],
}

export const initialStockTakeSheet = {
  createdAt: "",
  id: null,
  name: "",
  note: "",
  key: null,
  unsynced: true,
  organizations: [],
  selectedOrganizations: [],
  products: [],
  updatedAt: "",
  version: null,
}

export const initialStockTransfer = {
  canceledAt: "",
  canceledByOrgId: "",
  createdAt: "",
  hasProductsComments: null,
  id: "",
  nonMatchingReceivedProductsCount: 0,
  organizations: [],
  products: [],
  recipes: [],
  dishes: [],
  receivedAt: "",
  receiverOrgId: "",
  receiverOrgName: "",
  receiverOrg: {},
  senderOrgId: "",
  senderOrgName: "",
  senderOrg: {},
  sentAt: "",
  status: "DRAFT",
}

export const initialWasteGroup = {
  id: null,
  updatedAt: "",
  products: [],
  totalCost: null,
}

export const initialPaymentPlan = {
  creationDate: null,
  expirationDate: null,
  id: "",
  orgId: "",
  stripeCustomerId: "",
  stripeSubscriptionId: "",
  stripePaymentRecurring: "",
  type: "DEFAULT",
  enabledFeatures: [],
}

export const initialOnboardingSteps = {
  id: null,
  orgId: null,
  hasAddedProducts: null,
  hasCheckedCorrectPricesAndBarcodes: null,
  hasAddedStocktake: null,
  hasAddedWaste: null,
  hasAddedRecipe: null,
}

export const newMinQuantityOrder = {
  productsInBasket: [], //Needs to have quantity property
}

export const initialGlobalOrder = {
  products: [],
}

export const initalAccounting = {
  accounts: null,
  suppliers: null,
  taxRates: null,
  taxRateZero: null,
  categories: null,
}

const InitialContext = {
  user: null,
  organization: null,
  integrations: [],
  organizationGroup: null,
  organizationPermissions: null,
  paymentPlan: initialPaymentPlan,
  organizations: [],
  selectedOrganizations: null,
  formattedOrganizations: [],
  newOrder: initialOrder,
  newMinQuantityOrder: newMinQuantityOrder,
  newDeliveryNote: initialDeliveryNote,
  newInvoice: initialInvoice,
  stockTakeSaveQueue: [],
  newStockTake: initialStockTake,
  newStockTakeSheet: initialStockTakeSheet,
  newStockTransfer: initialStockTransfer,
  onboardingSteps: initialOnboardingSteps,
  selectedWasteGroup: initialWasteGroup,
  sidebarCollapsed: false,
  mobileMenuOpen: false,
  mobileKeyboardOpen: false,
  selectedAiGeneratedRecipe: null,
  globalOrder: initialGlobalOrder,
  accounting: {},
  categories: [],
}

export default InitialContext
