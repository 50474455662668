import React from "react"
import PropTypes from "prop-types"
import { Router, Location, Redirect } from "@reach/router"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Layout from "components/layout"
import PrivateRoute from "components/router/privateRoute"
import DashboardLayout from "components/dashboard/DashboardLayout/DashboardLayout"
import Dashboard from "components/dashboard/Dashboard/Dashboard"
import Onboarding from "components/onboarding/Onboarding/Onboarding"
import OrganizationSettings from "components/organization/OrganizationSettings/OrganizationSettings"
import AccountSettings from "components/auth/AccountSettings/AccountSettings"
import Suppliers from "components/suppliers/Suppliers/Suppliers"
import Products from "components/products/Products/Products"
import ImportProducts from "components/products/ImportProducts/ImportProducts"
import OrdersOverview from "../components/orders/OrdersOverview"
import EditOrder from "components/orders/EditOrder/EditOrder"
import EditDish from "components/dishes/EditDish/EditDish"
import SupplierDetails from "components/suppliers/SupplierDetails/SupplierDetails"
import ImportSuppliers from "components/suppliers/ImportSuppliers/ImportSuppliers"
import DeliveryNotes from "components/delivery-notes/DeliveryNotes/DeliveryNotes"
import Invoices from "components/invoices/Invoices/Invoices"
import StockTakeWrapper from "components/stock/StockTakeWrapper/StockTakeWrapper"
import Waste from "components/waste/Waste/Waste"
import WasteReport from "components/waste/WasteReport/WasteReport"
import StockTransfers from "components/stock/transfers/StockTransfers/StockTransfers"
import DiscrepancyReport from "components/stock/DiscrepancyReport/DiscrepancyReport"
import BelowMinQtyReport from "components/stock/BelowMinQtyReport/BelowMinQtyReport"
import Recipes from "components/recipes/Recipes/Recipes"
import EditRecipe from "components/recipes/EditRecipe/EditRecipe"
import SaleDetails from "components/sales/SaleDetail/SaleDetail"
import ImportSales from "components/sales/ImportSales/ImportSales"
import GPReports from "components/gp-reports/GPReports/GPReports"
import GPReport from "components/gp-reports/GPReport/GPReport"
import COGSReports from "components/stock/COGSReports/COGSReports"
import COGSReport from "components/stock/COGSReport/COGSReport"
import Login from "components/auth/Login/Login"
import Signup from "components/auth/Signup/Signup"
import ResetPassword from "components/auth/ResetPassword/ResetPassword"
import ResetPasswordConfirm from "components/auth/ResetPasswordConfirm/ResetPasswordConfirm"
import AcceptInvitation from "components/organization/Invites/AcceptInvitation"
import RegisterInvitedUser from "components/organization/Invites/RegisterInvitedUser"
import PosImport from "components/integrations/PosImport/PosImport"
import CookBook from "components/cookbook/CookBook/CookBook"
import Dishes from "components/dishes/Dishes/Dishes"
import "styling/navigation.css"
import "react-day-picker/dist/style.css"
import * as cx from "classnames"
import ExportForm from "components/common/ExportForm/ExportForm"
import PriceChangesReport from "components/products/PriceChangesReport/PriceChangesReport"
import OrderApprove from "components/orders/OrderApprove/OrderApprove"
import SalesWrapper from "components/sales/SalesWrapper/SalesWrapper"
import SupplierMarketplace from "../components/suppliers/SupplierMarketplace/SupplierMarketplace"
import SupplierMarketplaceDetails from "../components/suppliers/SupplierMarketplaceDetails/SupplierMarketplaceDetails"
import CallbackHandler from "components/router/callbackHandler"
import EditStandingOrder from "../components/orders/StandingOrders/EditStandingOrder/EditStandingOrder"
import MenuProfitabilityReports from "components/MenuProfitabilityReport/MenuProfitabilityReports"
import MenuProfitabilityReport from "components/MenuProfitabilityReport/MenuProfitabilityReport"
import DiscrepancyReports from "components/stock/DiscrepancyReports/DiscrepancyReports"
import Events from "components/events/Events/Events"
import EditEvent from "components/events/EditEvent/EditEvent"

const slideRoutes = [
  "/",
  "/login",
  "/signup",
  "/reset-password",
  "/reset-password-confirm",
]

const IndexPage = () => (
  <Layout>
    <TransitionRouter>
      <Redirect from="/" to="/dashboard" noThrow />
      <PrivateRoute path="/dashboard" component={DashboardLayout}>
        <Dashboard path="/" />
        <CallbackHandler path="/callback/:callbackType" />
        <Onboarding path="welcome/*" />
        <Products path="/products/items" />
        <ImportProducts path="/products/items/import" />
        <Suppliers path="/purchases/suppliers" />
        <ImportSuppliers path="/purchases/suppliers/import" />
        <SupplierDetails path="/purchases/suppliers/:supplierId" />
        <SupplierMarketplace path="/purchases/suppliers/marketplace" />
        <SupplierMarketplaceDetails path="/purchases/suppliers/marketplace/:supplierId" />
        <OrdersOverview path="purchases/orders" />
        <DeliveryNotes path="purchases/deliveries" />
        <Invoices path="purchases/invoices" />
        <ExportForm path="purchases/:type/export" />
        <StockTakeWrapper path="stock/stocktake" />
        <DiscrepancyReport path="stock/stocktake/discrepancy-report/:discrepancyReportId" />
        <BelowMinQtyReport path="stock/stocktake/belowminqty-report/:belowMinQtyReportId" />
        <Waste path="stock/waste" />
        <WasteReport path="stock/waste-report" />
        <StockTransfers path="stock/transfers" />
        <ExportForm path="stock/:type/export" />
        <SalesWrapper path="sales" />
        <CookBook path="/products/cookbook">
          <Recipes path="/recipes" />
          <Dishes path="/dishes" />
        </CookBook>
        <Events path="/products/events" />
        <EditEvent path="/products/events/new" />
        <EditEvent path="/products/events/:eventId" />
        <EditRecipe path="/products/cookbook/recipes/new" />
        <EditRecipe path="/products/cookbook/recipes/:recipeId" />
        <EditDish path="/products/cookbook/dishes/new" />
        <EditDish path="/products/cookbook/dishes/:dishId" />
        <ImportSales path="sales/import" />
        <PosImport path="sales/pos-import" />
        <SaleDetails path="sales/:saleId" />
        <EditOrder path="purchases/orders/new" />
        <EditOrder path="purchases/orders/:orderId" />
        <EditStandingOrder path="purchases/orders/standing-orders/new" />
        <EditStandingOrder path="purchases/orders/standing-orders/:orderTemplateId" />
        <GPReports path="/insights/gp-analysis" />
        <GPReport path="/insights/gp-analysis/:gpReportId" />
        <COGSReports path="/insights/cogs-reports" />
        <COGSReport path="/insights/cogs-reports/:cogsReportId" />
        <DiscrepancyReports path="/insights/discrepancy-reports" />
        <PriceChangesReport path="/insights/price-changes" />
        <MenuProfitabilityReports path="/insights/menu-profitability" />
        <MenuProfitabilityReport path="/insights/menu-profitability/:reportId" />
        <OrganizationSettings path="company-settings" />
        <AccountSettings path="account-settings" />
      </PrivateRoute>
      <Login path="/login" />
      <Login path="/login/admin" />
      <Signup path="/signup" />
      <ResetPassword path="/reset-password" />
      <ResetPasswordConfirm path="/reset-password-confirm" />
      <AcceptInvitation path="/accept-invitation" />
      <RegisterInvitedUser path="/register-invited-user" />
      <OrderApprove path="/order-approve" />
    </TransitionRouter>
  </Layout>
)

let prevLocationPath = ""

const TransitionRouter = ({ children }) => (
  <Location>
    {({ location }) => {
      const isInitialScreen =
        !prevLocationPath.length || location.pathname === "/dashboard"

      prevLocationPath = location.pathname

      return (
        <TransitionGroup
          className={cx("transitionContainer", {
            NavigationIsBackwards: location.state && !!location.state.backwards,
          })}
        >
          <CSSTransition
            key={location.key}
            className={cx("page", {
              slide: slideRoutes.indexOf(location.pathname) > -1,
              pageInitial: isInitialScreen,
            })}
            timeout={slideRoutes.indexOf(location.pathname) > -1 ? 450 : 0}
          >
            <Router location={location} basepath="/" className="router">
              {children}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )
    }}
  </Location>
)

TransitionRouter.propTypes = {
  children: PropTypes.any,
}

export default IndexPage
