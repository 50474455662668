import { formatISO, set } from "date-fns"
import {
  zonedTimeToUtc,
  formatInTimeZone,
  format,
  utcToZonedTime,
} from "date-fns-tz"
import { navigate } from "gatsby"
import { logout } from "./auth"
import { wasteReasons, recipeMeasures } from "./constants"
import { createOrder } from "./orders/orders"
import { showError, showInfo } from "./toast"
import { GrowyzeBackendGETResponse, Category } from "./types"

export const isBrowser = () => typeof window !== "undefined"

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0
}

export const toQueryString = (params) => {
  let encodedStr = ""
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (encodedStr && encodedStr[encodedStr.length - 1] !== "&") {
        encodedStr = encodedStr + "&"
      }
      const value = params[key]
      if (value instanceof Array) {
        for (const i in value) {
          if (value.hasOwnProperty(i)) {
            encodedStr =
              encodedStr + key + "=" + encodeURIComponent(value[i]) + "&"
          }
        }
      } else if (typeof value === "object") {
        for (const innerKey in value) {
          if (value.hasOwnProperty(innerKey)) {
            encodedStr =
              encodedStr +
              key +
              "[" +
              innerKey +
              "]=" +
              encodeURIComponent(value[innerKey]) +
              "&"
          }
        }
      } else {
        encodedStr = encodedStr + key + "=" + encodeURI(value)
        if (encodedStr.includes("+")) {
          encodedStr = encodedStr.replace("+", "%2B")
        }
      }
    }
  }
  if (encodedStr[encodedStr.length - 1] === "&") {
    encodedStr = encodedStr.substr(0, encodedStr.length - 1)
  }
  return encodedStr
}

export const pathGetIndex = (obj, path, val, field = "id") => {
  const o = pathGet(obj, path)
  return o.findIndex((i) => i[field] === val)
}

export const getInitials = ({ user }) => {
  const fullName = user.firstName + " " + user.lastName
  const names = fullName.split(" ")
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const getInitialsFromString = (str: string) => {
  const names = str.split(" ")
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const truncateFileName = (n, len) => {
  const ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase()
  let filename = n.replace("." + ext, "")
  if (filename.length <= len) {
    return n
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "")
  return filename + "." + ext
}

/*
 * Normalizes null values, usefull for responses with null values that need
 * to be used in controlled forms
 */
export const normalizeNullValues = (obj) =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)))

/*
export const formatCurrencyValue = (value, currency = "GBP") => {
  const formats = {
    GBP: "en-GB",
    EUR: "nl-NL",
    USD: "en-US",
  }

  return new Intl.NumberFormat(formats[currency || "GBP"], {
    style: "currency",
    currency: currency || "GBP",
  }).format(value)
}
*/

export const formatCurrencyValue = (
  value: number,
  digits?: number,
  suffixZeros?: false
) => {
  const res = roundNumber(value, digits)
  if (suffixZeros && decimalCount(res)) {
    return new String(res) + "0"
  }
  return res
}

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const base64toBlob = async (base64Data) => {
  const photo = await fetch(base64Data)
  return photo.blob()
}

export const getByteArray = (file) => {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.readAsArrayBuffer(file)
    fileReader.onload = function (ev: any) {
      const array = new Uint8Array(ev.target.result)
      const fileByteArray: any[] = []
      for (let i = 0; i < array.length; i++) {
        fileByteArray.push(array[i])
      }
      resolve(array) // successful
    }
    fileReader.onerror = reject // call reject if error
  })
}

export const base64toFile = async (base64Data) => {
  const photo = await fetch(base64Data)
  const blob = await photo.blob()
  if (blob) {
    const extension = blob.type.split("/").pop()
    return new File([blob], `${uuidv4()}.${extension}`, { type: blob.type })
  }
}

export const getMimeTypeFromDataUri = (dataUri) => {
  return (
    dataUri && dataUri.substring(dataUri.indexOf(":") + 1, dataUri.indexOf(";"))
  ) // => image/png
}

/**
 * Rounds a number to 2 digits if necessary
 *
 * @return  {Number} returns a rounded number
 */
export const roundNumber = (number: number, digits?: number): number => {
  const num = Number(number)
  if (digits !== undefined) {
    return num % 1 === 0
      ? num //Return whole number if it has no decimal numbers ( Example : | 40,0005 = 40,0005 | 40,0000 = 40 | 0,005 = 0,005 )
      : Number(num.toFixed(digits)) //Original return - Fixing the original to make it return a number
  } else {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
}

export const calculateStepSize = (datasets) => {
  const stepSize = Math.abs(
    Array.isArray(datasets)
      ? Math.max(
          ...datasets.map((a: any) =>
            Array.isArray(a.data) ? Math.max(...a.data) : a.data
          )
        )
      : datasets
  )
  if (stepSize > 50000) {
    return 50000
  } else if (stepSize > 10000) {
    return 10000
  } else if (stepSize > 1000) {
    return 1000
  } else if (stepSize > 500) {
    return 500
  } else if (stepSize > 250) {
    return 250
  } else if (stepSize > 100) {
    return 100
  } else if (stepSize > 50) {
    return 50
  } else if (stepSize > 10) {
    return 10
  } else {
    return 1
  }
}

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w]+/g, "") // Remove all non-word chars
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export const getDeviceOrientation = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return "PORTRAIT"
  }

  if (window.matchMedia("(orientation: landscape)").matches) {
    return "LANDSCAPE"
  }
}

export const formatReason = (reason) =>
  wasteReasons.find((wasteReason) => wasteReason.value === reason)?.label

export const removeTimezone = (dateString) => {
  return formatISO(new Date(dateString)).substring(0, 19) + "Z"
}

export const removeTimezoneEndDate = (dateString) => {
  return (
    formatISO(new Date(dateString).setHours(23, 59, 59)).substring(0, 19) + "Z"
  )
}

const addTrailingZeros = (num) => String(num).padStart(2, "0")

export const formatAsYearMonthDay = (date) =>
  [
    date.getFullYear(),
    addTrailingZeros(date.getMonth() + 1),
    addTrailingZeros(date.getDate()),
  ].join("-")

/**
 * Re-add timezone offset (extra), to prevent remove off offset on .toISOString() function
 */
export const convertTimeZone = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}

export const checkIsAutomaticBarcode = (barcode) => {
  return barcode.match(/[-]/g)?.length === 4
}

export const countCompletedSteps = (steps) => {
  if (steps === undefined || null) return

  const totalSteps = Object.keys(steps).length
  const completedSteps = Object.values(steps).reduce((acc, value) => {
    const stepCompleted = value.checked === true || value.checked

    if (stepCompleted) {
      return ++acc
    }
    return acc
  }, 0)

  return `${completedSteps}/${totalSteps}`
}

export const calculateProgress = (steps) => {
  if (steps === undefined || null) return

  const stepsCompleted = countCompletedSteps(steps)
  const completedSteps = parseInt(stepsCompleted.substring(0, 1))
  const totalSteps = parseInt(stepsCompleted.substring(3, 2))

  return (completedSteps / totalSteps) * 100
}

export const MAX_FILE_SIZE = 3000

export const isNumber = (x) => {
  const isNumberRegex = /^-?\d*\.?\d*$/

  return isNumberRegex.test(x)
}

export const isInteger = (x) => {
  const isIntegerRegex = /^[0-9]+$/

  return isIntegerRegex.test(x)
}

export const decimalCount = (num): number => {
  const numStr = String(num)

  if (numStr.includes(".")) {
    return numStr.split(".")[1].length
  }

  return 0
}

export const formatQuantityValue = (val) => {
  let parsedValue
  if (val !== 0 && !val) {
    parsedValue = ""
  } else {
    if (Number.isInteger(val)) {
      parsedValue = parseInt(val)
    } else {
      parsedValue = roundNumber(Number(val))
    }
  }
  return parsedValue
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const orgMapToArray = (data) => {
  const orgs = data && data.orgIdToOrgName
  if (!orgs) {
    return []
  }

  const formattedOrgs: {
    label: string
    value: any
    type: "STANDARD" | "MAIN" | "SUB"
  }[] = []
  Object.keys(orgs).forEach((org) => {
    formattedOrgs.push({
      label: orgs[org],
      value: org,
      type: data.organizations?.find((o) => o.id === org).type,
    })
  })
  return formattedOrgs
    .sort((a, b) => {
      if (a.label.toLowerCase() === b.label.toLowerCase()) {
        return 0
      }
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    })
    .sort((a, b) => {
      if (a.type === b.type) {
        return 0
      }
      return a.type === "MAIN" ? -1 : 1
    })
}

export const isEmptyObj = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

export const convertToGMT = (date) =>
  new Date(date).toISOString().substring(0, 19) + "Z"

/**
 * Get current timezone (this is based on browser)
 *
 * @return  {String}  E.G. 'Europe/Amsterdam'
 */
export const getBrowserTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Resets a date to GTM (offset = 0) and converts again into specified timezone
 *
 * @param   {Date}  date  Date object
 * @param   {String}  tz    E.g. "Europe/Amsterdam"
 *
 * @return  {String}        Date as String
 */
export const convertToTimeZoneUTCString = (date, tz) => {
  return zonedTimeToUtc(date, tz).toISOString()
}

export const localDateToZonedISOString = (date, tz) => {
  // Step 1: convert local datetime to UTC
  const utcDate = zonedTimeToUtc(date, getBrowserTimeZone())
  // Step 2: convert UTC to specified timezone
  const zonedDate = utcToZonedTime(utcDate, tz)
  // Step 3 Format the date as an ISO string
  return format(zonedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: tz,
  })
}

/**
 * Converts a UTC string to a local date
 *
 * @param   {utcString}  utcString
 *
 * @return  {localDateString}
 */
export const convertUTCStringToLocal = (utcString) => {
  const localDate = utcToZonedTime(utcString, getBrowserTimeZone())
  return localDate
}

/**
 * Resets a date to GTM (offset = 0) and converts again into specified timezone
 *
 * @param   {Date}  date  Date object
 * @param   {String}  tz    E.g. "Europe/Amsterdam"
 *
 * @return  {Date}        Date object
 */
export const convertToTimeZoneUTC = (date, tz) => {
  return zonedTimeToUtc(date, tz)
}
/**
 *  Convert a date to the correct time based on the organizations timezone.
 *  @param {Date|String} date You can set the timestamp or a date object in here
 *  @param {String} timeZone set here the timezone of the organization
 *  @param {Boolean} timeOnly set True, to show only the Time
 */
export const convertTimeStampFormat = (
  date,
  timeZone,
  timeOnly = false,
  addSeconds?: boolean
) =>
  formatInTimeZone(
    date,
    timeZone ?? "Europe/London",
    timeOnly
      ? `HH:mm${addSeconds ? ":ss" : ""}`
      : `dd-MM-yyyy HH:mm${addSeconds ? ":ss" : ""}`
  )

export const convertToTimeZoneDate = (date, timeZone) => {
  return formatInTimeZone(
    date,
    timeZone ?? "Europe/London",
    "yyyy-MM-dd HH:mm:ss"
  )
}

export const shortDateStr = (datetimeStr: string) => {
  return datetimeStr.substring(0, 10)
}

export const utcMidnightDate = (date) => {
  const dateString =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  const myDate = Date.parse(dateString)
  return new Date(myDate).toISOString()
}

export const convertToFormat = (date, formatstr?) =>
  format(date, formatstr ?? "dd-MM-yyyy")

export const convertTimeStampDate = (date, timeZone?, format?) =>
  formatInTimeZone(date, timeZone ?? "Europe/London", format ?? "dd-MM-yyyy")

/** Convert date in format "dd-MM-yyyy" to "yyyy-mm-ddThh:mm:ssZ" as used by Growyze api  */
export const convertEnGbDateToISOstring = (date: string) =>
  new Date(date.split("-").reverse().join("/")).toISOString()

export const getFileNameDateTime = (date = new Date()) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0")

  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const seconds = String(date.getSeconds()).padStart(2, "0")

  const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`

  return formattedDateTime
}

export const formatLocalDate = (date: Date | number, zoneId: string) => {
  if (!date) return ""
  const dateFrom = set(date, {
    hours: 0,
    minutes: 0,
  })
  return zonedTimeToUtc(dateFrom, zoneId)
}

export const extractNumbersFromString = (str) => {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[^0-9\.-]+/g, "")
}

export const setQtyInputValues = (qtyInputTypes, value) => {
  //Sets multiple qtyInputTypes to single value
  const obj = {}
  qtyInputTypes.forEach((type) => (obj[type.quantityKey] = value))

  return obj
}

export const sumArrayValues = (arr) => arr.reduce((a, b) => a + b, 0)

export const formatReportSelectOptionLabel = (obj, type) => {
  switch (type) {
    case "stocktake":
      return `${obj.stockTakeReport.name} (finalised: ${convertTimeStampDate(
        new Date(obj.stockTakeReport.completedAt)
      )})`
    case "sales":
      return `${
        obj.isFromSquare ? "Square import" : "Manual import"
      } (${convertTimeStampDate(new Date(obj.from))} - ${convertTimeStampDate(
        new Date(obj.to)
      )}, total: ${obj.totalSales})`
    default:
      return type
  }
}

export const isEmail = (str) => {
  return !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str)
}

export const getProductMeasures = (measure) => {
  if (measure != undefined) {
    const measureType = (
      recipeMeasures.find((ms) => ms.value === measure) || {
        type: "each",
      }
    ).type
    return recipeMeasures.filter((ms) => ms.type === measureType)
  } else {
    return []
  }
}
const getRecipeSpecificMeasures = (recipe) => {
  if (recipe.portion != undefined) {
    return [
      {
        label: "portion",
        value: "portion",
        type: "portion",
      },
    ]
  } else {
    return []
  }
}

export const getRecipeMeasures = (recipe) => {
  return getRecipeSpecificMeasures(recipe).concat(
    getProductMeasures(recipe.yield?.measure)
  )
}
export const normalizeIngredientMeasures = (ingr) => {
  //Turns ingredient.measure "%" symbol into "percentage" to match what backend expects
  if (ingr.measure === "%") {
    // const qty = ingr.usedQty
    // ingr.usedQty = qty * (ingr.product.size / 100)
    ingr.measure = "percentage"
  }
  return ingr
}

export const calculateNumberInputStep = (number) => {
  const count = decimalCount(number)
  return count && count > 0 ? (count >= 3 ? 0.001 : count >= 2 ? 0.01 : 0.1) : 1
}

export const formatNumberInputValue = (inputValue) => {
  let value = ""
  const valueDecimals = inputValue ? decimalCount(inputValue) : null

  if (valueDecimals === 0) {
    value = Number(inputValue).toFixed(0)
  }

  if (valueDecimals !== null && valueDecimals === 1) {
    value = Number(inputValue).toFixed(1)
  }

  if (valueDecimals !== null && valueDecimals >= 2) {
    value = Number(inputValue).toFixed(2)
  }

  if (valueDecimals !== null && valueDecimals >= 3) {
    value = Number(inputValue).toFixed(3)
  }

  return value
}

export const changeNumberInputValue = (
  inputValue: string | number,
  type = "incr"
): string => {
  if (type !== "incr" && type !== "decr") {
    // @ts-ignore
    return console.error(
      "changeNumberInputValue expects a 'incr' or 'decr' as type"
    )
  }
  return decimalCount(inputValue) && decimalCount(inputValue) > 0
    ? decimalCount(inputValue) >= 2
      ? (type === "incr"
          ? Number(inputValue) + 0.01
          : Number(inputValue) - 0.01
        ).toFixed(2)
      : (type === "incr"
          ? Number(inputValue) + 0.1
          : Number(inputValue) - 0.1
        ).toFixed(1)
    : (type === "incr"
        ? Number(inputValue) + 1
        : Number(inputValue) - 1
      ).toFixed(0)
}

export const handleError = (response) => {
  if (response.status === undefined) {
    showError(response.message)
  } else if (response.status === 401) {
    logout()
  } else if (response.status === 403) {
    showInfo(
      "Your plan or role does not allow you to access this functionality. Please contact support"
    )
  } else {
    response.json().then((result) => {
      if (result.errors && result.errors.length > 0) {
        showError(result.errors[0].defaultMessage || "Operation failed")
      } else {
        showError(result.message || "Operation failed")
      }
    })
  }
}

export const apiResponseIsError = (response) => {
  if (!response) return true
  return (
    response?.status?.toString().charAt(0) === "4" ||
    response?.status?.toString().charAt(0) === "5"
  )
}

export const capitaliseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
export const ucfirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const calculateDifference = (a, b) => {
  return Math.abs(a - b)
}

export const groupBy = <A>(
  fieldName: string,
  array: Array<A>
): { [key: string]: Array<A> } =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[fieldName]]: (obj[item[fieldName]] || []).concat([item]),
    }),
    {}
  )

export const groupGlobalProductsBySuppliers = (products: any[]) => {
  const groupedBySupplierId = groupBy(
    "supplierId",
    products.map((p) => {
      return {
        ...p,
      }
    })
  )

  const formattedProducts = Object.values(groupedBySupplierId).reduce(
    (result, items) => {
      const supplier = {
        supplierName: items[0].supplierName,
        supplierId: items[0].supplierId,
      }
      const target = {
        supplier,
        products: items,
      }
      return result.concat(target)
    },
    []
  )

  return formattedProducts
}

export const createOrderToSupplier = (
  order: {
    products: any[]
    supplier: {
      supplierId: string
      supplierName: string
      // value: string
    }
  },
  skipSendingEmail: boolean,
  user,
  organization
) => {
  const params = {
    items: order.products.map((p) => {
      return {
        ...p,
        quantity: p._basketQuantity,
        productId: p.id,
        orderInCase: p._selectedCase === "multiple",
      }
    }),
    deliveryAddress: organization.address,
    status: "DRAFT",
    supplier: {
      id: order.supplier.supplierId,
      name: order.supplier.supplierName,
    },
  }

  return createOrder(params, {
    firstName: user.firstName,
    lastName: user.lastName,
    skipSendingEmail,
  })
}

export const saveOrder = async (
  status: string,
  skipSendingEmail: boolean,
  groupedOrders,
  user,
  organization
) => {
  for (const order of groupedOrders) {
    await createOrderToSupplier(order, skipSendingEmail, user, organization)
  }
  navigate("/dashboard/purchases/orders", { replace: true })
  // dispatch({ type: "CLEAR_GLOBAL_ORDER_PRODUCT" })
}
export const findValueLabel = (
  options: Array<{ label: string; value: any }>,
  value: any
) => options.find((option) => option.value === value)?.label

export const findValueIcon = (
  options: Array<{ label: string; value: any; icon: string }>,
  value: any
) => options.find((option) => option.value === value)?.icon

export const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index
}

export const standardiseMeasure = (measure: string) => {
  let standardisedMeasure = measure.toLowerCase()

  if (standardisedMeasure === "kilo") {
    standardisedMeasure = "kg"
  }
  return standardisedMeasure
}

export const objHasValue = (object: any) => {
  return (
    object &&
    Object.keys(object).some(function (k) {
      return object[k]
    })
  )
}

export const objHasAllKeysFilled = (obj: any) => {
  return Object.values(obj)
    .filter((a) => typeof a === "string")
    .every((a) => Boolean(a))
}

export const extractNumberFromString = (str: string) => {
  const num = str.replace(/\D+/g, "")
  return num ? num : undefined
}

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const kebabToCamelCase = (s) =>
  s.replace(/-./g, (x) => x[1].toUpperCase())

export const getAllItemsFromPaginatedResponse = async (
  func: (params: object) => Promise<GrowyzeBackendGETResponse<any>>,
  params?: object
) => {
  const items: any[] = []

  let page = 0
  let response: Pick<
    GrowyzeBackendGETResponse<any>,
    "totalPages" | "content"
  > = {
    totalPages: 1,
    content: [],
  }

  while (page < response.totalPages) {
    params = { ...params, page }
    response = await func(params)
    if (response) {
      items.push(...response.content)
      page = page + 1
    }
  }

  return items
}

/**
 * Returns a new array containing items that have a unique value for the given key
 */
export const removeDuplicatesFromArray = (arr: object[] = [], key = "id") => {
  const found = {}
  const unique: object[] = []

  if (!arr) return unique

  arr.forEach((item) => {
    if (!found[item[key]]) {
      found[item[key]] = true
      unique.push(item)
    }
  })

  return unique
}

/**
 * Formats custom categories for use in AsyncSelect component
 */
export const formatCategoriesForAsyncSelect = (categories: Category[]) => {
  return categories.map((cat) => {
    return {
      label: cat.name,
      value: cat.name,
      subCategories: cat.subCategories.map((subCat) => {
        return {
          label: subCat.name,
          value: subCat.name,
          // groupValue: cat.name,
          // groupLabel: cat.name,
        }
      }),
    }
  })
}

/**
 * Initialises query param state.
 * Used on recipe and dish overview pages
 */
export const initialiseQueryParameterState = (
  queryParametersList: { label: string; value: string }[],
  searchParamType: string,
  fallbackParamType: string
) => {
  const foundQueryParam = queryParametersList.find(
    (queryParam) => queryParam.value === searchParamType
  )

  return foundQueryParam
    ? foundQueryParam
    : queryParametersList.find(
        (queryParam) => queryParam.value === fallbackParamType
      )
}

/**
 * Prints the current page.
 *
 * @return {void} No return value.
 * Although execCommand is deprecated this is the only way to make it work on Safari:
 * See https://stackoverflow.com/a/72818375
 */
export const printPage = () => {
  try {
    if (!document.execCommand("print", false, undefined)) {
      window.print()
    }
  } catch {
    window.print()
  }
}

export const flattenObject = (
  ob: any,
  prefix: any = false,
  result: any = null
) => {
  result = result || {}

  // Preserve empty objects and arrays, they are lost otherwise
  if (
    prefix &&
    typeof ob === "object" &&
    ob !== null &&
    Object.keys(ob).length === 0
  ) {
    result[prefix] = Array.isArray(ob) ? [] : {}
    return result
  }

  prefix = prefix ? prefix + "." : ""

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      // Only recurse on true objects and arrays, ignore custom classes like dates
      if (
        typeof ob[i] === "object" &&
        (Array.isArray(ob[i]) ||
          Object.prototype.toString.call(ob[i]) === "[object Object]") &&
        ob[i] !== null
      ) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result)
      } else {
        result[prefix + i] = ob[i]
      }
    }
  }
  return result
}

export const unflattenObject = (ob: any) => {
  const result = {}
  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      const keys: any = i.match(/(?:^\.+)?(?:\.{2,}|[^.])+(?:\.+$)?/g) // Just a complicated regex to only match a single dot in the middle of the string
      keys.reduce((r, e, j) => {
        return (
          r[e] ||
          (r[e] = isNaN(Number(keys[j + 1]))
            ? keys.length - 1 === j
              ? ob[i]
              : {}
            : [])
        )
      }, result)
    }
  }
  return result
}
