import React, { useContext } from "react"
import { useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { AsideContext } from "context/aside/AsideContext"
import AsideCalendar from "../common/AsideCalendar/AsideCalendar"
import { convertToFormat } from "services/helpers"
import { FlexiblePeriod } from "services/types"

interface DateInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  className?: string
  label?: string
  disableBefore?: Date
  [key: string]: any
}

const DateInput = ({
  label,
  disableBefore,
  className = "",
  ...props
}: DateInputProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, , helpers] = useField(props)

  const { setValue } = helpers

  const aside = useContext(AsideContext)

  const toggleDatePicker = () => {
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      mode: "single",
      disable: { before: disableBefore ? disableBefore : null },
      onConfirm: (data: FlexiblePeriod) => {
        if (data.from) {
          setValue(data.from)
        }
        aside.hideAside()
      },
    })
  }

  return (
    <>
      {label && (
        <label
          className="font-sansBold text-primaryBlue mb-2"
          htmlFor={props.name}
        >
          {label}
        </label>
      )}
      <div
        className="inline-flex relative items-center w-full"
        onClick={() => toggleDatePicker()}
      >
        <input
          className={`cursor-pointer flex-grow px-4 relative border border-gray-300 shadow-none bg-clip-padding ${className}`}
          type="text"
          style={{ height: "45px" }}
          readOnly={true}
          {...props}
          {...field}
          value={
            field.value
              ? field.value instanceof Date
                ? convertToFormat(field.value)
                : convertToFormat(new Date(field.value))
              : ""
          }
        />
        <FontAwesomeIcon
          icon={faCalendar}
          className="absolute right-4 z-10 text-gray-700"
        />
      </div>
    </>
  )
}

export default DateInput
