/* eslint-disable react/prop-types */
import { faCheck, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect } from "react"
import { getDishes } from "services/dishes/dishes"
import { usePrevious, useUpdateEffect } from "react-use"

import { formatCurrencyValue } from "services/helpers"

import BaseTable, { AutoResizer } from "react-base-table"
import SearchInput from "components/forms/SearchInput"

//@ts-ignore
import * as styles from "./DishSelect.module.css"
import DishIcon from "components/dishes/DishIcon/DishIcon"
import { SelectedItem } from "../NewProductSelect/types"

interface DishSelectProps {
  onSelect: (dish: any) => void
  createNewDish?: ((data: any) => void) | null
  multiSelect?: boolean
  onClose?: () => void
  highlightPosId?: boolean
}

const DishSelect = ({
  onSelect,
  createNewDish,
  multiSelect,
  onClose,
  highlightPosId,
}: DishSelectProps) => {
  const [query, setQuery] = useState("")
  const [dishes, setDishes] = useState([])
  const previousQ = usePrevious(query)
  const [loading, setLoading] = useState(false)

  const [selectedDishes, setSelectedDishes] = useState<Array<SelectedItem>>([])

  const resetState = () => {
    setSelectedDishes([])
  }

  const getData = async () => {
    setLoading(true)
    const params = {
      sort: "createdDate,desc",
      partialDishName: query,
    }

    const response = await getDishes(params)

    if (response) {
      setDishes(response.content)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useUpdateEffect(() => {
    if (query || previousQ) {
      getData()
    }
  }, [query])

  const columns = [
    {
      key: "description",
      title: "",
      dataKey: "description",
      width: 180,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <>
            <DishIcon dish={rowData} onClick={() => onSelect(rowData)} />
            <div
              className="flex flex-col p-3"
              onClick={() => onSelect(rowData)}
            >
              <div className="text-md text-primaryBlue">
                <span className="font-sansBold">{rowData.name}</span>{" "}
                {highlightPosId && rowData.posId && (
                  <span className="text-gray-600 font-light">
                    {`(Current POS ID: ${rowData.posId})`}
                  </span>
                )}
              </div>
              <div className="text-md">{rowData.description || "-"}</div>
            </div>
          </>
        )
      },
    },
    {
      key: "cost",
      title: "",
      dataKey: "cost",
      width: 180,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div>
            <div className="text-sm text-gray-700">Cost</div>
            <div className="text-md">
              {formatCurrencyValue(rowData.totalCost)}
            </div>
          </div>
        )
      },
    },
    {
      key: "action",
      dataKey: "action",
      width: 60,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const isSelected = selectedDishes?.find(
          (dish) => dish.id === rowData.id
        )
        return (
          <div>
            <div className="absolute right-5 top-5">
              {!isSelected ? (
                <button
                  className={styles.qtyButton}
                  onClick={(e) => {
                    e.preventDefault()
                    if (!multiSelect) {
                      onSelect(rowData)
                    } else {
                      const latestDishes = [...selectedDishes]
                      latestDishes.push(rowData)
                      setSelectedDishes(latestDishes)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              ) : (
                <button
                  type="button"
                  className="button whitespace-nowrap button--smaller button--autoWidth button--primaryBlueLightweight mr-2"
                  onClick={(e) => {
                    e.preventDefault()
                    const latestDishes = [...selectedDishes]
                    setSelectedDishes(
                      latestDishes.filter((p) => p.id !== rowData.id)
                    )
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  Selected
                </button>
              )}
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          label="Name"
          placeholder="Dish name"
          className="form-control rounded"
          onSearchChange={(val) => {
            setQuery(val)
          }}
        />
        {onClose && (
          <button
            type="button"
            onClick={() => {
              onClose()
            }}
            className="text-xl px-3 ml-3"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
      <div className={styles.content}>
        <div className="w-full flex-grow">
          <AutoResizer height={Infinity}>
            {({ width, height }) => (
              <BaseTable
                fixed={false}
                estimatedRowHeight={80}
                width={width}
                ignoreFunctionInColumnCompare={false}
                maxHeight={dishes?.length === 0 ? undefined : height}
                height={dishes?.length === 0 ? 200 : height}
                headerHeight={0}
                data={dishes}
                columns={columns}
                emptyRenderer={
                  <div className={styles.noProducts}>
                    <p className="mb-4">
                      {loading ? "Loading..." : "No dishes found"}
                    </p>
                  </div>
                }
              />
            )}
          </AutoResizer>
        </div>
      </div>
      {(createNewDish || multiSelect) && (
        <footer className={styles.footer}>
          <div
            className={
              "flex px-4 lg:px-6 py-3 lg:py-4 w-full items-center justify-end"
            }
          >
            {multiSelect && (
              <div className="flex items-start flex-grow">
                <span className="font-semibold mr-4 text-left flex flex-grow">
                  <span className="text-primaryBlue mr-2">
                    {selectedDishes.length}{" "}
                    {selectedDishes.length === 1 ? "dish" : "dishes"}
                  </span>
                  <span className="text-gray-600">selected</span>
                </span>
              </div>
            )}
            {createNewDish && (
              <button
                onClick={createNewDish}
                className={"button button--autoWidth button--primaryGreen"}
              >
                <FontAwesomeIcon icon={faPlus} className={styles.buttonIcon} />
                <span className={styles.buttonText}>Add dish</span>
              </button>
            )}
            {multiSelect && (
              <button
                type="button"
                className="button button--autoWidth button--primaryGreen"
                onClick={() => {
                  onSelect(selectedDishes)
                  resetState()
                }}
                disabled={selectedDishes.length < 1}
              >
                <FontAwesomeIcon icon={faCheck} className={styles.buttonIcon} />
                <span className={styles.buttonText}>Confirm</span>
              </button>
            )}
          </div>
        </footer>
      )}
    </div>
  )
}

export default DishSelect
