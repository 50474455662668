import NProgress from "nprogress"
import { toQueryString, isBrowser, isEmptyObj } from "./helpers"
import { logout } from "./auth"
import { handleRateLimit } from "./rate-limits"

const ENV = process.env.NODE_ENV
const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT
const API_URL = process.env.GATSBY_API_URL

interface options {
  authenticate?: boolean
  token?: string | null
  download?: boolean
  plain?: boolean
  headers?: {} | null
  signal?: AbortSignal | null
  query?: any
}

const defaultOptions: options = {
  authenticate: true,
  token: null,
  download: false,
  plain: false,
  headers: null,
  signal: null,
}

export const setToken = (token) => {
  return new Promise((resolve) => {
    window.localStorage.setItem(`growyzeToken_${ENVIRONMENT}`, token)
    resolve(undefined)
  })
}

export const getConfig = () => ({
  basePath: ENV === "development" ? "" : API_URL,
})

export const getBasePath = () => (ENV === "development" ? "" : API_URL)

export const getParams = () => ({
  headers: {
    Authorization: window.localStorage.getItem(`growyzeToken_${ENVIRONMENT}`),
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})
export const getToken = () => {
  return new Promise((resolve) => {
    const token = window.localStorage.getItem(`growyzeToken_${ENVIRONMENT}`)
    if (token) {
      resolve(token)
    } else {
      resolve(null)
    }
  })
}

export const removeToken = () => {
  return new Promise((resolve) => {
    window.localStorage.removeItem(`growyzeToken_${ENVIRONMENT}`)
    resolve(undefined)
  })
}

export const get = (
  url,
  query = {},
  options = defaultOptions,
  version = "/v1"
) => {
  return doRequest({
    version,
    url,
    query,
    method: "GET",
    ...defaultOptions,
    ...options,
  })
}

export const remove = (
  url,
  params = {},
  options = defaultOptions,
  version = "/v1"
) => {
  return doRequest({
    version,
    url,
    params,
    method: "DELETE",
    ...defaultOptions,
    ...options,
  })
}

export const post = (
  url,
  params = {},
  options = defaultOptions,
  version = "/v1"
) => {
  return doRequest({
    version,
    url,
    params,
    method: "POST",
    ...defaultOptions,
    ...options,
  })
}

export const put = (
  url,
  params = {},
  options = defaultOptions,
  version = "/v1"
) => {
  return doRequest({
    version,
    url,
    params,
    method: "PUT",
    ...defaultOptions,
    ...options,
  })
}

export const patch = (
  url,
  params = {},
  options = defaultOptions,
  version = "/v1"
) => {
  return doRequest({
    version,
    url,
    params,
    method: "PATCH",
    ...defaultOptions,
    ...options,
  })
}

export const JSONreplacer = (key, value) => {
  return value === "" || value === undefined ? null : value
}

export const doRequest = async (config) => {
  const browser = isBrowser()
  if (browser) {
    NProgress.start()
  }

  let url

  if (ENV === "development") {
    url = `${config.version + config.url}`
  } else {
    url = `${API_URL + config.version + config.url}`
  }

  const reqConfig = {
    method: config.method,
    signal: config.signal,
    download: config.download,
    plain: config.plain,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(config.headers ? config.headers : {}),
    },
  }

  // Add auth header if needed
  if (config.authenticate) {
    const token = await getToken()
    reqConfig.headers["Authorization"] = `${token}`
  }

  if (config.query && !isEmptyObj(config.query)) {
    const queryString = toQueryString(config.query)
    url = `${url}?${queryString}`
  }

  if (config.params && !isEmptyObj(config.params)) {
    reqConfig["body"] =
      reqConfig.headers["Content-Type"] === "application/json"
        ? JSON.stringify(config.params, JSONreplacer)
        : config.params
  }

  if (
    reqConfig.headers["Accept"] !== "application/octet-stream" && //Adding this to prevent removal of content header for .xlsx download types
    reqConfig.headers["Content-Type"] === "multipart/form-data"
  ) {
    //Dont really understand the purpose of this DELETE,
    //Content-Type is being passed into the config so it should remain how it was specified, this seems like bad practice
    delete reqConfig.headers["Content-Type"]
  }
  return fetch(url, reqConfig)
    .then(async (response) => {
      let result

      if (!response.ok && response.status === 401) {
        if (browser) {
          return logout()
        }
      } else if (!response.ok && response.status === 403) {
        result = response.json()
      } else if (!response.ok && response.status === 429) {
        result = response.json()
        result.then((data) => {
          handleRateLimit(data)
        })
        return false // return false but not an error
      } else if (response) {
        // Catch empty response
        if (response.status === 204) {
          result = Promise.resolve(true)
        } else if (reqConfig.download) {
          result = response
        } else if (reqConfig.plain) {
          result = response.text()
        } else {
          result = response.json()
        }
      }
      return result
    })
    .finally(() => {
      if (browser) {
        NProgress.done()
      }
    })

  /*
  // do some more testing before implementing this
  .catch((error) => {
    if (browser) {
      const errorStr = new String(error)
      if (
        errorStr.match("NetworkError") ||
        errorStr.match("Failed to fetch")
      ) {
        showError("Network Error")
      }else{
        return Promise.reject(error)
      }
    }else{
      return Promise.reject(error)
    }
  })
  */
}
