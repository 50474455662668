import { OtherIngredientRow } from "services/dishes/dishes"
import React from "react"
import TextInput from "components/forms/TextInput"

export default ({
  element,
  path,
  disabled,
}: {
  element: OtherIngredientRow
  path: string
  disabled?: boolean
}) => (
  <div className="form-group w-full">
    <div className="input-container">
      <TextInput
        required={true}
        name={`${path}.name`}
        label="Description"
        type="text"
        value={element.name}
        disabled={disabled}
        showValidations={false}
        placeholder="Description"
        className="form-control form-control--first form-control--last"
      />
    </div>
  </div>
)
