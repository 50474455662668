import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactTooltip from "react-tooltip"
import { allergens } from "services/constants"
import { findValueIcon, findValueLabel } from "services/helpers"

const AllergenList = ({ entityId, entity }) => {
  const entityHasAllergens = [
    ...(entity.allergens || []),
    ...(entity.mayContainAllergens || []),
  ].length
  return (
    <>
      <span className="inline-flex items-baseline justify-start">
        <h2 className="mr-1 mb-3 font-sansSemiBold font-semibold text-gray-800">
          Allergens
        </h2>
        <ReactTooltip
          id={`infoBox-allergens-${entityId}`}
          type="light"
          place="top"
          effect="solid"
          border={true}
          borderColor="#e2e8f0"
        />
        <span
          data-tip="growyze accepts no liability for the use of any information resulting from the use of this calculator. It is your responsibility to ensure that this information is true and correct"
          data-for={`infoBox-allergens-${entityId}`}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </span>
      {!entityHasAllergens ? (
        <p className="text-xs">No allergens</p>
      ) : (
        <>
          {entity.allergens && entity.allergens?.length > 0 ? (
            <div className="mb-4">
              <h4 className="text-xs">Contains</h4>
              <div className="flex flex-wrap">
                {entity.allergens?.map((allergen) => (
                  <span
                    key={allergen}
                    className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                  >
                    <img
                      className="w-8 p-1"
                      src={findValueIcon(allergens, allergen)}
                    />
                    {findValueLabel(allergens, allergen)}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
          {entityId.mayContainAllergens &&
          entityId.mayContainAllergens?.length > 0 ? (
            <div>
              <h4 className="text-xs">May contain</h4>
              <div className="flex flex-wrap">
                {entityId.mayContainAllergens?.map((allergen) => (
                  <span
                    key={allergen}
                    className="w-14 m-2 text-xs flex flex-col items-center capitalize text-center"
                  >
                    <img
                      className="w-8 p-1"
                      src={findValueIcon(allergens, allergen)}
                    />
                    {findValueLabel(allergens, allergen)}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

export default AllergenList
