import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import EditDishForm from "./EditDishForm"
import { getDish } from "services/dishes/dishes"
import { Dish } from "services/dishes/types"

export interface Props {
  dishId: string
  initialValues: Dish
  onSubmitCallback: (data: any) => {}
  onClose: () => void
}

export default ({
  dishId,
  initialValues,
  onSubmitCallback,
  onClose,
}: Props) => {
  const [dish, setDish] = useState<Dish | undefined>(undefined)

  const isAsideView = !!onClose

  const getDishData = (id) => {
    if (id) {
      getDish(id).then((res) => setDish({ ...initialValues, ...res }))
    } else {
      setDish({
        ...initialValues,
        sections: [{ name: "Main", elements: [] }],
        notes: "",
        method: "",
        status: "PUBLISHED",
      } as unknown as Dish)
    }
  }

  useEffect(() => {
    getDishData(dishId)
  }, [dishId])

  return (
    <>
      <Helmet>
        <title>Edit Dish</title>
      </Helmet>
      {isAsideView ? null : (
        <Header back title={dishId ? "Edit Dish" : "Add Dish"} />
      )}
      {dish && (
        <EditDishForm
          dish={dish}
          getData={getDishData}
          isAsideView={isAsideView}
          onSubmitCallback={onSubmitCallback}
          onClose={onClose}
        />
      )}
    </>
  )
}
