import React, { useState } from "react"
import { roundNumber } from "services/helpers"
import TextInput from "../../forms/TextInput"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { Dish } from "services/dishes/types"
import { Recipe } from "services/dishes/types"
import Switch from "../Switch/Switch"

interface SalesAndProfitProps {
  values: Dish | Recipe
}

const SalesAndProfit = ({ values }: SalesAndProfitProps) => {
  const [incTax, setIncTax] = useState(false)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const targetProfit = roundNumber(
    values.suggestedSalePrice ? values.suggestedSalePrice - values.totalCost : 0
  )
  const targetProfitIncTax = roundNumber(
    values.suggestedSalePriceWithTax
      ? values.suggestedSalePriceWithTax - values.totalCost
      : 0
  )
  const targetCostPercent = roundNumber(
    values.targetMarginPercent ? 100 - values.targetMarginPercent : 0
  )

  const styles = {
    sectionHeading: "mb-3 font-sansSemiBold font-semibold text-gray-800",
    formGroupWrapper: "w-full md:w-4/5 items-stretch",
    formGroup: "form-group w-full md:w-1/2 px-2 mb-4",
    formGroupNarrow:
      "form-group w-full md:w-1/5 px-2 mb-4 flex flex-col items-start",
    inputContainer: "input-container w-full",
    priceInput: "form-control form-control--first",
    taxInput: "form-control form-control--first form-control--last",
    costProfitChart:
      "input-container p-4 border border-solid border-gray-300 rounded-b",
    switchContainer: "mt-3",
  }

  return (
    <div className="flex items-stretch flex-wrap lg:px-6 pt-4">
      <div className={styles.formGroupNarrow}>
        <h3 className={styles.sectionHeading}>Tax</h3>
        <div className={styles.inputContainer}>
          <TextInput
            name="taxPercent"
            label="Tax %"
            showValidations={false}
            type="number"
            disabled={!permissionObj?.permissions.modify}
            placeholder="Tax %"
            className={styles.taxInput}
            min={0}
            max={100}
          />
        </div>
        <div className={styles.switchContainer}>
          <Switch
            on={incTax}
            setOn={setIncTax}
            label="Inc. Tax"
            buttonType="submit"
          />
        </div>
      </div>
      {/* NET */}
      <div
        className={`${styles.formGroupWrapper} ${!incTax ? "flex" : "hidden"}`}
      >
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Current <span className="text-darkGray40">net</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="salePrice"
              label="Sales Price"
              showValidations={false}
              type="number"
              disabled={!permissionObj?.permissions.modify}
              placeholder="Sales price"
              className={styles.priceInput}
            />
            <TextInput
              name="posId"
              label="POS ID"
              type="text"
              disabled={!permissionObj?.permissions.modify}
              placeholder="POS ID"
              className="form-control"
            />
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={values.profit}
                costPercentage={values.totalCostPercent}
                profitPercentage={values.profitPercent}
              />
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Projected <span className="text-darkGray40">net</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="suggestedSalePrice"
              label="Suggested Sales Price"
              type="number"
              disabled
              placeholder="Suggested Sales Price"
              className={styles.priceInput}
            />
            <TextInput
              name="targetMarginPercent"
              label="Target Margin %"
              type="number"
              disabled={!permissionObj?.permissions.modify}
              max="99.99"
              step="0.01"
              placeholder="Target Margin %"
              className="form-control"
            />
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={targetProfit}
                costPercentage={targetCostPercent}
                profitPercentage={values.targetMarginPercent}
              />
            </div>
          </div>
        </div>
      </div>
      {/* INC TAX */}
      <div
        className={`${styles.formGroupWrapper} ${incTax ? "flex" : "hidden"}`}
      >
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Current <span className="text-darkGray40">inc. tax</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="salePriceWithTax"
              label="Sales Price"
              showValidations={false}
              type="number"
              disabled={!permissionObj?.permissions.modify}
              placeholder="Sales price"
              className={styles.priceInput}
            />
            <TextInput
              name="posId"
              label="POS ID"
              type="text"
              disabled={!permissionObj?.permissions.modify}
              placeholder="POS ID"
              className="form-control"
            />
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={values.profitBasedOnSalePriceWithTax}
                costPercentage={values.totalCostPercentBasedOnSalePriceWithTax}
                profitPercentage={values.profitPercentBasedOnSalePriceWithTax}
              />
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Projected <span className="text-darkGray40">inc. tax</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="suggestedSalePriceWithTax"
              label="Suggested Sales Price"
              type="number"
              disabled
              placeholder="Suggested Sales Price"
              className={styles.priceInput}
            />
            <TextInput
              name="targetMarginPercent"
              label="Target Margin %"
              type="number"
              disabled={!permissionObj?.permissions.modify}
              max="99.99"
              step="0.01"
              placeholder="Target Margin %"
              className="form-control"
            />
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={targetProfitIncTax}
                costPercentage={targetCostPercent}
                profitPercentage={values.targetMarginPercent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesAndProfit
