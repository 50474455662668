import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineOptions,
  LineController,
  BarController,
} from "chart.js"
import { Line, Bar, Chart } from "react-chartjs-2"
import { ChartLegend } from "../DashboardPanel/DashboardPanel"
import { get } from "services/api"
import { ca } from "date-fns/locale"
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
)

interface DashboardChartsProps {
  orgId: string
  apiRoute: string
  dataSets: Array<object>
  chartType: "mixed" | "line" | "bar"
  chartOptions: any
}

const DashboardCharts = (props: DashboardChartsProps) => {
  const { orgId, apiRoute, dataSets, chartType, chartOptions } = props
  const [dates, setDates] = useState<string[]>([])
  const [dataSetData, setdataSetData] = useState<any[]>([])
  const [GraphLoading, setGraphLoading] = useState(true)

  const getDashboardChart = async () => {
    const response = await get("/" + apiRoute, {
      sort: "createdAt,desc",
      size: 99,
      organizations: [orgId],
    })

    await getHistory(response.content).finally(() => {
      // dataGraph.datasets.forEach((set: any) => {
      //   set.data = dataSetData[set.dataName] || []
      // })
    })
    // console.log(dataGraph.datasets)
  }

  const getHistory = async (data) => {
    const oneYearAgo = new Date().setFullYear(new Date().getFullYear() - 1)

    const tempDates: string[] = []
    const tempAllItems = [{}]
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    dataSets.map((item: any) => {
      tempAllItems[0][item.dataName] = []
    })

    data?.map((item: any) => {
      if (
        new Date(item.createdAt).getTime() > oneYearAgo
        // &&
        // item.productsCount != 0 &&
        // item.totalAmount != 0
      ) {
        const dateObject = new Date(item.createdAt)
        const weekday = days[dateObject.getDay()]

        // THIS FOR A CHECKER WHAT DATE YOU WANT TO USE
        // const day = dateObject.getDate()
        // const month = dateObject.getMonth() + 1 // Month is zero-based, so add 1
        // const year = dateObject.getFullYear()
        // const formattedDay = day < 10 ? `0${day}` : day
        // const formattedMonth = month < 10 ? `0${month}` : month
        // const formattedYear = year.toString().slice(-2) // Extract the last two digits

        // Have option in the request how it should be rendered
        const formattedDate = `${weekday}`
        // if(useDate === "dd-mm-yy") {
        //   const formattedDate =+ `${formattedDay}-${formattedMonth}-${formattedYear}`
        // }

        pushToAllKeys(tempAllItems[0], item)
        tempDates.push(formattedDate)
      }
    })
    tempAllItems.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].reverse()
      })
    })
    // console.log(tempAllItems)
    setdataSetData(tempAllItems)
    setDates(tempDates.reverse())
    // console.log(dataSetData)
    // console.log(tempAllItems)

    dataGraph.datasets.forEach((set: any) => {
      set.data = tempAllItems[0][set.dataName] || []
      setGraphLoading(false)
    })
  }

  // based on what type of chart it is
  const labels = dates.map((date) => date.slice(0, 5)) // Only show the day and month

  const dataGraph = {
    labels,
    datasets: dataSets,
  }

  // GET THE MOST MINIMAL OF A DATASET AND MOST MAX FROM A DATASET > MAKES 0 ON THE SAME LEVEL
  // const updatedChartOptions = Object.entries(chartOptions).reduce(
  //   (acc, [key, option]) => {
  //     // ...option,
  //     acc[key] = Object.fromEntries(
  //       Object.entries(option || {}).map(([scaleKey, scaleOptions]) => [
  //         scaleKey,
  //         {
  //           ...scaleOptions,
  //           min: Math.min(
  //             ...dataGraph.datasets.flatMap((set: any) => set.data)
  //           ),
  //           max: Math.max(
  //             ...dataGraph.datasets.flatMap((set: any) => set.data)
  //           ),
  //         },
  //       ])
  //     )

  //     return acc
  //   },
  //   {}
  // )
  // Even more detailed stepping, on all ticks
  // const updatedChartOptions = Object.entries(chartOptions).reduce(
  //   (acc, [key, option]) => {
  //     // Iterate through each scale (e.g., 'y', 'y2', etc.) in the chart options
  //     acc[key] = Object.fromEntries(
  //       Object.entries(option || {}).map(([scaleKey, scaleOptions]) => {
  //         const data = dataGraph.datasets.flatMap((set) => set.data)
  //         // If all data points are 0, return min and max as 0

  //         let someZero = false
  //         // CHECKS IF A DATASETS DATA HAS ONLY ZEROS
  //         someZero = dataGraph.datasets.some((set) =>
  //           set.data.every((item) => item === 0)
  //         )

  //         console.log(someZero)
  //         const suggestedMin = Math.min(...data)
  //         const suggestedMax = Math.max(...data)

  //         // Calculate the range (difference between max and min)
  //         const range = suggestedMax - suggestedMin

  //         // Calculate the step size (you can adjust `idealTickCount` for the number of ticks you want)
  //         const idealTickCount = 10
  //         const stepSize = range / (idealTickCount - 1)

  //         // Round the step size to a more readable value (optional)
  //         const roundedStepSize = Math.ceil(stepSize / 10) * 10 // Round to nearest 10 or 100

  //         // Returning updated scale options, including dynamically calculated min, max, and stepSize
  //         if (someZero) return [scaleKey, scaleOptions]
  //         return [
  //           scaleKey,
  //           {
  //             ...scaleOptions,
  //             suggestedMin, // Apply the calculated min based on data
  //             suggestedMax, // Apply the calculated max based on data
  //             // ticks: {
  //             //   ...scaleOptions.ticks,
  //             //   stepSize: roundedStepSize, // Dynamically calculated step size
  //             //   // callback: function (value) {
  //             //   //   return value // Optional: You can format the ticks here if needed
  //             //   // },
  //             // },
  //           },
  //         ]
  //       })
  //     )

  //     return acc
  //   },
  //   {}
  // )

  const options: LineOptions = {
    // @ts-ignore <maintainAspectRatio> property is allowed - based on the docs
    responsive: true,
    borderWidth: 1,
    barThickness: 20,
    minBarLength: 8,
    maintainAspectRatio: false, // Makes the Charts less height
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        bodyColor: "#4E4E4E",
        borderColor: "#E5E5E5",
        borderWidth: 1,
        displayColors: false,
        padding: 15,
        bodyFont: {
          size: 10,
          family: "averta_peregular",
        },
        callbacks: {
          title: () => null,
          label: (context: any) => {
            return [
              `Date: ${dates[context.dataIndex]}`,
              ...dataSets.map(
                (item: any) =>
                  `${item.label}: ${
                    dataSetData[0][item.dataName][context.dataIndex]
                  }`
              ),
            ]
          },
        },
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "point",
      intersect: false,
    },
    ...chartOptions, // change to updatedChartOptions
  }

  useEffect(() => {
    getDashboardChart()
  }, [])

  // could do in future https://www.chartjs.org/docs/latest/samples/advanced/progress-bar.html
  if (GraphLoading) return <div>Loading...</div>

  return (
    <div className="w-full">
      <div>
        {chartType === "line" ? (
          <Line options={options} data={dataGraph as any} />
        ) : chartType === "bar" ? (
          <Bar options={options} data={dataGraph as any} />
        ) : chartType === "mixed" ? (
          // <MixedChart options={options} data={dataGraph} />
          <Chart options={options} data={dataGraph as any} type="bar" />
        ) : null}
      </div>

      <div className="mt-4">
        <ChartLegend datasets={dataGraph.datasets} direction="horizontal" />
      </div>
    </div>
  )
}

export default DashboardCharts

// Recursive function to push values for all keys, including nested keys
function pushToAllKeys(obj, item) {
  // Loop through all keys in the object
  Object.keys(obj).forEach((key) => {
    // Check if the value is an array
    if (Array.isArray(obj[key])) {
      // Push item[key] if it exists, or 0 if it is null/undefined
      obj[key].push(
        item[key] !== undefined && item[key] !== null ? item[key] : 0 // if you want no value replace 0 with null
      )
    }
    // Check if the value is an object (not null)
    else if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively process nested objects, passing the value of item[key] if it exists, otherwise {}
      pushToAllKeys(obj[key], item?.[key] ?? {})
    }
  })
}
