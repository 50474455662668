import { get, post, put, remove } from "../api"
import { Event } from "./types"

type EventResponse = Promise<Event>

const url = "/events"
export const getEvents = (params) => get(url, params)

export const getEvent = (id): EventResponse => get(`${url}/${id}`)
export const createEvent = (event: Event) => post(url, event)
export const saveEvent = (id, event): EventResponse =>
  put(`${url}/${id}`, event)

export const updateEventFeaturedImage = (id, params): EventResponse => {
  return post(`${url}/${id}/featured-file`, params)
}

export const updateEventFiles = (id, params): EventResponse => {
  return post(`${url}/${id}/files`, params)
}

export const removeEvent = (id): EventResponse => remove(`${url}/${id}`)
