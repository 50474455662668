import React, { useState } from "react"
import TextInput from "../../forms/TextInput"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { Event } from "services/events/types"
import Switch from "../../common/Switch/Switch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import ReactTooltip from "react-tooltip"

interface SalesAndProfitEventProps {
  values: Event
}

const SalesAndProfitEvent = ({ values }: SalesAndProfitEventProps) => {
  const [incTax, setIncTax] = useState(false)
  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const summaryElements = {
    "Total cost": {
      net: values.totalCost || 0,
      gross: values.totalCost || 0,
    },
    "Sales price per cover": {
      net: values.cover?.revenue || 0,
      gross: values.cover?.revenueWithTax || 0,
      label: incTax ? "Inc. tax" : "Net",
    },
    "Cost per cover": {
      net: values.cover?.cost || 0,
      gross: values.cover?.cost || 0,
      info: "Cost of consumed",
    },
    "Consumed cost": {
      net: values.consumedCost || 0,
      gross: values.consumedCost || 0,
    },
  }

  const styles = {
    sectionHeading: "mb-3 font-sansSemiBold font-semibold text-gray-800",
    formGroupWrapper: "w-full md:w-4/5 items-stretch",
    formGroup: "form-group w-full md:w-1/2 px-2 mb-4",
    formGroupNarrow:
      "form-group w-full md:w-1/5 px-2 mb-4 flex flex-col items-start",
    inputContainer: "input-container w-full",
    priceInput: "form-control form-control--first",
    taxInput: "form-control form-control--first form-control--last",
    costProfitChart:
      "input-container p-4 border border-solid border-gray-300 rounded-b",
    switchContainer: "mt-3",
  }

  const summaryContent = (
    <ul className="border rounded-t border-gray-300 self-stretch -mb-px px-4 py-3">
      {Object.keys(summaryElements).map((name) => {
        const element = summaryElements[name]
        return (
          <li
            key={name}
            className="flex items-center justify-between space-y-1"
          >
            <span className="flex-1 text-primaryGray uppercase text-xs font-semibold font-sansSemiBold">
              {name}
              {element.label && (
                <span className="text-primaryBlueLighter text-opacity-50 ml-1">
                  {element.label}
                </span>
              )}
              {element.info && (
                <FontAwesomeIcon
                  data-for="summaryTotalItemTooltip"
                  data-tip={element.info}
                  icon={faInfoCircle}
                  className="text-primaryBlueLighter ml-1"
                />
              )}
            </span>
            <span className="text-sm text-gray-800 font-semibold font-sansSemiBold">
              {incTax ? element.gross : element.net}
            </span>
          </li>
        )
      })}
    </ul>
  )

  return (
    <div className="flex items-stretch flex-wrap lg:px-6 pt-4">
      <div className={styles.formGroupNarrow}>
        <h3 className={styles.sectionHeading}>Tax</h3>
        <div className={styles.inputContainer}>
          <TextInput
            name="taxPercent"
            label="Tax %"
            showValidations={false}
            type="number"
            value={values.taxPercent || ""}
            disabled={!permissionObj?.permissions.modify}
            placeholder="Tax %"
            className={styles.taxInput}
            min={0}
            max={100}
          />
        </div>
        <div className={styles.switchContainer}>
          <Switch on={incTax} setOn={setIncTax} label="Inc. Tax" />
        </div>
      </div>
      <ReactTooltip
        id="summaryTotalItemTooltip"
        type="light"
        place={"top"}
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
        multiline={true}
      />
      {/* NET */}
      <div
        className={`${styles.formGroupWrapper} ${!incTax ? "flex" : "hidden"}`}
      >
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Sales <span className="text-darkGray40">net</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="revenue"
              label="Revenue"
              showValidations={false}
              type="number"
              value={values.revenue || ""}
              disabled={!permissionObj?.permissions.modify}
              placeholder="Revenue"
              className={styles.priceInput}
            />
            <TextInput
              name="posId"
              label="POS ID"
              type="text"
              value={values.posId || ""}
              showValidations={false}
              disabled={!permissionObj?.permissions.modify}
              placeholder="POS ID"
              className="form-control form-control--last"
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Event totals <span className="text-darkGray40">net</span>
          </h3>

          <div className={styles.inputContainer}>
            {summaryContent}
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={values.profit}
                costPercentage={values.totalCostPercent}
                profitPercentage={values.profitPercent}
              />
            </div>
          </div>
        </div>
      </div>
      {/* INC TAX */}
      <div
        className={`${styles.formGroupWrapper} ${incTax ? "flex" : "hidden"}`}
      >
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Sales <span className="text-darkGray40">inc. tax</span>
          </h3>

          <div className={styles.inputContainer}>
            <TextInput
              name="revenueWithTax"
              value={values.revenueWithTax || ""}
              label="Revenue"
              showValidations={false}
              type="number"
              disabled={!permissionObj?.permissions.modify}
              placeholder="Revenue"
              className={styles.priceInput}
            />
            <TextInput
              name="posId"
              label="POS ID"
              value={values.posId || ""}
              type="text"
              showValidations={false}
              disabled={!permissionObj?.permissions.modify}
              placeholder="POS ID"
              className="form-control form-control--last"
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <h3 className={styles.sectionHeading}>
            Event totals <span className="text-darkGray40">inc. tax</span>
          </h3>

          <div className={styles.inputContainer}>
            {summaryContent}
            <div className={styles.costProfitChart}>
              <CostProfitChart
                cost={values.totalCost}
                profit={values.profitBasedOnRevenueWithTax}
                costPercentage={values.totalCostPercentBasedOnSalePriceWithTax}
                profitPercentage={values.profitPercentBasedOnRevenueWithTax}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesAndProfitEvent
