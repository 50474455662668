import { utcToZonedTime } from "date-fns-tz"
import React from "react"
import { convertToFormat } from "services/helpers"

const OrderItemDeliveryDate = ({ order, zoneId }) => {
  const formatDate = (date) => {
    if (!date) {
      return ""
    }
    return convertToFormat(date, "yyyy-MM-dd")
  }
  return (
    <div className="flex flex-col w-auto px-1 order-4">
      <>
        {order.expectedDeliveryDate
          ? formatDate(utcToZonedTime(order.expectedDeliveryDate, zoneId))
          : ""}
      </>
    </div>
  )
}

export default OrderItemDeliveryDate
