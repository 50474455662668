import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faShoppingCart, faCircle } from "@fortawesome/pro-duotone-svg-icons"
import {
  faChartLine,
  faList,
  faChartBar,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LineOptions, BarOptions, BarElement } from "chart.js"
import EmptyState from "components/common/EmptyState/EmptyState"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { format } from "date-fns"
import React, { useContext, useState } from "react"
import { Line, Bar } from "react-chartjs-2"
import {
  calculatePointRadius,
  defaultColors,
  defaultStatusColors,
  defaultsDatasetMapper,
  getOrganizationColor,
  orderStatusColors,
  resetSelected,
} from "services/chartHelpers"
import { getForDashboard } from "services/dashboard"
import { calculateStepSize, onlyUnique, roundNumber } from "services/helpers"
import { showError } from "services/toast"
import ChartTogglePanel from "../ChartTogglePanel/ChartTogglePanel"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js"
import { useMediaQuery } from "react-responsive"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { useWindowSize } from "usehooks-ts"
import IconToggle from "components/common/IconToggle/IconToggle"

ChartJS.register(
  // Registering locally addons used by chartJS (hover tooltip)
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler
)

interface Props {
  apiRoute: string
  chartType: "bar" | "line"
  mapLabelsWithoutData: boolean
  header: {
    title: string
    fontAwesomeIcon: IconProp
    iconColor: string
    iconBackgroundColor: string
  }
  selectedOption: string
  refreshObserver: string // Counter
  children: any
}

export const ChartLegend = ({
  datasets = [],
  direction = "horizontal",
}: {
  datasets: any[]
  direction: "vertical" | "horizontal"
}) => {
  const isVertical = direction === "vertical"

  return (
    <ul
      className={`flex overflow-auto ${
        isVertical ? "flex-col space-y-2" : "flex-row"
      }`}
    >
      {datasets.map((a: any) => {
        return (
          <li
            className="text-sm whitespace-nowrap md:text-base space-x-1 mr-3 mb-2"
            key={`Label ${a.label}`}
          >
            <FontAwesomeIcon
              icon={faCircle}
              className=""
              style={{ color: a.pointBackgroundColor }}
            />
            <span className="text-gray-600 font-medium select-none">
              {a.label}
            </span>
          </li>
        )
      })}
    </ul>
  )
}

const colors = defaultColors

function DashboardPanel({
  apiRoute = "delivery-notes",
  chartType = "bar",
  header = {
    title: "Custom chart",
    fontAwesomeIcon: faShoppingCart,
    iconColor: "#5555",
    iconBackgroundColor: "red",
  },
  mapLabelsWithoutData = true,
  selectedOption,
  refreshObserver,
}: Props) {
  //Read
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  //Context
  const { organizations, selectedOrganizations } =
    useContext(GlobalStateContext)

  const { width } = useWindowSize()
  // useEffects
  React.useEffect(() => {
    renderLabelChart()
  }, [width])

  //State
  const [labels, setLabels] = useState<any[] | []>([])
  const [datasets, setDatasets] = useState<[] | any[]>([]) // Individual data points
  const [selected, setselected] = useState<null | any>(null) //Currently selected element - clicked

  //Toggle
  const [sidePanelVisible, setsidePanelVisible] = useState(
    chartType === "bar" ? true : false
  ) // Default to side view for bar charts for now

  const handlerSetBarAsActive = function (e, chartElement) {
    //Use datasetIndex to determine which line should be highlighted
    const { datasetIndex, element } = chartElement[0]
    //Change ORDER propertry to adjust z-index
    if (element) {
      setDatasets((prev) => {
        const temp: any[] = [...prev]
        temp.forEach((e: any, index) => {
          e.fill = false
          if (index !== datasetIndex) {
            e.borderColor = "lightgray"
            e.order = index + 1
            e.pointRadius = 0
          } else {
            e.borderColor = e._borderColorOriginal
            temp[datasetIndex].fill = true
            temp[datasetIndex].order = 0
            e.pointRadius = calculatePointRadius
            setselected(temp[datasetIndex])
          }
        })
        return temp
      })
    }
  }

  const options: LineOptions = {
    // @ts-ignore <maintainAspectRatio> property is allowed - based on the docs
    maintainAspectRatio: false,
    // @ts-ignore <clip> property is allowed - based on the docs
    clip: false,
    spanGaps: true,
    responsive: true,
    tension: 0.3,
    pointRadius: calculatePointRadius,
    borderWidth: 1,
    onClick: handlerSetBarAsActive,
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        titleAlign: "center",
        bodyAlign: "center",
        bodyColor: "#4E4E4E",
        titleColor: "#4E4E4E",
        bodySpacing: 5,
        borderColor: "#E5E5E5",
        borderWidth: 1,
        displayColors: false,
        padding: 15,
        titleFont: {
          weight: "normal",
          size: 12,
        },
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        callbacks: {
          title: (context) => {
            return context[0] ? `${context[0].label}` : ``
          },
          label: function (context) {
            return `${context.dataset?.label}: ${roundNumber(context.raw, 2)}`
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          // Check highest value and round up to nearest 1000, 500, 250, 100, 50, 10, 1
          // This defines visible scale on the left side of the chart
          stepSize: () => calculateStepSize(datasets),
        },
        grid: {
          display: false,
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
  }

  const renderLabelChart = function () {
    //Chart references
    const sourceCanvas = barChartRef?.current?.canvas
    const targetCtx = barChartLabelCopyRef?.current?.getContext(
      "2d"
    ) as CanvasRenderingContext2D

    //Undefined check prevent
    if (!sourceCanvas || !targetCtx) return

    //Sizes
    const copyWidth =
      barChartRef.current.scales["y"].width + (isTabletOrMobile ? 10 : 0)
    const height = barChartRef.current.canvas.height

    //Render
    targetCtx.canvas.width = copyWidth
    targetCtx.canvas.height = height
    targetCtx.drawImage(
      sourceCanvas,
      0,
      0,
      copyWidth,
      height,
      0,
      0,
      copyWidth,
      height
    )
  }

  const barChartRef: any = React.useRef(null)
  const barChartLabelCopyRef: any = React.useRef(null)

  const barOptions: BarOptions = {
    // @ts-ignore <barThickness> property is allowed - based on the docs
    barThickness: 12,
    // @ts-ignore <clip> property is allowed - based on the docs
    maintainAspectRatio: false,
    clip: false,
    responsive: true,
    borderWidth: 1,
    minBarLength: 5,
    animation: {
      onComplete: renderLabelChart,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        titleAlign: "center",
        bodyAlign: "center",
        bodyColor: "#4E4E4E",
        titleColor: "#4E4E4E",
        bodySpacing: 5,
        borderColor: "#E5E5E5",
        borderWidth: 1,
        displayColors: false,
        padding: 15,
        titleFont: {
          weight: "normal",
          size: 12,
        },
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        callbacks: {
          title: (context) => {
            return context[0] ? `${context[0].label}` : ``
          },
          label: function (context) {
            return context.dataset?.label
          },
          afterLabel: (context) => {
            return `Total: ${context.formattedValue}`
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 7,
        },
      },
      y: {
        ticks: {
          // Check highest value and round up to nearest 1000, 500, 250, 100, 50, 10, 1
          // This defines visible scale on the left side of the chart
          stepSize: () => calculateStepSize(datasets),
        },
        grid: {
          display: false,
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
  }

  const dataConfig = {
    sales: {
      countByDaysField: "salesByDays",
      totalField: "totalSales",
    },
    default: {
      countByDaysField: "countByDays",
      totalField: "totalCost",
    },
  }

  //useEffect
  React.useEffect(() => {
    if (selectedOrganizations?.length === 0) {
      setLabels([])
      setDatasets([])
      return
    }

    getForDashboard({
      route: apiRoute,
      searchBy: selectedOption,
      organizations: selectedOrganizations,
    })?.then((response) => {
      if (!response) return

      const { orders, invoices, sales, deliveryNotes, status, message } =
        response
      const isGroupedData =
        Boolean(deliveryNotes) || (Boolean(orders) && chartType === "bar")
      const isError = status?.toString().startsWith("4")

      if (isError) {
        showError(message, { autoClose: false })
      }

      const data = orders || invoices || sales || deliveryNotes || [] // Fallback to empty array - ensure a data reset

      const dataType = sales ? "sales" : "default"
      const values = dataConfig[dataType]

      const labelKey: string = values["countByDaysField"]
      const valueKey: string = values["totalField"]

      if (
        data.length === 0 || // If no data, follow original flow
        (chartType === "line" && sidePanelVisible)
      ) {
        setsidePanelVisible(false) // Ensure side panel is closed after every data fetch
      } else if (chartType === "bar") {
        setsidePanelVisible(true) // By default, keep the side panel (non chart view) open
      }

      const labels = !data?.length
        ? []
        : isGroupedData
        ? [...data.map((a) => a.orgId)]
            .filter(onlyUnique)
            .map((a) => organizations.find((b) => b.value === a))
        : data[0]?.[labelKey]?.map((a) => format(new Date(a.date), "dd-MM")) //Take one of the data to use as scale

      setLabels(labels.map((a) => ({ value: a, _visible: true })))

      if (isGroupedData) {
        const targetLabels = deliveryNotes
          ? defaultStatusColors
          : orderStatusColors

        const target = [...targetLabels].map((currLabel) => {
          const parsedData = [...data].map((b) => b[currLabel.key])
          mapLabelsWithoutData &&
            parsedData.push(...new Array(labels.length - data.length).fill(-1))
          return {
            ...currLabel,
            data: parsedData,
            targetLabels,
          }
        })

        const dataset = target
          .map((a) => ({ ...a, grouped: true }))
          .map((curr, idx, arr) => defaultsDatasetMapper(curr, idx, arr, false))

        setDatasets(dataset)
      } else {
        const dataset = [
          ...data,
          ...selectedOrganizations
            .filter((v) => !data.find((a) => a.orgId === v))
            .map((a) => ({ orgId: a })),
        ]
          .map((a) => {
            return {
              borderColor: getOrganizationColor(colors, organizations, a.orgId),
              label:
                organizations.find((b) => b.value === a.orgId)?.label || "-",
              data:
                a[labelKey]
                  ?.map((b) => b[valueKey])
                  .map((a, idx, self) =>
                    a > 0 || idx === 0 || idx === self.length - 1 ? a : null
                  ) ?? [], // Setting datapoint to null to prevent it from being connected
              pointStyle: () => null,
              _backend: { ...a },
            }
          })
          .map((curr, idx, arr) => defaultsDatasetMapper(curr, idx, arr, true))

        setDatasets(dataset.filter((a) => a.data.length !== 0))
      }
    })
  }, [selectedOption, refreshObserver, selectedOrganizations])

  const percentage = selected?._backend?.percentage
  const rawLabels = labels
    .filter((a) => a._visible)
    .map((a) => (a.value?.label ? a.value.label : a.value))

  return (
    <div
      className="p-5 border rounded-md w-full"
      onMouseLeave={() => resetSelected(setDatasets, setselected)}
    >
      <div className="flex justify-between text-lg items-center w-full mb-6">
        <div className="flex space-x-2 items-center flex-shrink-0">
          <div
            className="w-12 h-12 flex items-center justify-center rounded-full text-white text-xl"
            style={{
              backgroundColor: header.iconBackgroundColor,
              color: header.iconColor,
            }}
          >
            <FontAwesomeIcon icon={header.fontAwesomeIcon} />
          </div>
          <h2>{header.title}</h2>
        </div>

        {/* If active line is selected */}
        <div className="space-x-4 flex">
          {selected !== null && (
            <div className="hidden xl:flex space-x-2 items-center">
              <div className="text-sm whitespace-nowrap flex">
                <span className="text-sm mr-1 truncate">
                  {selected.label || ""}:
                </span>{" "}
                <span className="text-sm text-primaryBlue font-semibold">
                  {selected._backend.totalCount}
                </span>
              </div>
              <span
                className={`font-semibold flex items-center text-sm ${
                  percentage === 0
                    ? "text-gray-500"
                    : percentage > 0
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {percentage !== 0 && (
                  <FontAwesomeIcon
                    className="mr-1"
                    icon={faArrowUp}
                    rotation={percentage < 0 ? 180 : undefined}
                  />
                )}
                <span>{Math.abs(roundNumber(percentage))}%</span>
              </span>
            </div>
          )}
          <IconToggle
            onChange={(val) => {
              setsidePanelVisible(!val)
            }}
            disabled={!labels.length}
            isBoolean={true}
            value={sidePanelVisible}
            icons={[
              {
                icon: chartType == "bar" ? faChartBar : faChartLine,
                value: false,
              },
              { icon: faList, value: true },
            ]}
          />
        </div>
      </div>

      <div className="w-full">
        {!labels.length ? (
          <div className="flex flex-col space-y-8 justify-center items-center h-full">
            <EmptyState
              entityTitle={header.title.toLocaleLowerCase()}
              chartType={chartType}
            />
          </div>
        ) : (
          <>
            {sidePanelVisible && (
              <ChartTogglePanel
                dataTypeName={header.title}
                data={datasets}
                setDatasets={setDatasets}
                labels={labels}
                setLabels={setLabels}
                renderType={chartType === "bar" ? "status" : "organizations"}
              />
            )}

            {!sidePanelVisible && (
              <div className="space-y-4">
                <div className="w-full">
                  {chartType === "line" && (
                    <Line
                      options={options}
                      data={{ labels: rawLabels, datasets }}
                    />
                  )}
                  {chartType === "bar" && (
                    <Bar
                      ref={barChartRef}
                      options={barOptions}
                      data={{ labels: rawLabels, datasets }}
                    />
                  )}
                </div>

                <ChartLegend datasets={datasets} direction="horizontal" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardPanel
