import React from "react"
import { formatCurrencyValue } from "services/helpers"
//@ts-ignore
import * as styles from "../EditElements.module.css"
import { RecipeOfEvent } from "services/events/types"
import RecipeIcon from "components/recipes/RecipeIcon/RecipeIcon"
export default ({
  element,
  change,
}: {
  element: RecipeOfEvent
  change: Function
}) => {
  const ingredient = element.recipe
  return (
    <div
      className="cursor-pointer flex items-center truncate overflow-ellipsis w-full pl-2"
      onClick={change()}
    >
      <RecipeIcon recipe={ingredient} />
      <div className="ml-2">
        <a className="font-sansSemiBold underline hover:no-underline font-semibold text-primaryBlue">
          {ingredient?.name}
        </a>
        <div className={styles.infoField}>
          {ingredient?.totalCost
            ? formatCurrencyValue(ingredient.totalCost)
            : "-"}
        </div>
      </div>
    </div>
  )
}
