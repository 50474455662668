import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import TabTitle from "./TabTitle"
import TabContent from "./TabContent"
import { navigate } from "gatsby"
import { useLocation } from "react-use"
import ReactTooltip from "react-tooltip"

interface TabsProps {
  children: any
  initialTab?: string
  connectSearchParams?: boolean
  connectSearchParamKey?: string
  onTabChange?(tabKey: string): void
  hideTabContent?: boolean
  hideTabs?: Array<string>
  [key: string]: any
}

const Tabs: React.FC<TabsProps> = forwardRef(
  (
    {
      initialTab,
      children,
      onTabChange,
      connectSearchParams = false,
      connectSearchParamKey = "activeTab",
      hideTabContent = false,
      hideTabs = [],
      ...props
    },
    ref
  ) => {
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState<string>(
      initialTab ? initialTab : children[0].props.tabKey
    )
    const tabRefs = React.useRef({})

    const filterNotAllowed = (setChildren) => {
      return (
        setChildren
          // Main isAllowed prop has preference over the roles filters
          .filter((item) =>
            item.props !== undefined && Object.hasOwn(item.props, "isAllowed")
              ? item.props.isAllowed
              : true
          )
          .filter((item) =>
            item.props !== undefined && hideTabs.includes(item.props.tabKey)
              ? false
              : true
          )
      )
    }

    const allowedChildren = filterNotAllowed(children)

    const handleTabChange = (tabKey: string) => {
      if (selectedTab !== tabKey) {
        if (onTabChange) {
          // Check if callback is defined
          onTabChange(tabKey)
        }

        // When the tabs are connected to the url this url change
        // will trigger a re-render that will correctly set the selectedTab on mount
        if (connectSearchParams) {
          const params = new URLSearchParams(window.location.search)
          params.set(connectSearchParamKey, tabKey) // Update the query param
          navigate(`${window.location.pathname}?${params.toString()}`, {
            replace: true,
          })
        } else {
          setSelectedTab(tabKey)
        }
      }
    }

    // Selected tab update helper
    const setTabFocus = (index) => {
      const tab = tabRefs.current[index]

      if (tab) {
        tab.focus()
      }
    }

    // onKeyDown handler for tab elements
    const onKeyDown = (event) => {
      const currentTabIndex = allowedChildren.findIndex(
        (c) => c.props.tabKey === selectedTab
      )
      const count = allowedChildren.length
      const nextTab = () => {
        const tabIndex = (currentTabIndex + 1) % count
        handleTabChange(allowedChildren[tabIndex].props.tabKey)
      }
      const prevTab = () => {
        const tabIndex = (currentTabIndex - 1 + count) % count
        handleTabChange(allowedChildren[tabIndex].props.tabKey)
      }
      const firstTab = () => handleTabChange(allowedChildren[0].props.tabKey)
      const lastTab = () =>
        handleTabChange(allowedChildren[count - 1].props.tabKey)

      const keyMap = {
        Tab: nextTab,
        ArrowRight: nextTab,
        ArrowLeft: prevTab,
        Home: firstTab,
        End: lastTab,
      }

      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }

    const showActiveTabs = () => {
      return allowedChildren
        .filter((c) => hideTabContent || c.props.tabKey === selectedTab)
        .map((c, i) => {
          const { ...rest } = c.props
          if (hideTabContent) {
            rest.className = rest?.className ?? ""
            rest.className += c.props.tabKey === selectedTab ? "" : " hidden"
          }
          return (
            <TabContent key={i} {...rest}>
              {c}
            </TabContent>
          )
        })
    }

    useEffect(() => {
      // Set a fallback by default on the first tab
      let tabOnMount = initialTab || children[0].props.tabKey
      // If connection with URL is set, try to get this param and overrule the initial tab
      if (connectSearchParams) {
        const params = new URLSearchParams(location.search)
        // Get the tab from the query param or fallback to previous value
        tabOnMount = params.get(connectSearchParamKey) || tabOnMount
      }
      setSelectedTab(tabOnMount)
    }, [])

    useEffect(() => {
      setTabFocus(
        allowedChildren.findIndex((c) => c.props.tabKey === selectedTab)
      )
    }, [selectedTab])

    // Ref handle to control the active tab from the outside
    useImperativeHandle(ref, () => ({
      changeActiveTab: (tab) => handleTabChange(tab),
    }))

    return (
      <>
        <div className={props.className ?? "growyze-tabs"} {...props}>
          <ul role="tablist" aria-orientation="horizontal">
            {allowedChildren.map((item, index) => (
              <TabTitle
                key={index}
                title={item.props.title}
                ref={(element) => (tabRefs.current[index] = element)}
                isActive={selectedTab === item.props.tabKey}
                tabKey={item.props.tabKey}
                handleTabChange={handleTabChange}
                onKeyDown={onKeyDown}
                showInfo={item.props.showInfo}
                showWarning={item.props.showWarning}
              />
            ))}
          </ul>
        </div>
        {showActiveTabs()}
        {/* Slot for tab tooltips used on TabTitle */}
        <ReactTooltip
          id={"tabs_tooltip"}
          type="light"
          place="top"
          effect="float"
          border={true}
          borderColor="#e2e8f0"
        />
      </>
    )
  }
)

export default Tabs
