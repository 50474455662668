import React, { useState, useEffect } from "react"
import { usePrevious, useUpdateEffect } from "react-use"

import { faPlus, faTimes, faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRecipes } from "services/recipes/recipes"
import { formatCurrencyValue } from "services/helpers"
import BaseTable, { AutoResizer } from "react-base-table"
import SearchInput from "components/forms/SearchInput"
import { recipeMeasures } from "services/constants"
import { SelectedItem } from "../NewProductSelect/types"
import RecipeIcon from "components/recipes/RecipeIcon/RecipeIcon"

import * as styles from "./RecipeSelect.module.css"

const info = (r) =>
  [r.category || " - "]
    .concat(
      r.yield
        ? [
            `${r.yield.size} ${
              recipeMeasures.find((ms) => ms.value === r.yield.measure)?.label
            }`,
          ]
        : []
    )
    .concat(
      r.portionCount
        ? [`${r.portionCount} ${r.portionCount === 1 ? "ptn" : "ptns"}`]
        : []
    )
    .join(" / ") || "-"

interface RecipeSelectProps {
  onSelect: (recipe: any) => void
  createNewRecipe?: ((data: any) => void) | null
  multiSelect?: boolean
  onClose?: () => void
  highlightPosId?: boolean
  disabledSelectWithoutPortion?: boolean
}

const RecipeSelect = ({
  onSelect,
  createNewRecipe,
  multiSelect,
  onClose,
  highlightPosId,
  disabledSelectWithoutPortion = false,
}: RecipeSelectProps) => {
  const [query, setQuery] = useState("")
  const [recipes, setRecipes] = useState([])
  const previousQ = usePrevious(query)
  const [loading, setLoading] = useState(false)

  const [selectedRecipes, setSelectedRecipes] = useState<Array<SelectedItem>>(
    []
  )

  const resetState = () => {
    setSelectedRecipes([])
  }

  const getData = async () => {
    setLoading(true)
    const params = {
      sort: "createdDate,desc",
      partialRecipeName: query,
    }

    const response = await getRecipes(params)
    setRecipes(response.content)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useUpdateEffect(() => {
    if (query || previousQ) {
      getData()
    }
  }, [query])

  const columns = [
    {
      key: "description",
      title: "",
      dataKey: "description",
      width: 180,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <>
            <RecipeIcon onClick={() => onSelect(rowData)} recipe={rowData} />
            <div
              className="flex flex-col p-3"
              onClick={() => onSelect(rowData)}
            >
              <div className="text-md text-primaryBlue ">
                <span className="font-sansBold">{rowData.name}</span>{" "}
                {highlightPosId && rowData.posId && (
                  <span className="text-gray-600 font-light">
                    {`(Current POS ID: ${rowData.posId})`}
                  </span>
                )}
              </div>
              <div className="text-md truncate text-ellipsis">
                {info(rowData)}
              </div>
            </div>
          </>
        )
      },
    },
    {
      key: "cost",
      title: "",
      dataKey: "cost",
      width: 80,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div>
            <div className="text-sm text-gray-700">Cost</div>
            <div className="text-md">
              {formatCurrencyValue(rowData.totalCost)}
            </div>
          </div>
        )
      },
    },
    {
      key: "notes",
      title: "",
      dataKey: "notes",
      width: 200,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return rowData.portion != null || !disabledSelectWithoutPortion ? (
          ""
        ) : (
          <div className="text-red-700 text-sm">
            Item missing portions. Go to{" "}
            <a
              className="underline"
              target="_blank"
              href="/dashboard/products/cookbook/recipes"
            >
              Products → Recipes
            </a>{" "}
            to complete the set up
          </div>
        )
      },
    },
    {
      key: "action",
      dataKey: "action",
      width: 60,
      flexGrow: 0,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const isSelected = selectedRecipes?.find(
          (recipe) => recipe.id === rowData.id
        )
        return (
          <div>
            <div className="absolute right-5 top-5">
              {!isSelected ? (
                <button
                  className={styles.qtyButton}
                  disabled={
                    disabledSelectWithoutPortion && rowData.portion === null
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    if (!multiSelect) {
                      onSelect(rowData)
                    } else {
                      const latestRecipes = [...selectedRecipes]
                      latestRecipes.push(rowData)
                      setSelectedRecipes(latestRecipes)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              ) : (
                <button
                  type="button"
                  className="button whitespace-nowrap button--smaller button--autoWidth button--primaryBlueLightweight mr-2"
                  onClick={(e) => {
                    e.preventDefault()
                    const latestRecipes = [...selectedRecipes]
                    setSelectedRecipes(
                      latestRecipes.filter((p) => p.id !== rowData.id)
                    )
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  Selected
                </button>
              )}
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          label="Name"
          placeholder="Recipe name"
          className="form-control rounded"
          onSearchChange={(val) => {
            setQuery(val)
          }}
        />
        {onClose && (
          <button
            type="button"
            onClick={() => {
              onClose()
            }}
            className="text-xl px-3 ml-3"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
      <div className={styles.content}>
        <div className="w-full flex-grow">
          <AutoResizer height={Infinity}>
            {({ width, height }) => (
              <BaseTable
                fixed={false}
                estimatedRowHeight={80}
                width={width}
                ignoreFunctionInColumnCompare={false}
                maxHeight={recipes?.length === 0 ? undefined : height}
                height={recipes?.length === 0 ? 200 : height}
                headerHeight={0}
                data={recipes}
                columns={columns}
                emptyRenderer={
                  <div className={styles.noProducts}>
                    <p className="mb-4">
                      {loading ? "Loading..." : "No recipes found"}
                    </p>
                  </div>
                }
              />
            )}
          </AutoResizer>
        </div>
      </div>

      {(createNewRecipe || multiSelect) && (
        <footer className={styles.footer}>
          <div
            className={
              "flex px-4 lg:px-6 py-3 lg:py-4 w-full items-center justify-end"
            }
          >
            {multiSelect && (
              <div className="flex items-start flex-grow">
                <span className="font-semibold mr-4 text-left flex flex-grow">
                  <span className="text-primaryBlue mr-2">
                    {selectedRecipes.length}{" "}
                    {selectedRecipes.length === 1 ? "recipe" : "recipes"}
                  </span>
                  <span className="text-gray-600">selected</span>
                </span>
              </div>
            )}
            {createNewRecipe && (
              <button
                onClick={createNewRecipe}
                className={"button button--autoWidth button--primaryGreen"}
              >
                <FontAwesomeIcon icon={faPlus} className={styles.buttonIcon} />
                <span className={styles.buttonText}>Add recipe</span>
              </button>
            )}
            {multiSelect && (
              <button
                type="button"
                className="button button--autoWidth button--primaryGreen"
                onClick={() => {
                  onSelect(selectedRecipes)
                  resetState()
                }}
                disabled={selectedRecipes.length < 1}
              >
                <FontAwesomeIcon icon={faCheck} className={styles.buttonIcon} />
                <span className={styles.buttonText}>Confirm</span>
              </button>
            )}
          </div>
        </footer>
      )}
    </div>
  )
}

export default RecipeSelect
