import React, { useContext } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"

import { convertTimeStampFormat, formatAsYearMonthDay } from "services/helpers"
import { format } from "date-fns"
import { GlobalStateContext } from "context/global/GlobalContextProvider"

interface TimeLabelProps {
  date: Date | undefined
  showIcon?: boolean
  showTime?: boolean
  showInTimeZone?: boolean
}

const TimeLabel = ({
  date,
  showIcon = true,
  showTime = false,
  showInTimeZone = false,
}: TimeLabelProps) => {
  const { organization }: { organization: any } = useContext(GlobalStateContext)
  return (
    <>
      {date && (
        <span className="text-xs text-gray-700 font-light">
          {showIcon && <FontAwesomeIcon icon={faCalendar} size="sm" />}{" "}
          {showInTimeZone ? (
            <>{convertTimeStampFormat(date, organization.address.zoneId)}</>
          ) : (
            <>
              {date ? formatAsYearMonthDay(new Date(date)) : ""}
              {showTime ? ` ${format(new Date(date), "HH:mm")}` : ""}
            </>
          )}
        </span>
      )}
    </>
  )
}
export default TimeLabel
