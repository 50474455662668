import React from "react"
import BaseTable, { AutoResizer } from "react-base-table"
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import { formatCurrencyValue } from "services/helpers"
import { useMediaQuery } from "usehooks-ts"
import * as styles from "./EventItem.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"
import {
  Event,
  SectionElementOfEvent,
  SectionOfEvent,
} from "services/events/types"

interface EventItemContentProps {
  event: Event
}

const EventItemContent = ({ event }: EventItemContentProps) => {
  const mappedData = event.sections
    ? event.sections.map((s: SectionOfEvent, index) => {
        const parentId = `${s.name}-${index}`
        const children = s.elements
          ? s.elements.map((e: SectionElementOfEvent, index) => {
              const childId = `${parentId}-${index}`
              return {
                id: childId,
                parentId,
                type: e.type,
                ingredient: e.ingredient,
                ...{
                  OTHER_INGREDIENT: {
                    name: e.otherIngredient?.name,
                    size: e.otherIngredient?.size,
                    measure: e.otherIngredient?.measure,
                    wasteQty: null,
                    initialQty: 1,
                    addedQty: 1,
                    cost: e.otherIngredient?.cost,
                  },
                  INGREDIENT: {
                    name: e.ingredient?.product?.name,
                    size: e.ingredient?.size,
                    measure: e.ingredient?.measure,
                    wasteQty: e.ingredient?.wasteQty,
                    initialQty: e.ingredient?.initialQty,
                    addedQty: e.ingredient?.addedQty,
                    cost: e.ingredient?.cost,
                  },
                  RECIPE: {
                    name: e.recipe?.recipe?.name,
                    usedQty: e.recipe?.size,
                    measure: e.recipe?.measure,
                    wasteQty: e.recipe?.wasteQty,
                    initialQty: e.recipe?.initialQty,
                    addedQty: e.recipe?.addedQty,
                    cost: e.recipe?.cost,
                  },
                  DISH: {
                    name: e.dish?.dish?.name,
                    size: e.dish?.size,
                    measure: e.dish?.measure,
                    wasteQty: e.dish?.wasteQty,
                    initialQty: e.dish?.initialQty,
                    addedQty: e.dish?.addedQty,
                    cost: e.dish?.cost,
                  },
                  // @ts-ignore
                }[e.type],
              }
            })
          : []
        return {
          name: s.name,
          id: parentId,
          size: `${s.elements ? s.elements.length : 0} item${
            s.elements && s.elements.length > 1 ? "s" : ""
          }`,
          addedQty: children.reduce((acc, elem) => acc + elem.addedQty, 0),
          initialQty: children.reduce((acc, elem) => acc + elem.initialQty, 0),
          cost: s.totalCost,
          children: children,
        }
      })
    : []

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex items-center">
          {rowData.parentId && rowData.ingredient?.product.isDeleted && (
            <>
              <span
                data-for="deleted_product_tooltip"
                data-tip="This ingredient is deleted."
              >
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="text-error mr-2"
                />
              </span>

              <ReactTooltip
                id={"deleted_product_tooltip"}
                type="light"
                place="right"
                effect="float"
                border={true}
                borderColor="#e2e8f0"
              />
            </>
          )}

          <div className="flex flex-col my-2 pl-0">
            <span
              className={`text-sm  ${rowData.name ? "" : "italic"} ${
                rowData.children
                  ? "font-sansSemiBold font-semibold text-primaryBlue"
                  : "font-sans font-normal text-gray-800"
              }`}
            >
              {rowData.name ?? "Untitled section"}
            </span>
          </div>
        </div>
      ),
    },
    {
      key: "size",
      width: 80,
      title: "Qty",
      dataKey: "size",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {rowData.size} {rowData.measure}
        </span>
      ),
    },
    {
      key: "initialQty",
      width: 80,
      title: "Initial qty",
      dataKey: "initialQty",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {rowData.initialQty}
        </span>
      ),
    },
    {
      key: "addedQty",
      width: 80,
      title: "Added",
      dataKey: "addedQty",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {rowData.addedQty}
        </span>
      ),
    },
    {
      key: "cost",
      width: 80,
      title: "Cost",
      dataKey: "cost",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <span
          className={`text-sm ${
            rowData.children
              ? "font-sansSemiBold font-semibold text-primaryBlue"
              : "font-sans font-normal text-gray-800"
          }`}
        >
          {formatCurrencyValue(rowData.cost)}
        </span>
      ),
    },
  ]

  const isTabletOrMobile = useMediaQuery("{ maxWidth: 1023 }")

  return (
    <AutoResizer height={Infinity}>
      {({ width, height }) => (
        <BaseTable
          fixed={isTabletOrMobile ? true : false}
          style={{ backgroundColor: "transparent" }}
          width={width}
          maxHeight={mappedData.length === 0 ? undefined : Infinity}
          height={mappedData.length === 0 ? 80 : height}
          components={{
            ExpandIcon: (props) => {
              const { expandable, expanded, onExpand } = props

              if (!expandable) {
                return null
              }

              return (
                <FontAwesomeIcon
                  className="cursor-pointer text-primaryBlueLighter mr-4"
                  size="sm"
                  icon={expanded ? faChevronUp : faChevronDown}
                  onClick={() => {
                    onExpand(!expanded)
                  }}
                />
              )
            },
          }}
          ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
          expandColumnKey="name"
          data={mappedData}
          columns={columns}
          emptyRenderer={<div className={styles.noItems}>No items found</div>}
        />
      )}
    </AutoResizer>
  )
}

export default EventItemContent
