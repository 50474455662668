import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { format } from "date-fns"
import React, { useContext, useEffect, useState } from "react"
import { usePromise } from "react-use"
import { get } from "services/api"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import { removeTimezone, removeTimezoneEndDate } from "services/helpers"
import { getOrgNameById } from "services/user"
import DashboardCharts from "../Dashboard/DashboardCharts"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { firstCogsReportsPerOrg } from "services/stock-take/stock-take"

interface DashboardItemProps {
  itemType?: "card" | "list"
  apiRoute?: string
  chartType: "mixed" | "line" | "bar"
  header: {
    title: string
    fontAwesomeIcon: IconProp
    iconColor: string
    iconBackgroundColor: string
  }
  selectedOption: any
  refreshObserver: string
  items: {
    title: string
    value: string
    borderColor: string
  }
  datasets: Array<any>
  chartOptions: any
  cta: {
    label: string
    ctaBackgroundColor: string
    fontAwesomeIcon: IconProp
    iconColor: string
    textColor: string
    link: string
    closeIcon: IconProp
  }
}

const DashboardItem = (props: DashboardItemProps) => {
  const {
    itemType = "card",
    header,
    selectedOption,
    apiRoute,
    refreshObserver,
    datasets,
    items,
    chartType,
    chartOptions,
    cta,
  } = props
  const { selectedOrganizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [dashItemData, setdashItemData] = useState([] as Array<any>)

  useEffect(() => {
    const getData = async () => {
      if (
        organizationPermissions?.general?.isMain && // Comment/Disable to show all orgs
        selectedOrganizations?.length === 0
      ) {
        setdashItemData([])
        return
      }

      setLoading(true)

      const params = {
        sort: "createdAt,desc",
        ...(selectedOption && {
          fromDate: selectedOption?.from
            ? removeTimezone(selectedOption.from)
            : null,
          toDate: selectedOption?.to
            ? removeTimezoneEndDate(selectedOption.to)
            : null,
        }),
        // Uncomment/Enable this instead of below, if you want to show all orgs
        // ...{ organizations: selectedOrganizations },  // Part to show all orgs
        ...(organizationPermissions?.general?.isMain && {
          organizations: selectedOrganizations,
        }),
        // emd of this
      }

      const result = await fromPromise(get("/" + apiRoute, params))

      if (result && !result.error) {
        setdashItemData(
          firstCogsReportsPerOrg(result.content, selectedOrganizations)
        )
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    getData()
  }, [selectedOrganizations, selectedOption, refreshObserver])

  const DashboardItems = loading ? (
    <div className="w-full">
      <div className="w-full my-4 space-y-6 mb-12">
        <div className="border rounded-md divide-y">
          <div className="p-4 flex justify-between md:items-center flex-wrap gap-x-4 gap-y-4">
            <div className="animate-pulse flex space-x-4">
              <div
                className="rounded-full w-12 h-12 flex-1 flex items-center justify-center text-xl"
                style={{
                  backgroundColor: header.iconBackgroundColor,
                  color: header.iconColor,
                }}
              >
                <FontAwesomeIcon icon={header.fontAwesomeIcon} />
              </div>
            </div>

            <div className="space-y-2">
              <p className="text-base font-semibold">loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : itemType === "card" ? (
    <div className="w-full">
      {header.title}
      {dashItemData.map((item) => (
        <DashboardItemToggle
          item={item}
          header={header}
          key={item.id}
          apiRequest={apiRoute}
          datasets={datasets}
          items={items}
          chartType={chartType}
          chartOptions={chartOptions}
          ctaButton={cta}
        />
      ))}
    </div>
  ) : (
    <div className="w-full my-4 space-y-6 mb-12">
      <h2 className="font-bold font-sansBold text-lg">{header.title}</h2>

      <div className="border rounded-md divide-y">
        {dashItemData.map((item) => (
          <DashboardItemToggle
            item={item}
            header={header}
            key={item.id}
            apiRequest={apiRoute}
            datasets={datasets}
            items={items}
            chartType={chartType}
            chartOptions={chartOptions}
            ctaButton={cta}
          />
        ))}
      </div>
    </div>
  )

  return <div>{DashboardItems}</div>
}
const DashboardItemToggle = (props: any) => {
  const [ItemToggle, setItemToggle] = useState(false)
  const {
    item,
    header,
    apiRequest,
    datasets,
    items,
    chartType,
    chartOptions,
    ctaButton,
  } = props
  return (
    <div className="p-4 flex justify-between md:items-center flex-wrap gap-x-4 gap-y-4">
      {!ItemToggle ? (
        <DashboardItemListDisplay
          item={item}
          header={header}
          toggle={setItemToggle}
          items={items}
          ctaButton={ctaButton}
        />
      ) : (
        <DashboardItemListChart
          item={item}
          request={apiRequest}
          header={header}
          toggle={setItemToggle}
          datasets={datasets}
          chartType={chartType}
          chartOptions={chartOptions}
          ctaButton={ctaButton}
        />
      )}
    </div>
  )
}

const DashboardItemListDisplay = (props: any) => {
  const { item, toggle, header, items, ctaButton } = props
  const { organizations } = useContext(GlobalStateContext)
  return (
    <>
      <div className="flex items-center space-x-4 w-full sm:w-1/2 md:w-1/5 grow break-all">
        <div className="mr-2">
          <div
            className="rounded-full w-12 h-12 flex items-center justify-center text-xl shrink-0"
            style={{
              backgroundColor: header.iconBackgroundColor,
              color: header.iconColor,
            }}
          >
            <FontAwesomeIcon icon={header.fontAwesomeIcon} />
          </div>
        </div>

        <div>
          <p className="text-base font-semibold">
            {getOrgNameById(item.organizations[0], organizations)}
          </p>
          <p className="text-xs text-gray-600">
            {format(new Date(item.periodFrom), "dd-MM-yyyy")} -{" "}
            {format(new Date(item.periodTo), "dd-MM-yyyy")}
          </p>
        </div>
      </div>
      <div className="flex flex-1 md:justify-center space-x-4 md:space-x-16 text-xs w-full md:w-2/5">
        {items.map((i, index) => (
          <DashboardItemBorderValue
            key={index}
            title={i.title}
            value={item[i.value] || 0}
            bordercolor={i.borderColor}
          />
        ))}
      </div>
      {ctaButton && (
        <button
          onClick={toggle}
          className="button--autoWidth border font-sansSemiBold font-semibold px-3 py-2.5 hover:border-gray-300 rounded-md hover:shadow-sm space-x-2 w-full sm:w-1/2 md:w-1/5"
          style={{
            backgroundColor: ctaButton.ctaBackgroundColor
              ? ctaButton.ctaBackgroundColor
              : undefined,
            color: ctaButton.textColor ? ctaButton.textColor : undefined,
          }}
        >
          {ctaButton.fontAwesomeIcon && ctaButton.iconColor ? (
            <FontAwesomeIcon
              icon={ctaButton.fontAwesomeIcon}
              style={{ color: ctaButton.iconColor }}
            />
          ) : ctaButton.fontAwesomeIcon ? (
            <FontAwesomeIcon icon={ctaButton.fontAwesomeIcon} />
          ) : ctaButton.iconColor ? (
            <span
              className="font-normal"
              style={{ color: ctaButton.iconColor }}
            >
              &nbsp;
            </span>
          ) : (
            <></>
          )}
          <span>{ctaButton.label}</span>
        </button>
      )}
    </>
  )
}

const DashboardItemListChart = (props: any) => {
  const {
    item,
    toggle,
    header,
    request,
    datasets,
    chartType,
    chartOptions,
    ctaButton,
  } = props
  const { organizations } = useContext(GlobalStateContext)
  return (
    <div className="justify-between items-center pb-4 w-full">
      <div className="flex justify-between items-center pb-4 w-full">
        <div className="flex items-center space-x-2">
          <div className="flex items-center mr-2">
            <div
              className="rounded-full bg-primaryGreenVeryLight w-12 h-12 flex items-center justify-center text-white text-xl"
              style={{
                backgroundColor: header.iconBackgroundColor,
                color: header.iconColor,
              }}
            >
              <FontAwesomeIcon icon={header.fontAwesomeIcon} />
            </div>
          </div>
          <h1>{getOrgNameById(item.organizations[0], organizations)}</h1>
        </div>
        {ctaButton && (
          <button
            onClick={() => toggle(false)}
            className="button border shadow-none button--autoWidth no-truncate"
            style={{
              backgroundColor: ctaButton.ctaBackgroundColor
                ? ctaButton.ctaBackgroundColor
                : undefined,
              color: ctaButton.textColor ? ctaButton.textColor : undefined,
            }}
          >
            {ctaButton.iconColor ? (
              <FontAwesomeIcon
                icon={ctaButton.closeIcon || faPlus}
                style={{ color: ctaButton.iconColor }}
                transform={ctaButton.closeIcon ? undefined : { rotate: 45 }}
              />
            ) : ctaButton.closeIcon ? (
              <FontAwesomeIcon icon={ctaButton.closeIcon} />
            ) : (
              <FontAwesomeIcon icon={faPlus} transform={{ rotate: 45 }} />
            )}
          </button>
        )}
      </div>
      <DashboardCharts
        orgId={item.organizations[0]}
        apiRoute={request}
        dataSets={datasets}
        chartType={chartType}
        chartOptions={chartOptions}
      />
    </div>
  )
}

const DashboardItemBorderValue = (props: any) => {
  const {
    title,
    bordercolor,
    textcolor = "gray-600",
    value,
    itemStatus,
  } = props
  if (itemStatus) {
    return (
      <div className="flex w-full sm:w-1/2 md:w-1/5 m-0 items-center">
        <ItemStatus
          status={itemStatus.find(
            (x: any) => x.value
            // x.value === itemStatusData[orgId]?.stockTakeReport.status
          )}
        />
      </div>
    )
  }
  return (
    <div
      className={`space-y-2 ${
        bordercolor ? `border-l-4 border-${bordercolor}` : ""
      } pl-2`}
    >
      <p className={`text-${textcolor} uppercase`}>{title}</p>
      <p className="font-semibold">{value}</p>
    </div>
  )
}

export default DashboardItem
