/* eslint-disable */
import React, { useState, useEffect, useContext } from "react"
import { usePrevious } from "react-use"
import { navigate } from "@reach/router"
import { apiResponseIsError, roundNumber, slugify } from "services/helpers"
import {
  faTrashAlt,
  faFileDownload,
  faEllipsisH,
  faSync,
} from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Header from "components/dashboard/Header/Header"
import moment from "moment"

import BaseTable, { AutoResizer } from "react-base-table"
import SearchInput from "components/forms/SearchInput"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import { AsideContext } from "context/aside/AsideContext"
import { ModalContext } from "context/modal/ModalContext"
import ProductSelectModal from "components/dishes/EditElements/modals/ProductSelectModal"

import {
  getSale,
  removeSale,
  getSalesProducts,
  exportSales,
  refreshOnNonMatchingPosIds,
} from "services/sales"
import Helmet from "react-helmet"
//@ts-ignore
import * as styles from "./SaleDetail.module.css"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { showError, showSuccess } from "services/toast"
import { updateProduct } from "services/products/products"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import RecipeSelectModal from "components/dishes/EditElements/modals/RecipeSelectModal"
import { updateRecipe } from "services/recipes/recipes"
import { saveDish } from "services/dishes/dishes"
import DishSelectModal from "components/dishes/EditElements/modals/DishSelectModal"
import EditRecipe from "components/recipes/EditRecipe/EditRecipe"
import EditDish from "components/dishes/EditDish/EditDish"
import { getOrgNameById } from "services/user"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { getExportFileName } from "services/export"

const SaleDetail = ({ saleId }) => {
  const [salesData, setSalesData] = useState({})
  const { organization, organizations } = useContext(GlobalStateContext)

  const [productName, setProductName] = useState("")
  const previousProductName = usePrevious(productName)

  const [posId, setPosId] = useState("")
  const previousPosId = usePrevious(posId)

  const [notFound, setNotFound] = useState(false)

  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const permissionObj = usePermissions("Sales") as Permission

  const isGoEpos = salesData?.salesOrigin === "GOEPOS"

  const isRefund = (rowData) => {
    const { soldQty, totalValue } = rowData
    return soldQty < 0 || totalValue < 0
  }
  const getData = async () => {
    const saleData = await getSale(saleId)

    if (saleData.status === 404) {
      setNotFound(true)
    } else {
      setSalesData(saleData)
    }
  }

  const getProducts = async () => {
    let params = {}

    if (productName) {
      params.partialProductName = productName
    }

    if (posId) {
      params.partialPosId = posId
    }

    const products = await getSalesProducts(saleId, params)

    if (products.status === 404) {
      setNotFound(true)
    } else {
      setSalesData({ ...salesData, items: products })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousProductName || productName) {
      getProducts()
    }
  }, [productName])

  useEffect(() => {
    if (previousPosId || posId) {
      getProducts()
    }
  }, [posId])

  const columns = [
    {
      key: "posId",
      title: "POS ID",
      dataKey: "posId",
      width: 300,
      flexGrow: 1,
      flexShrink: 0,
      salesData, //Prevents state from becoming stale in column
      cellRenderer: ({ rowData }) => (
        <div className="flex justify-between items-center w-full">
          {rowData.autoGeneratedPosId && !rowData.nonMatchingPosId ? (
            <span className="text-sm">Missing POS ID</span>
          ) : (
            <span className="text-sm">{rowData.posId}</span>
          )}
        </div>
      ),
    },
    {
      key: "nonMatchingPosId",
      title: "Non matching POS IDs",
      dataKey: "nonMatchingPosId",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      align: "center",
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          {rowData.nonMatchingPosId && (
            <div className="flex items-center">
              <DropdownButton
                onActionClick={(action) => {
                  handleMatchPosIDActionClick(action, rowData)
                }}
                options={[
                  {
                    title: "Add item",
                    key: "add.product",
                    disabled: !permissionObj?.permissions.modify,
                  },
                  {
                    title: "Add recipe",
                    key: "add.recipe",
                    disabled: !permissionObj?.permissions.modify,
                  },
                  {
                    title: "Add dish",
                    key: "add.dish",
                    disabled: !permissionObj?.permissions.modify,
                  },
                ]}
                buttonClass={styles.addButton}
                icon={faPlus}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 400,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">{rowData.name}</div>
      ),
    },
    {
      key: "soldQty",
      title: "Qty sold",
      dataKey: "soldQty",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          {isGoEpos && isRefund(rowData) && "- "}
          {roundNumber(rowData.soldQty)}
        </div>
      ),
    },
    {
      key: "totalValue",
      title: "Net Sales",
      dataKey: "totalValue",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          {isGoEpos && isRefund(rowData) && "- "}
          {rowData.totalValue}
        </div>
      ),
    },
  ]

  const dropdownOptions = [
    {
      title: "Export to .CSV",
      key: "export.csv",
      disabled: !permissionObj?.permissions.read,
    },
    {
      title: "Export to .XLSX",
      key: "export.xlsx",
      disabled: !permissionObj?.permissions.read,
    },
  ]

  const handleActionClick = ({ type }) => {
    switch (type) {
      case "export.csv":
        exportSales(saleId, "csv", getExportFileName("sales", "", "csv"))
        break

      case "export.xlsx":
        exportSales(saleId, "xlsx", getExportFileName("sales", "", "xlsx"))
        break
      default:
        break
    }
  }
  const handleRefreshClick = async () => {
    const newSaleData = await refreshOnNonMatchingPosIds(salesData.id)
    if (newSaleData.status === 404) {
      setNotFound(true)
    } else {
      setSalesData(newSaleData)
    }
  }

  const handleMatchPosIDActionClick = ({ type }, data) => {
    switch (type) {
      case "add.product":
        showProductModal(data)
        break
      case "add.recipe":
        showRecipeModal(data)
        break
      case "add.dish":
        showDishModal(data)
      default:
        break
    }
  }

  const onAddNewProduct = (data) => {
    const { name, posId } = data
    aside.showAside(EditProduct as any, {
      headerText: "Add new product",
      initialValues: { name, posId },
      createProduct: true,
      onSubmitCallback: handleRefreshClick,
    })
  }

  const onAddNewRecipe = (data) => {
    const { name, posId } = data
    aside.showAside(EditRecipe as any, {
      headerText: "Add new recipe",
      fullWidth: true,
      initialValues: { name, posId },
      onSubmitCallback: handleRefreshClick,
    })
  }

  const onAddNewDish = (data) => {
    const { name, posId } = data
    aside.showAside(EditDish as any, {
      headerText: "Add new dish",
      fullWidth: true,
      initialValues: { name, posId },
      onSubmitCallback: handleRefreshClick,
    })
  }

  const deleteSale = async (saleId) => {
    const deleted = await removeSale(saleId)
    if (deleted) {
      showSuccess("Sale deleted!")
      navigate("/dashboard/sales")
    } else {
      showError("Something went wrong")
    }
  }

  const handleSaleFileDelete = (saleId) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete Sales File`,
      text: "Are you sure you want to delete this sales file?",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      onConfirm: () => {
        deleteSale(saleId)
      },
    })
  }

  //
  // // Non matching POS ID
  //

  // select handlers

  const onSelectProduct = async (data) => {
    const result = await updateProduct(data.id, data)
    if (apiResponseIsError(result)) {
      showError(result?.message)
    } else {
      showSuccess("POS ID updated!")
    }
    return handleRefreshClick()
  }

  const onSelectRecipe = async (data) => {
    const result = await updateRecipe(data.id, data)
    if (apiResponseIsError(result)) {
      showError(result?.message)
    } else {
      showSuccess("POS ID updated!")
    }
    return handleRefreshClick()
  }

  const onSelectDish = async (data) => {
    const result = await saveDish(data.id, data)
    if (apiResponseIsError(result)) {
      showError(result?.message)
    } else {
      showSuccess("POS ID updated!")
    }
    return handleRefreshClick()
  }

  // select confirmation handlers

  const handleSelectProduct = (selectedProdList, data) => {
    const selectedProd = selectedProdList[0]
    if (!selectedProd.id) return

    const { posId } = data
    const currentPosId = selectedProd.code
    const newProdData = { ...selectedProd, posId }

    modal.showModal(ConfirmModal, {
      title: currentPosId ? "Replace POS ID" : "Add POS ID",
      text: currentPosId
        ? `The current POS ID is, "${currentPosId}". Would you like to replace it with, "${posId}"?`
        : "Do you want to add this POS ID?",
      confirmButtonText: currentPosId ? "Replace" : "Add",
      cancelButtonText: "Cancel",
      onConfirm: () => {
        onSelectProduct(newProdData)
      },
    })
  }

  const handleSelectRecipe = (selectedRecipe, data) => {
    const { posId } = data
    const currentPosId = selectedRecipe.posId
    const newRecipeData = { ...selectedRecipe, posId }

    modal.showModal(ConfirmModal, {
      title: currentPosId ? "Replace POS ID" : "Add POS ID",
      text: currentPosId
        ? `The current POS ID is, "${currentPosId}". Would you like to replace it with, "${posId}"?`
        : "Do you want to add this POS ID?",
      confirmButtonText: currentPosId ? "Replace" : "Add",
      cancelButtonText: "Cancel",
      onConfirm: () => {
        onSelectRecipe(newRecipeData)
      },
    })
  }

  const handleSelectDish = (selectedDish, data) => {
    const { posId } = data
    const currentPosId = selectedDish.posId
    const newDishData = { ...selectedDish, posId }

    modal.showModal(ConfirmModal, {
      title: currentPosId ? "Replace POS ID" : "Add POS ID",
      text: currentPosId
        ? `The current POS ID is, "${currentPosId}". Would you like to replace it with, "${posId}"?`
        : "Do you want to add this POS ID?",
      confirmButtonText: currentPosId ? "Replace" : "Add",
      cancelButtonText: "Cancel",
      onConfirm: () => {
        onSelectDish(newDishData)
      },
    })
  }

  // item select modal handlers

  const showProductModal = (data) => {
    modal.showModal(ProductSelectModal, {
      createNewProduct: () => onAddNewProduct(data),
      onSelect: (selectedProd) => handleSelectProduct(selectedProd, data),
      highlightPosId: true,
      multiSelect: false,
    })
  }

  const showRecipeModal = (data) => {
    modal.showModal(RecipeSelectModal, {
      createNewRecipe: () => onAddNewRecipe(data),
      onSelect: (selectedRecipe) => handleSelectRecipe(selectedRecipe, data),
      highlightPosId: true,
    })
  }

  const showDishModal = (data) => {
    modal.showModal(DishSelectModal, {
      createNewDish: () => onAddNewDish(data),
      onSelect: (selectedDish) => handleSelectDish(selectedDish, data),
      highlightPosId: true,
    })
  }

  return (
    <>
      <Helmet>
        <title>Sales Details</title>
      </Helmet>
      <div className={styles.container}>
        <Header back={true} title="Sales Details" />

        <div className={styles.subHeader}>
          <div className={styles.header} style={{ flex: 2 }}>
            <div className="my-1 lg:my-2 lg:mr-4 w-auto flex-grow lg:flex-1">
              <SearchInput
                label="Search by product name"
                placeholder="Search by product name"
                className="form-control rounded"
                onSearchChange={(product) => setProductName(product)}
              />
            </div>
            <div className="my-1 lg:my-2 lg:mr-4 w-auto flex-grow lg:flex-1">
              <SearchInput
                label="Search by POS ID"
                placeholder="Search by POS ID"
                className="form-control rounded"
                onSearchChange={(posId) => setPosId(posId)}
              />
            </div>
            <div className="flex items-center">
              <button
                onClick={() => handleRefreshClick(salesData.id)}
                className="text-orange-400"
              >
                <FontAwesomeIcon icon={faSync} /> Refresh
              </button>
            </div>
            <div className="my-1 lg:my-2 lg:mr-4 flex justify-end w-full lg:flex-1">
              <button
                type="button"
                className="button my-1 mr-3 lg:my-0 ml-auto button--autoWidth button--red"
                disabled={!permissionObj?.permissions.delete}
                onClick={() => {
                  handleSaleFileDelete(saleId)
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mr-0 lg:mr-2" />
                <span className="hidden lg:inline">Delete</span>
              </button>
              <DropdownButton
                onActionClick={handleActionClick}
                label={<FontAwesomeIcon icon={faFileDownload} />}
                mobileIcon={faEllipsisH}
                options={dropdownOptions}
              />
            </div>
          </div>
        </div>

        {notFound && (
          <div className="text-center mt-10">
            <h2>No Record Found</h2>
          </div>
        )}

        {!notFound && (
          <div className={styles.content}>
            <div className="flex flex-col w-full h-full">
              <div className="mb-4 px-4 lg:px-0 py-2 lg:py-0 flex flex-col items-center">
                <div className="w-full">
                  <p className="text-sm text-gray-700 mb-4">
                    {salesData.from && (
                      <>
                        <b>From</b>{" "}
                        {moment(salesData.from).format("MMMM Do, YYYY")}
                      </>
                    )}
                    <br />
                    {salesData.to && (
                      <>
                        <b>To</b> {moment(salesData.to).format("MMMM Do, YYYY")}
                      </>
                    )}
                  </p>
                </div>

                {salesData.salesOrigin === "COMBINED" && (
                  <div className="w-full mt-4">
                    <p className="text-sm text-gray-700 mb-4">
                      <b>Combined Sales</b>

                      <ul>
                        {salesData.subSales.map((subSale, index) => (
                          <li key={index} className="mt-2">
                            {index + 1}
                            {". "}
                            {subSale.organizations[0] === organization.id ? (
                              <button
                                type="button"
                                className="text-primaryBlue"
                                onClick={() =>
                                  navigate(`/dashboard/sales/${subSale.id}`)
                                }
                              >
                                {getOrgNameById(
                                  subSale.organizations[0],
                                  organizations
                                )}{" "}
                                (
                                {moment(salesData.from).format("MMMM Do, YYYY")}{" "}
                                - {moment(salesData.to).format("MMMM Do, YYYY")}
                                )
                              </button>
                            ) : (
                              <span>
                                {getOrgNameById(
                                  subSale.organizations[0],
                                  organizations
                                )}{" "}
                                (
                                {moment(salesData.from).format("MMMM Do, YYYY")}{" "}
                                - {moment(salesData.to).format("MMMM Do, YYYY")}
                                )
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}
              </div>
              <div className="flex-grow h-full" style={{ minHeight: "200px" }}>
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      fixed
                      style={{ backgroundColor: "transparent" }}
                      width={width}
                      height={height}
                      data={salesData.items}
                      columns={columns}
                      rowKey="posId"
                      emptyRenderer={
                        <div className="flex justify-center">
                          <span className="font-sansBold">
                            No products found
                          </span>
                        </div>
                      }
                    />
                  )}
                </AutoResizer>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SaleDetail
