import React, { useContext, useState } from "react"
import { eventStates } from "services/constants"
import { isValid } from "date-fns"
import { faCalendarAlt } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAlt, faPaperclip } from "@fortawesome/pro-light-svg-icons"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import TimeLabel from "components/common/TimeLabel/TimeLabel"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { showSuccess } from "services/toast"
import usePermissions from "hooks/usePermissions"
import { Event, EventStatusEnum } from "services/events/types"
import { removeEvent } from "services/events/events"
import { navigate } from "gatsby"
import ItemIcon from "components/common/OverviewItem/ItemIcon/ItemIcon"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import EventItemContent from "./EventItemContent"
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons"

// const cx = classNames.bind(styles)

interface EventItemProps {
  event: Event
  onDeleted?: () => void
  onUpdated?: () => void
}

const EventItem = ({ event, onDeleted }: EventItemProps) => {
  const [collapse, setCollapse] = useState(false)
  const modal = useContext(ModalContext)
  const {
    organization,
  }: // organizationPermissions,
  { organization: any; organizationPermissions: any } =
    useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const permissionObj = usePermissions("Recipes & Dishes")

  const getCorrectDate = (rowData: Event) => {
    switch (rowData.status) {
      case EventStatusEnum.IN_PROGRESS:
        return rowData.updatedDate || rowData.createdDate
      case EventStatusEnum.COMPLETED:
        return rowData.updatedDate
      default:
        return rowData.createdDate
    }
  }

  const rowState = (rowData) => {
    const eventState = eventStates.find((eS) => eS.value === rowData.status)
    return (
      eventState && (
        <ItemStatus status={eventState}>
          <TimeLabel date={getCorrectDate(rowData)} />
        </ItemStatus>
      )
    )
  }

  const deleteEvent = async (event: Event) => {
    const deleted = await removeEvent(event.id)
    if (deleted) {
      showSuccess("Event deleted!")
      onDeleted && onDeleted()
    }
  }

  const onEdit = (event: Event) => {
    navigate(`/dashboard/products/events/${event.id}`)
  }

  const onDelete = (event) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${event.name || "event"}`,
      text: "Are you sure you want to delete this event?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteEvent(event),
    })

  const handleActionClick = (action, event) => {
    const { type } = action
    switch (type) {
      case "event.edit":
        onEdit(event)
        break
      case "dn.delete":
        onDelete(event)
        break
      default:
        break
    }
  }

  const totalFiles =
    (event.featuredFile ? 1 : 0) + (event.files ? event.files.length : 0)

  /**
   * Action used for both Edit and Re-order
   * @param  {Object} rowData current o rder
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    const options = [
      {
        key: "event.edit",
        title: "Edit",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "dn.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]
    return (
      <div className="px-3 flex-shrink-0 text-right">
        {rowData?.organizations.length &&
          rowData?.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, rowData)}
            />
          )}
      </div>
    )
  }

  const summaryItems = [
    {
      label: "Total cost",
      value: event.totalCost,
    },
    {
      label: "Cover cost",
      value: event.cover?.cost,
    },
    {
      label: "Cover revenue",
      value: event.cover?.revenue,
    },
    {
      label: "Total revenue net",
      value: event.revenue,
    },
    {
      label: "Total revenue gross",
      value: event.revenueWithTax,
    },
    {
      label: "Net profit",
      value: event.profit,
    },
  ]

  return (
    <div
      className={`border -mb-px first:rounded-t last:rounded-b ${
        collapse ? "collapsed border-gray-300" : ""
      }`}
      style={{ backgroundColor: collapse ? "#F8F8FF" : "#FFFFFF" }}
    >
      <div className="flex flex-wrap items-center content-center justify-between">
        <div className="pl-3 flex w-auto items-center">
          <button
            type="button"
            className="button--smaller button--autoWidth outline-none focus:outline-none text-sm"
            onClick={(e) => {
              e.preventDefault()
              setCollapse(!collapse)
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={collapse ? 180 : undefined}
            />
          </button>
          <ItemIcon
            disabled={!permissionObj?.permissions.read}
            fileId={event.featuredFile && event.featuredFile.fileId}
            fileAlt={event.name}
            mainIcon={{
              icon: faCalendarAlt,
              color: "#000055",
              backgroundColor: "#FFFFFF",
            }}
            onClick={() => handleActionClick({ type: "event.edit" }, event)}
          />
          <button
            onClick={(e) => {
              e.preventDefault()
              handleActionClick({ type: "event.edit" }, event)
            }}
            disabled={!permissionObj?.permissions.read}
            style={{ minWidth: "200px", maxWidth: "200px", textAlign: "left" }}
            className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 pr-3 flex flex-col"
          >
            <span className="break-all">{event.name}</span>
            {event.startDate && isValid(new Date(event.startDate)) && (
              <div className="flex flex-wrap items-center">
                <TimeLabel
                  showInTimeZone
                  date={event.startDate}
                  showIcon={false}
                  showTime
                />
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="text-gray-600 mx-1"
                  style={{ fontSize: "0.25rem" }}
                  size="xs"
                />
                <TimeLabel
                  showInTimeZone
                  date={event.endDate}
                  showIcon={false}
                  showTime
                />
              </div>
            )}
          </button>
        </div>

        <div
          className="flex-col p-3 flex"
          style={{ minWidth: "100px", maxWidth: "100px" }}
        >
          <span className="text-xs text-uppercase text-gray-700">
            Cover count
          </span>
          <span>{event?.coverCount || "-"}</span>
        </div>

        <div
          className="flex-col p-3 flex"
          style={{ minWidth: "100px", maxWidth: "100px" }}
        >
          <span className="text-xs text-uppercase text-gray-700">POS ID</span>
          <span>{event?.posId || "-"}</span>
        </div>

        {rowState(event)}

        <div
          className="px-2 self-start"
          style={{ maxWidth: "240px", minWidth: "240px" }}
        >
          <CostProfitChart
            cost={event.totalCost}
            profit={event.profit}
            costPercentage={event.totalCostPercent}
            profitPercentage={event.profitPercent}
          />
        </div>

        {actions(event)}
      </div>
      {collapse && (
        <div className="w-full pb-0 lg:px-8 lg:pb-8 orderDetails">
          <EventItemContent event={event} />
          <div className="bg-paleBlueDarker mt-4 rounded-md px-4 py-1 bg-opacity-40 flex items-center flex-wrap">
            {summaryItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex pr-12 py-2 flex-col w-1/2 lg:w-auto"
                >
                  <span className="text-xs text-gray-700">{item.label}</span>
                  <span className="text-sm font-semibold font-sansBold text-primaryBlueLighter">
                    {item.value || "-"}
                  </span>
                </div>
              )
            })}
            <div className="flex flex-col py-2 gap-y-1 w-1/2 lg:w-auto">
              <span className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faCommentAlt} className="mr-1" />{" "}
                {event.notes ? 1 : "No"} note
              </span>
              <span className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faPaperclip} className="mr-1" />{" "}
                {totalFiles} file{totalFiles > 1 && "s"}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventItem
