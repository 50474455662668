// react
import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactElement,
  useRef,
} from "react"

// shared & high level
import { isDate } from "date-fns"
import ReactTooltip from "react-tooltip"

// styles & looks
import classNames from "classnames/bind"
import { useMediaQuery } from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faReply,
  faArrowLeft,
  faPlus,
  faMinus,
  faTrashAlt,
  faPencil,
} from "@fortawesome/pro-regular-svg-icons"
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons"

//components
import UpdateItem from "components/invoices/UpdateItem/UpdateItem"
import AddComment from "components/invoices/AddComment/AddComment"
import EditDetails, {
  EditObject,
} from "components/invoices/EditDetails/EditDetails"
import EditTotal from "components/invoices/EditTotal/EditTotal"
import RejectNote from "components/invoices/RejectNote/RejectNote"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import Loader from "components/common/Loader/Loader"
import ConfirmDetailsHeader from "components/invoices/ConfirmDetailsHeader/ConfirmDetailsHeader"
import ConfirmDetailsItem from "components/invoices/ConfirmDetailsItem/ConfirmDetailsItem"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import DeliveryNoteSelect from "components/delivery-notes/DeliveryNoteSelect/DeliveryNoteSelect"
import QueryNote from "components/delivery-notes/QueryNote/QueryNote"
import SearchInput from "components/forms/SearchInput"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import FileList from "components/common/FileList/FileList"
import ConfirmDetailsMessages from "components/invoices/ConfirmDetailsMessages/ConfirmDetailsMessages"
import InvoiceDiscrepancies from "components/common/Discrepancies/Discrepancies"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"
import { Discrepancy } from "components/common/Discrepancies/Discrepancies"
import ConditionalWrapper from "components/util/ConditionalWrapper"
import AccountingPublishContainer from "components/integrations/Accounting/AccountingPublishForm/AccountingPublishContainer"
import AccountingPublishButtons from "components/integrations/Accounting/AccountingPublishButtons"
import { ReviewInvoiceDeliveryInfo } from "../InvoiceDeliveryInfo"

// services
import { showError, showSuccess } from "services/toast"
import { uuidv4, handleError, roundNumber } from "services/helpers"
import { DiscrepancyStatus, Permission } from "services/types"
import {
  addInvoice,
  updateInvoice,
  rejectInvoice,
  querySupplier,
  createInvoiceFromDeliveryNote,
  updateInvoiceFiles,
  InvoicedProduct,
  createDeliveryFromInvoice,
  approveInvoice,
} from "services/invoices"
import { updateProductPrices } from "services/products/products"
import {
  getDiscrepancies,
  updateInvoiceDiscrepancy,
} from "services/invoice-discrepancies"
import { DeliveryNote } from "services/typescript-fetch-client/api"
import { InvoiceProduct } from "services/typescript-fetch-client/api"
import {
  AccountingProvider,
  AccountingPublishParams,
} from "services/accounting"

// states & context
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import { AsideContext } from "context/aside/AsideContext"

// hooks
import usePermissions from "hooks/usePermissions"
import { useFileOwner } from "hooks/useFileOwner"

// styles
import * as styles from "./ConfirmDetails.module.css"
import { updateDeliveryNote } from "services/delivery-notes"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"
const cx = classNames.bind(styles)

interface ConfirmDetailsProps {
  onCancel: Function
  onSave: Function
  onConfirm: Function
  onReject: Function
  onQuery: Function
  onLoadingChange: Function
  onFilesChange: Function
}

type EditItem =
  | {
      type: "DELIVERY"
      title: string
      method: "SEARCH" | "CREATE" | "UNSELECTED"
    }
  | {
      type: "DELIVERY_DETAILS"
      title: string
      delivery: DeliveryNote
    }
  | { type: "NUMBER"; title: string }
  | { type: "REJECT_NOTE"; title: string }
  | { type: "QUERY_NOTE"; title: string }
  | { type: "TOTAL_COST"; title: string }
  | {
      type: "ITEM"
      title: string | React.ReactElement
      item: InvoiceProduct
      index: Number
    }
  | {
      type: "COMMENT"
      title: string
      item: InvoiceProduct
      index: Number
    }
  | { type: "PRODUCT"; title?: string }
  | {
      type: "PUBLISH_TO_ACCOUNTING"
      title: string
      provider: AccountingProvider
    }
  | {
      type: "ACCOUNTING_SUMMARY"
      title: string
      provider: AccountingProvider
    }
  | undefined

const ConfirmDetails = ({
  onCancel,
  onSave,
  onConfirm,
  onReject,
  onQuery,
  onLoadingChange,
  onFilesChange,
}: ConfirmDetailsProps) => {
  const { organization, newInvoice }: { organization: any; newInvoice: any } =
    useContext(GlobalStateContext)
  const tabsRef = useRef<any>(null)
  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Invoices") as Permission
  const [itemList, setItemList] = useState<Array<InvoiceProduct>>([])
  const [initialList, setInitialList] = useState<Array<InvoiceProduct>>([])
  const [discrepancies, setDiscrepancies] = useState<
    Array<Discrepancy> | undefined
  >(undefined)
  const [q, setQ] = useState("")

  const [editItem, setEditItem] = useState<EditItem>(undefined)
  const [saving, setSaving] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState<any | null>(null)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [activeTab, setActiveTab] = useState("items")
  const [fileLoading, setFileLoading] = useState(false)
  const filesOwner = useFileOwner(newInvoice)

  let content: ReactElement | null = null

  //
  // Callbacks
  //

  const onRemove = (name) => {
    modal.showModal(ConfirmModal as any, {
      type: "danger",
      title: "Remove item",
      text: "Are you sure you want to remove this item from the list?",
      confirmButtonText: "Remove",
      onConfirm: () => {
        const newProducts = newInvoice.products?.filter(
          (product: any) => product.description !== name
        )

        const totalCost = _calculateTotalCostInvoice(newProducts)

        const params = {
          ...newInvoice,
          products: newProducts,
          totalCost,
        }

        saveInvoice(params)
      },
    })
  }

  const selectEditItem = useCallback((item, index) => {
    const title = item ? (
      <div
        className={cx("flex w-full", {
          "justify-between": !item.orderQty,
          "justify-center": item.orderQty,
        })}
      >
        <span>{item.description}</span>
        {!item.dnReceivedQty && index !== null && (
          <button
            className="text-primaryPink whitespace-nowrap"
            onClick={() => onRemove(item.description)}
            disabled={!permissionObj?.permissions.modify}
          >
            <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
            Remove
          </button>
        )}
      </div>
    ) : (
      <span></span>
    )
    setEditItem({ type: "ITEM", item, index, title })
  }, [])

  const resetEditItem = useCallback(() => {
    setEditItem(undefined)
  }, [])

  const selectCommentItem = useCallback((item, index) => {
    const title = item ? `Add note for ${item.description}` : ""
    setEditItem({ type: "COMMENT", index, title, item })
  }, [])

  const _calculateTotalCostInvoice = (products) => {
    return products.reduce((acc, product) => {
      return (acc += product.invoicePrice * product.invoiceQty)
    }, 0)
  }

  const _updateProductQuantities = (quantities, editItem) =>
    initialList.map((item: any) => {
      if (editItem.uuid === item.uuid) {
        return {
          ...item,
          ...quantities,
        }
      }
      return item
    })

  const _updateProductComments = (comment, commentItem) => {
    const newProducts = initialList.map((item: any) => {
      if (commentItem.uuid === item.uuid) {
        return {
          ...item,
          comment,
        }
      }
      return item
    })
    return newProducts
  }

  const finalizeInvoiceIfNeededAndAttachDeliveryNote = (deliveryNote) =>
    saveInvoice(
      {
        ...newInvoice,
        status: newInvoice.status === "REVIEW" ? "DRAFT" : newInvoice.status,
      },
      deliveryNote?.id
    )
      .then(() => deliveryNote)
      .catch(handleError)
  const editDeliveryNoteDetails = (delivery: DeliveryNote) => {
    setEditItem({
      type: "DELIVERY_DETAILS",
      delivery,
      title: "Edit delivery",
    })
  }

  const createDeliveryNote = () =>
    createDeliveryFromInvoice(newInvoice.id)
      .then(finalizeInvoiceIfNeededAndAttachDeliveryNote)
      .then(editDeliveryNoteDetails)
      .catch(handleError)

  const approveInv = async () => {
    approveInvoice(newInvoice.id)
      .then((result) => {
        if (result) {
          dispatch({
            type: "UPDATE_NEW_INVOICE",
            payload: {
              status: "APPROVED",
            },
          })
          setSaving(false)
          return onConfirm()
        }
      })
      .catch(handleError)
  }

  const rejectInv = async ({ message, notifySupplier }) => {
    const formData = new FormData()
    formData.append("message", message)
    formData.append("notifySupplier", notifySupplier)
    setSaving(true)
    const result = await rejectInvoice(newInvoice.id, formData)
    setSaving(false)

    if (result && (result.error || result.status === 400)) {
      return showError(result.message || "Could not reject this invoice")
    }
    dispatch({
      type: "UPDATE_NEW_INVOICE",
      payload: {
        status: "REJECTED",
      },
    })
    return onReject()
  }

  const saveInvoice = async (
    params,
    deliveryNoteId = null,
    resetAfterSave = true
  ) => {
    setActiveTab("items")
    setSaving(true)

    const newParams = { ...params }

    if (newParams) {
      if (newParams.dataUri) {
        delete newParams.dataUri
      }
    }

    if (deliveryNoteId) {
      newParams.deliveryNoteId = deliveryNoteId
    }

    /**
     * {01-11-21 RK: Check on saving invoice if newInvoice already has products.
     * If so, invoice should be created from existing params and not from deliveryNoteNumber}
     *
     * @var {[type]}
     */
    const updateResult = newInvoice.id
      ? await updateInvoice(newInvoice.id, newParams)
      : deliveryNoteId
      ? await createInvoiceFromDeliveryNote(deliveryNoteId)
      : await addInvoice(newParams)

    if (
      updateResult &&
      updateResult.status !== 400 &&
      updateResult.status !== 403 &&
      updateResult.status !== 404 &&
      updateResult.status !== 500
    ) {
      setSaving(false)
      // Update quantities here

      const {
        products,
        globalDiscrepancies,
        invoiceNumber,
        dateOfIssue,
        dateOfScanning,
        files,
        extractedFile,
        hasProductPriceDiscrepancies,
        invoicedTotalCost,
        expectedTotalCost,
        deltaTotalCost,
        hasNoOrderedProducts,
        hasNoInvoicedProducts,
        hasNoReceivedProducts,
        hasTotalCostDiscrepancy,
        hasQtyDiscrepancies,
        totalCost,
        totalVat,
        dueDate,
        grossTotalCost,
        supplier,
        status,
        deliveryNoteNumber,
        deliveryNoteId,
        po,
        id,
      } = updateResult

      dispatch({
        type: "UPDATE_NEW_INVOICE",
        payload: {
          products,
          globalDiscrepancies,
          invoiceNumber,
          dateOfIssue,
          dateOfScanning,
          deltaTotalCost,
          hasProductPriceDiscrepancies,
          expectedTotalCost,
          invoicedTotalCost,
          hasNoOrderedProducts,
          hasNoInvoicedProducts,
          hasNoReceivedProducts,
          hasTotalCostDiscrepancy,
          hasQtyDiscrepancies,
          supplier,
          status,
          files,
          extractedFile,
          deliveryNoteNumber,
          deliveryNoteId,
          po,
          totalCost,
          totalVat,
          dueDate,
          grossTotalCost,
          id,
        },
      })
      if (resetAfterSave) {
        resetEditItem()
      }
      showSuccess("Invoice saved!")
      if (onSave) {
        onSave()
      }
      return true
    } else if (
      updateResult.status === 400 ||
      updateResult.status === 404 ||
      updateResult.status === 500
    ) {
      if (updateResult.errors && updateResult.errors.length > 0) {
        showError(updateResult.errors[0].defaultMessage || "Saving failed")
      } else {
        showError(updateResult.message || "Saving failed")
      }
      setSaving(false)
      return false
    } else if (updateResult.status === 403) {
      // message comes from api.js - forbidden
      setSaving(false)
      return false
    }
  }

  const updateQuantity = async (quantities) => {
    if (editItem?.type === "ITEM") {
      const addingNewProduct = editItem.index == null
      const item = editItem.item as any

      const updatedProductsQuantities = _updateProductQuantities(
        quantities,
        item
      )
      const newProductsQuantities = [
        ...newInvoice.products,
        {
          description: item.name,
          invoicePrice: item.price,
          ...item,
          ...quantities,
          orderPrice: item.price,
        },
      ]

      const totalCost = _calculateTotalCostInvoice(
        addingNewProduct ? newProductsQuantities : updatedProductsQuantities
      )

      const params = {
        ...newInvoice,
        totalCost,
        products: addingNewProduct
          ? newProductsQuantities
          : updatedProductsQuantities,
      }

      saveInvoice(params)
    } else {
      throw new TypeError(`Invalid state type  ${editItem?.type}`)
    }
  }

  const updateComment = async (comment) => {
    if (editItem?.type === "COMMENT") {
      const params = {
        ...newInvoice,
        products: _updateProductComments(comment, editItem.item),
      }
      saveInvoice(params)
    } else {
      throw new TypeError(`Invalid state type  ${editItem?.type}`)
    }
  }

  const updateDetails = async ({ number, date, dueDate }, resetForm = true) => {
    const params = {
      ...newInvoice,
      dateOfIssue: isDate(date) ? date.toISOString() : date,
      dueDate: isDate(dueDate) ? dueDate.toISOString() : dueDate,
      invoiceNumber: number,
    }
    saveInvoice(params, null, resetForm)
  }

  const updateTotalCost = async ({ totalCost }) => {
    const params = {
      ...newInvoice,
      totalCost,
    }

    saveInvoice(params)
  }

  const confirmItem = (productData, isConfirmed) => {
    const updatedProductsList = initialList.map((product: any) => {
      if (product.uuid === productData.uuid) {
        return {
          ...product,
          isConfirmed,
        }
      }

      return product
    })

    const params = {
      ...newInvoice,
      products: updatedProductsList,
    }

    saveInvoice(params)
  }

  const confirmPrice = async (productData, isAcceptedPrice) => {
    const { id } = newInvoice.supplier
    const { barcode, invoiceQtyInCase, invoicePrice } = productData

    const paramsProductPrices = {
      casePrice: invoiceQtyInCase,
      price: invoicePrice,
    }

    setSaving(true)
    const created = await updateProductPrices(id, barcode, paramsProductPrices)
    setSaving(false)

    if (created && created.message) {
      return showError(created.message)
    }

    const updatedProductsList = initialList.map((product: any) => {
      if (product.uuid === productData.uuid) {
        return {
          ...product,
          isAcceptedPrice,
        }
      }

      return product
    })

    const paramsInvoice = {
      ...newInvoice,
      products: updatedProductsList,
    }

    saveInvoice(paramsInvoice)
  }

  const confirmSupplier = async () => {
    dispatch({
      type: "UPDATE_NEW_INVOICE",
      payload: {
        supplier: selectedSupplier,
      },
    })
    setEditItem({ type: "DELIVERY", title: "", method: "SEARCH" })
  }

  const notifySupplier = async ({ message }) => {
    const formData = new FormData()

    formData.append("message", message)

    setSaving(true)
    const queryResult = await querySupplier(newInvoice.id, formData)
    setSaving(false)

    if (queryResult.status !== 400) {
      dispatch({
        type: "UPDATE_NEW_INVOICE",
        payload: {
          status: "IN_QUERY",
        },
      })
      showSuccess("Notification sent!")
      return onQuery()
    } else {
      showError("There are no discrepancies.")
    }
  }

  const hasDiscrepancies = () => {
    return (
      newInvoice.hasQtyDiscrepancies ||
      newInvoice.hasNoReceivedProducts ||
      newInvoice.hasProductPriceDiscrepancies
    )
  }

  const notifySupplierConfirm = () => {
    setEditItem({ type: "QUERY_NOTE", title: "Notify supplier" })
  }

  const handleProductSelect = (product) => {
    const isCase = product.packaging === "multiple"
    const selectedProduct = {
      uuid: uuidv4(),
      barcode: product.barcode,
      category: product.category,
      code: product.code,
      invoiceQty: product.quantity,
      isConfirmed: false,
      measure: product.measure,
      description: product.name,
      orderCaseSize: product.productCase?.size,
      invoiceQtyInCase: isCase,
      orderQty: null,
      price: isCase ? product.productCase.price : product.price,
      receivedQtyInCase: isCase,
      size: product.size,
      subCategory: product.subCategory,
      unit: product.unit,
      productCase: product.productCase,
    }
    selectEditItem(selectedProduct, null)
  }

  const handleResolveInvoiceDiscrepancy = async (
    invoice,
    discrepancy: Discrepancy,
    item: InvoiceProduct
  ) => {
    setSaving(true)
    await updateInvoiceDiscrepancy({
      invoiceId: invoice.id,
      discrepancyId: discrepancy.id,
      payload: { status: DiscrepancyStatus.RESOLVED },
    })
    setSaving(false)
    handleProductSelect(item)
  }

  const onAddNewProduct = () => {
    aside.showAside(EditProduct as any, {
      headerText: "Add new product",
      supplierData: newInvoice.supplier,
      createProduct: true,
      onSubmitCallback: (data) => handleProductSelect(data),
    })
  }

  const _getFilteredItemsBySearch = (list, input) => {
    return list.filter((product) => {
      return product.description.toLowerCase().includes(input.toLowerCase())
    })
  }

  const totalFiles =
    (newInvoice.extractedFile ? 1 : 0) +
    (newInvoice.files ? newInvoice.files.length : 0)

  const handleSearch = (input) => {
    setQ(input)

    if (input == "" || !input) {
      setItemList(initialList)
      return
    }

    const filtered = _getFilteredItemsBySearch(initialList, input)
    setItemList(filtered)
  }

  const handleFilesUploaded = async (newFiles) => {
    setFileLoading(true)

    const filesToUpload = [...(newInvoice.files || []), ...newFiles]

    const updateInvFiles = await updateInvoiceFiles(newInvoice.id, {
      files: filesToUpload,
    })

    if (updateInvFiles.files && updateInvFiles.files.length) {
      dispatch({
        type: "UPDATE_NEW_INVOICE",
        payload: { files: updateInvFiles.files },
      })
      onFilesChange()
    }
    setFileLoading(false)
  }

  const handleFilesDelete = async (file) => {
    setFileLoading(true)

    const newFileList = [
      ...newInvoice.files.filter((f) => f.fileId !== file.fileId),
    ]

    const res = await updateInvoiceFiles(newInvoice.id, {
      files: newFileList,
    })

    dispatch({
      type: "UPDATE_NEW_INVOICE",
      payload: { files: res.files },
    })
    onFilesChange()
    setFileLoading(false)
  }

  const handleAccountingPublishSucceed = async (
    AccountingParams: AccountingPublishParams,
    provider: AccountingProvider
  ) => {
    resetEditItem()
    dispatch({
      type: "UPDATE_NEW_INVOICE",
      payload: { [provider.key + "Invoice"]: AccountingParams },
    })
    if (onSave) {
      onSave()
    }
  }

  const pendingDiscrepancyCount = discrepancies
    ? discrepancies.filter(({ status }) => status === "PENDING").length
    : 0

  useEffect(() => {
    if (newInvoice && newInvoice.products) {
      const products = newInvoice.products.map((p, index) => {
        return {
          ...p,
          uuid: uuidv4(),
          originalIndex: index,
        }
      })

      setInitialList(products)

      if (q) {
        const filteredProducts = _getFilteredItemsBySearch(products, q)
        setItemList(filteredProducts)
      } else {
        setItemList(products)
      }
      getDiscrepancies(newInvoice.id).then(setDiscrepancies)
    }
  }, [newInvoice.products])

  useEffect(() => {
    const unresolvedDiscrepancies = discrepancies
      ? discrepancies.filter(
          (discrepancy) => discrepancy.status !== DiscrepancyStatus.RESOLVED
        )
      : undefined
    if (!unresolvedDiscrepancies?.length || newInvoice?.status !== "REVIEW") {
      tabsRef.current?.changeActiveTab("items")
    } else {
      tabsRef.current?.changeActiveTab("discrepancies")
    }
  }, [discrepancies])

  useCallback(() => {
    onLoadingChange(saving)
  }, [saving, onLoadingChange])

  const itemOverview = (
    <div className={styles.items}>
      <Loader isLoading={saving} />

      <div className="flex-grow overflow-auto pb-6">
        <div className="w-full px-4 lg:px-6 pt-3">
          <AccountingPublishButtons
            invoice={newInvoice}
            onClick={(obj) => setEditItem(obj)}
          />
        </div>

        <Tabs
          ref={tabsRef}
          initialTab={activeTab}
          onTabChange={setActiveTab}
          className="growyze-tabs"
        >
          <Tab title={`Items (${itemList.length})`} tabKey="items">
            <div className="px-4 lg:px-6 py-3">
              {newInvoice.supplier && newInvoice.status !== "REVIEW" && (
                <ConfirmDetailsMessages invoice={newInvoice} />
              )}
              <div className="flex items-center">
                <SearchInput
                  className="rounded-tl"
                  placeholder="Search on item name.."
                  onSearchChange={handleSearch}
                  resetVal={null}
                  label={null}
                />
                <button
                  className="button ml-auto no-truncate button--autoWidth button--primaryGreen"
                  onClick={(e) => {
                    e.preventDefault()
                    setEditItem({
                      type: "PRODUCT",
                    })
                  }}
                  disabled={!permissionObj?.permissions.modify}
                >
                  <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
                  <span className="hidden lg:inline">Add item</span>
                </button>
              </div>
              <ul>
                {itemList.length === 0 ? (
                  <span className="block p-4 text-center text-lg">
                    No items found...
                  </span>
                ) : (
                  itemList.length > 0 &&
                  itemList.map((product, index) => {
                    return (
                      <ConfirmDetailsItem
                        product={product}
                        supplier={newInvoice.supplier}
                        onEdit={(product) => selectEditItem(product, index)}
                        onComment={(product) =>
                          selectCommentItem(product, index)
                        }
                        onConfirm={(confirmed) => {
                          confirmItem(product, confirmed)
                        }}
                        onConfirmPrice={(confirmed) => {
                          confirmPrice(product, confirmed)
                        }}
                        key={`${index}_${product.description}_${product.barcode}`}
                        hasDeliveryNote={newInvoice.deliveryNoteId != undefined}
                      />
                    )
                  })
                )}
                <li className={styles.totalAmount}>
                  <div className="flex flex-col flex-1">
                    {newInvoice.deliveryNoteId != null && (
                      <div className="flex justify-end">
                        <span className={styles.itemLabel}>
                          Received total (expected):
                        </span>
                      </div>
                    )}
                    <div className="flex justify-end">
                      <span className={styles.itemLabel}>
                        {" "}
                        Invoice total net:
                      </span>
                    </div>
                    {newInvoice.deliveryNoteId != null && (
                      <div className="flex justify-end">
                        <span className={styles.itemLabel}>Variance:</span>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col flex-1 text-sm ml-2">
                    {newInvoice.deliveryNoteId != null && (
                      <strong className="text-primaryBlue ml-12">
                        {roundNumber(newInvoice.expectedTotalCost, 3)}
                      </strong>
                    )}
                    <div className="flex">
                      <strong className="text-primaryBlue ml-12">
                        {roundNumber(newInvoice.totalCost, 3)}
                      </strong>
                      <button
                        className="ml-2"
                        onClick={() => {
                          setEditItem({
                            type: "TOTAL_COST",
                            title: "Edit the invoice cost",
                          })
                        }}
                        disabled={!permissionObj?.permissions.modify}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </button>
                    </div>

                    {newInvoice.deliveryNoteId != null && (
                      <strong className="text-primaryPink ml-12">
                        {roundNumber(newInvoice.deltaTotalCost, 3)}
                      </strong>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab title={`Files (${totalFiles})`} tabKey="files">
            <div className="px-4 lg:px-6 py-3">
              <FileList
                clientKey={"invoice"}
                extractedFile={newInvoice.extractedFile}
                files={newInvoice.files}
                filesOwner={filesOwner}
                disabled={!permissionObj?.permissions.modify}
                onUploaded={handleFilesUploaded}
                onDeleted={handleFilesDelete}
                isLoading={fileLoading}
                modifyPermission={
                  newInvoice.organizations &&
                  newInvoice.organizations[0] === organization?.id
                }
              />
            </div>
          </Tab>
          <Tab
            title={`Discrepancies (${pendingDiscrepancyCount})`}
            isAllowed={newInvoice.status === "REVIEW"}
            tabKey="discrepancies"
          >
            <InvoiceDiscrepancies
              discrepancies={discrepancies}
              instance={newInvoice}
              handleDiscrepancy={handleResolveInvoiceDiscrepancy}
            />
          </Tab>
        </Tabs>
      </div>

      <footer className="flex items-center flex-shrink-0 w-full bg-white px-4 lg:px-6 py-4 border-t">
        <button className="mr-auto" onClick={() => onCancel()}>
          Close
        </button>
        <>
          {hasDiscrepancies() && newInvoice.status !== "IN_QUERY" && (
            <button
              className="button button--autoWidth button--paleBlue ml-2 no-truncate"
              onClick={notifySupplierConfirm}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faReply} className="mr-2" />
              <span className="inline sm:hidden">Notify</span>
              <span className="hidden sm:inline">Notify supplier</span>
            </button>
          )}
          {newInvoice.status !== "REJECTED" && (
            <button
              onClick={() => {
                setEditItem({
                  type: "REJECT_NOTE",
                  title: "Are you sure you’d like to reject this invoice?",
                })
              }}
              disabled={!permissionObj?.permissions.modify}
              className="button button--autoWidth button--primaryPink ml-4 no-truncate"
            >
              Reject {isTabletOrMobile ? "" : "invoice"}
            </button>
          )}
          {newInvoice.status !== "APPROVED" &&
            (newInvoice.status === "REVIEW" ? (
              <button
                className="button button--autoWidth button--primaryGreen ml-4 no-truncate"
                onClick={() => {
                  if (newInvoice.products?.length === 0) {
                    showError(
                      "You cannot complete an empty invoice, please add at least one item, either by resolving a discrepancy, or manually, via the 'Add' button."
                    )
                  } else {
                    setEditItem({
                      type: "DELIVERY",
                      title: "",
                      method: "UNSELECTED",
                    })
                  }
                }}
                disabled={
                  newInvoice.invoiceNumber === null ||
                  saving ||
                  !permissionObj?.permissions.modify
                }
              >
                Complete {isTabletOrMobile ? "" : "review"}
              </button>
            ) : (
              <ConditionalWrapper
                condition={newInvoice.invoiceNumber === null}
                wrapper={(children) => {
                  return (
                    <span
                      data-tip={"Add invoice number"}
                      data-for={"missingInvoiceNumber"}
                    >
                      {children}
                      <ReactTooltip
                        id={"missingInvoiceNumber"}
                        type="light"
                        place="top"
                        effect="solid"
                        border={true}
                        borderColor="#e2e8f0"
                      />
                    </span>
                  )
                }}
              >
                <button
                  className="button button--autoWidth button--primaryGreen ml-4 no-truncate"
                  onClick={approveInv}
                  disabled={
                    newInvoice.invoiceNumber === null ||
                    saving ||
                    !permissionObj?.permissions.modify
                  }
                >
                  Approve {isTabletOrMobile ? "" : "invoice"}
                </button>
              </ConditionalWrapper>
            ))}
        </>
      </footer>
    </div>
  )

  if (!newInvoice.supplier) {
    content = (
      <div className="h-full overflow-hidden flex flex-col bg-white">
        <h2 className="px-4 lg:px-6 py-4 text-xl text-primaryBlue">
          Select your supplier
        </h2>
        <div className="flex-grow px-4 lg:px-6 overflow-auto relative">
          <Loader isLoading={saving} />
          <SupplierSelect
            onSelect={(supplier) => setSelectedSupplier(supplier)}
          />
        </div>
        <div className="p-4 border-t">
          <button
            disabled={saving}
            onClick={() => confirmSupplier()}
            className="button button--primaryBlue"
          >
            Confirm
          </button>
        </div>
      </div>
    )
  } else if (!newInvoice.id) {
    content = (
      <div className="flex-grow overflow-auto pb-6">
        <div className="px-4 lg:px-6 py-6">
          <h3 className="text-primaryBlue text-xl mb-2 text-center">
            New invoice
          </h3>
          <p className="text-gray-700 text-center mb-4">
            You have no items yet, create new from delivery
          </p>
          <div className="flex flex-col">
            <button
              onClick={() =>
                setEditItem({ type: "DELIVERY", title: "", method: "SEARCH" })
              }
              disabled={!permissionObj?.permissions.modify}
              className="bg-transparent p-4 -mt-px font-sansSemiBold font-semibold rounded border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter text-lg"
            >
              <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
              Create from delivery
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    content = itemOverview
  }

  //
  // Render
  //
  const popupContent = (item: EditItem) => {
    if (item) {
      if (item.type === "PRODUCT") {
        return (
          <>
            <div className="px-6 pt-4 pb-0 flex items-center">
              <button
                className="text-primaryBlue mr-4"
                onClick={() => resetEditItem()}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h2 className="text-xl text-primaryBlue">Search a product</h2>
            </div>
            <div
              style={{ minHeight: "500px" }}
              className="flex-grow flex flex-col overflow-auto relative"
            >
              <NewProductSelect
                onSelect={(item) =>
                  handleProductSelect({ ...item, quantity: 1 })
                }
                onClose={() => resetEditItem()}
                selectedSupplier={selectedSupplier || newInvoice.supplier}
                searchBySKU
                searchBySupplier
                searchByCategories
                searchByPackaging
                createNewProduct={() => onAddNewProduct}
                multiSelect={false}
              />
            </div>
          </>
        )
      }
      if (item.type === "ITEM") {
        return (
          <UpdateItem
            item={item.item}
            newItem={item.index === null}
            isSaving={saving}
            onSave={updateQuantity}
          />
        )
      } else if (item.type === "COMMENT") {
        return (
          <AddComment
            item={item.item}
            isSaving={saving}
            onSave={updateComment}
          />
        )
      } else if (item.type === "REJECT_NOTE") {
        return (
          <RejectNote
            supplier={newInvoice.supplier}
            isSaving={saving}
            onSave={rejectInv}
          />
        )
      } else if (item.type === "QUERY_NOTE") {
        return (
          <QueryNote
            supplier={newInvoice.supplier}
            isSaving={saving}
            onSave={notifySupplier}
          />
        )
      } else if (item.type === "NUMBER") {
        return (
          <EditDetails
            object={{
              number: newInvoice.invoiceNumber,
              date: newInvoice.dateOfIssue,
              dueDate: newInvoice.dueDate,
            }}
            isSaving={saving}
            onSave={(params) => updateDetails(params, true)}
            labels={{
              number: "Invoice number",
              date: "Issue date",
              dueDate: "Due date",
            }}
          />
        )
      } else if (item.type === "DELIVERY_DETAILS") {
        return (
          <EditDetails
            object={
              {
                number: item.delivery.deliveryNoteNumber,
                date: item.delivery.deliveryDate,
              } as EditObject
            }
            isSaving={saving}
            onSave={(deliveryDetails) => {
              if (item.delivery.id) {
                updateDeliveryNote(item.delivery.id, {
                  ...item.delivery,
                  deliveryNoteNumber: deliveryDetails.number,
                  deliveryDate: deliveryDetails.date,
                })
                  .then(() => {
                    dispatch({
                      type: "UPDATE_NEW_INVOICE",
                      payload: { deliveryNoteNumber: deliveryDetails.number },
                    })
                  })
                  .then(resetEditItem)
                  .catch(handleError)
              }
            }}
            labels={{ number: "Delivery number", date: "Delivery date" }}
          />
        )
      } else if (item.type === "TOTAL_COST") {
        return (
          <EditTotal
            invoice={newInvoice}
            isSaving={saving}
            onSave={updateTotalCost}
          />
        )
      } else if (item.type === "DELIVERY") {
        if (item.method === "UNSELECTED") {
          return (
            <div className="flex-grow overflow-auto pb-6">
              <div className="px-4 lg:px-6 py-6">
                <h3 className="text-primaryBlue text-xl mb-2 text-center">
                  Add a delivery to finalise invoice
                </h3>
                <p className="text-gray-700 text-center mb-4">
                  Do you want to create a new delivery that matches the items
                  from the invoice, or connect it with an existing delivery?
                </p>
                <div className="flex flex-col">
                  <button
                    onClick={() =>
                      setEditItem({
                        type: "DELIVERY",
                        title: "",
                        method: "SEARCH",
                      })
                    }
                    className="bg-transparent p-4 -mt-px font-sansSemiBold font-semibold rounded-t border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter text-lg"
                    disabled={!permissionObj?.permissions.modify}
                  >
                    <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                    Connect existing delivery
                  </button>
                </div>
                <div className="flex flex-col">
                  <button
                    onClick={() => createDeliveryNote()}
                    disabled={!permissionObj?.permissions.modify}
                    className="bg-transparent p-4 -mt-px font-sansSemiBold font-semibold rounded-b border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter text-lg"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Create new delivery
                  </button>
                </div>
                <div className="flex flex-col">
                  <button
                    onClick={() =>
                      modal.showModal(ConfirmModal, {
                        onConfirm: () =>
                          finalizeInvoiceIfNeededAndAttachDeliveryNote(null),
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        text: "Without a delivery for this invoice, your items will not be accounted for in any of the reports",
                        title: "Are you sure you don’t need a delivery?",
                      })
                    }
                    disabled={!permissionObj?.permissions.modify}
                    className="bg-transparent p-4 -mt-px font-sansSemiBold font-semibold rounded-b border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter text-lg"
                  >
                    <FontAwesomeIcon icon={faMinus} className="mr-2" />I
                    don&apos;t need a delivery
                  </button>
                </div>
              </div>
            </div>
          )
        } else if (item.method === "SEARCH") {
          return (
            <div className="h-full overflow-hidden flex flex-col bg-white">
              <div className="border-b px-4 lg:px-6 flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={() => resetEditItem()}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="py-4 text-xl text-primaryBlue">
                    Search delivery
                  </h2>
                </div>
                <div className="flex items-center gap-2 my-2 flex-col sm:flex-row md:my-0">
                  <ReviewInvoiceDeliveryInfo invoice={newInvoice} />
                </div>
              </div>
              <div className="flex-grow overflow-auto relative">
                <Loader isLoading={saving} />
                <DeliveryNoteSelect
                  selectedSupplier={newInvoice.supplier}
                  onSelect={finalizeInvoiceIfNeededAndAttachDeliveryNote}
                />
              </div>
            </div>
          )
        }
      } else if (
        (item.type === "PUBLISH_TO_ACCOUNTING" ||
          item.type === "ACCOUNTING_SUMMARY") &&
        item?.provider?.id
      ) {
        return (
          <AccountingPublishContainer
            provider={item.provider}
            editEnabled={item.type === "PUBLISH_TO_ACCOUNTING"}
            currentStep={item.type === "PUBLISH_TO_ACCOUNTING" ? 0 : 1}
            invoice={newInvoice}
            onPublish={handleAccountingPublishSucceed}
            onCancel={() => resetEditItem()}
            onSaveDetails={(params) => updateDetails(params, false)}
            onSaveInvoicedProducts={async (params: InvoicedProduct[]) => {
              await saveInvoice(
                { ...newInvoice, products: params },
                null,
                false
              )
            }}
          />
        )
      }
    } else {
      return undefined
    }
  }

  return (
    <>
      <div className={styles.container}>
        <ConfirmDetailsHeader
          invoice={newInvoice}
          onEdit={() => {
            setEditItem({ type: "NUMBER", title: "Edit details" })
          }}
          onSearchDeliveryAction={() =>
            setEditItem({ type: "DELIVERY", title: "", method: "SEARCH" })
          }
        />

        <div className="flex-grow flex overflow-hidden flex-col">{content}</div>
      </div>
      <OptionsPopup
        active={editItem != undefined}
        title={editItem?.title}
        activeCallback={resetEditItem}
      >
        {popupContent(editItem)}
      </OptionsPopup>
    </>
  )
}

export default ConfirmDetails
