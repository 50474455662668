import { GlobalStateContext } from "context/global/GlobalContextProvider"
import { useContext } from "react"

export const useFileOwner = (entity) => {
  const { organizationPermissions }: { organizationPermissions: any } =
    useContext(GlobalStateContext)

  return organizationPermissions.general.isMain
    ? entity?.organizations?.[0] || null
    : null
}
