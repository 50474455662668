import React from "react"
import TimeField from "react-simple-timefield"

interface TimePickerProps {
  value: string
  onChange: (value: string) => void
  [key: string]: any
}

const TimePicker = ({ value, onChange, ...props }: TimePickerProps) => {
  const handleTimeChange = (event, value) => {
    event.preventDefault()
    onChange(value)
  }

  const customStyles = {
    width: "70px",
    borderRadius: "5px",
    backgroundColor: "#F8F8FF",
    borderColor: "#e2e8f0",
  }

  return (
    <TimeField
      value={value}
      onChange={handleTimeChange}
      style={customStyles}
      {...props}
    />
  )
}

export default TimePicker
