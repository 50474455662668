import React, { useContext, useEffect, useState } from "react"
import Header from "components/dashboard/Header/Header"
import CompleteProfileWidget from "components/dashboard/CompleteProfileWidget/CompleteProfileWidget"
import GetStartedModal from "../GetStartedModal/GetStartedModal"
import { isDevelopment } from "services/constants"
import { countCompletedSteps } from "services/helpers"
import { getStartedSteps, getNextSteps } from "services/constants"
import Helmet from "react-helmet"
import * as styles from "./Dashboard.module.css"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/global/GlobalContextProvider"
import { ModalContext } from "context/modal/ModalContext"
import Rocket from "inline-svg/rocket.svg"
import Logo from "inline-svg/growyze-icon.svg"
import { getOnboardingSteps } from "services/onboarding"
import ChartFilters from "../ChartFilters/ChartFilters"
import {
  faBoxOpen,
  faCalculator,
  faFileAlt,
  faShoppingCart,
  faCoins,
} from "@fortawesome/pro-duotone-svg-icons"
import DashboardPanel from "../DashboardPanel/DashboardPanel"
import DashboardRecentStocktakes from "./DashboardRecentStocktakes/DashboardRecentStocktakes"
import DashboardItem from "../DashboardItem/DashboardItem"

const Dashboard = () => {
  const modal = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const {
    user: currentUser,
    onboardingSteps,
    organization,
  } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const firstOnboardingSteps = getStartedSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps?.id && onboardingSteps[step.name],
    }
  })

  const secondOnboardingSteps = getNextSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps?.id && onboardingSteps[step.name],
    }
  })

  const showGetStartedModal = () => {
    modal.showModal(GetStartedModal, {
      title: "Get started with growyze",
      description:
        "This simple guide helps you get the most of growyze in a few simple steps.",
      isFirstSteps: true,
      svgIcon: (
        <Rocket className="min-w-20 w-20 h-20 -my-20 flex-shrink-0 transform rotate-45" />
      ),
      withNotification: true,
    })
  }

  const showNextStepsModal = () => {
    modal.showModal(GetStartedModal, {
      title: "What is next?",
      description:
        "Why not complete your first stocktake, transfer your recipes and start recording waste?",
      isFirstSteps: false,
      svgIcon: <Logo className="min-w-12 w-12 h-12 mr-6 ml-3 flex-shrink-0" />,
      withNotification: false,
    })
  }

  const fetchOnboarding = async () => {
    try {
      setLoading(true)
      const res = await getOnboardingSteps()
      await dispatch({
        type: "UPDATE_ONBOARDING_STEPS",
        payload: { onboardingSteps: res },
      })
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOnboarding()
    return () => setLoading(false)
  }, [])

  const options = [
    { value: "TODAY", displayName: "Today" },
    { value: "LAST_WEEK", displayName: "Last week" },
    { value: "LAST_MONTH", displayName: "Last month" },
  ]

  const [selectedOption, setSelectedOption] = useState(options[2])
  const [refreshObserver, setrefreshObserver] = useState(0)

  const config = [
    {
      key: "orders",
      displayName: "Orders",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="line"
          apiRoute="orders"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Orders",
            fontAwesomeIcon: faShoppingCart,
            iconBackgroundColor: "#ffe5f0",
            iconColor: "rgb(252, 55, 98)",
          }}
        />
      ),
    },
    {
      key: "invoices",
      displayName: "Invoices",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="line"
          apiRoute="invoices"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Invoices",
            fontAwesomeIcon: faFileAlt,
            iconBackgroundColor: "rgba(231, 237, 255, 1)",
            iconColor: "rgba(38, 118, 238, 1)",
          }}
        />
      ),
    },

    {
      key: "order-status",
      displayName: "Order status",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="bar"
          apiRoute="orders"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Order status",
            fontAwesomeIcon: faShoppingCart,
            iconBackgroundColor: "#ffe5f0",
            iconColor: "rgb(252, 55, 98)",
            // iconColor: "#272764"
          }}
        />
      ),
    },
    {
      key: "delivery-status",
      displayName: "Delivery status",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="bar"
          apiRoute="delivery-notes"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Delivery notes",
            fontAwesomeIcon: faBoxOpen,
            iconColor: "rgb(185, 29, 179)",
            iconBackgroundColor: "rgb(250, 234, 255)",
          }}
        />
      ),
    },
    {
      key: "recent-stocktakes",
      displayName: "Recent stocktakes",
      className: "w-full",
      style: {},
      component: <DashboardRecentStocktakes />,
    },
  ]

  // Add live sales for organization 'The International' and 'Balham Brickwood'
  if (
    isDevelopment ||
    organization.id === "65a79f57c1373e390dade844" ||
    organization.id === "663a743862a8da6142dd8bdc"
  ) {
    config.splice(2, 0, {
      key: "sales",
      displayName: "Sales",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="line"
          apiRoute="sales"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Live sales",
            fontAwesomeIcon: faCoins,
            iconBackgroundColor: "rgba(0, 0, 85, 0.1)",
            iconColor: "rgba(0, 0, 85, 1)",
          }}
        />
      ),
    })
  }

  if (isDevelopment || organization.id === "65a79f57c1373e390dade844") {
    config.push({
      key: "cogs-reports",
      displayName: "COGS reports",
      className: "w-full",
      component: (
        <DashboardItem
          itemType="list"
          apiRoute="stock-take/cogs-reports"
          header={{
            title: "COGS",
            fontAwesomeIcon: faCalculator,
            iconColor: "rgb(62, 175, 174)",
            iconBackgroundColor: "rgb(224, 255, 255)",
          }}
          cta={{
            label: "More Insights",
            ctaBackgroundColor: "#FFFFFFB2",
            borderColor: "#ECECEC",
            textColor: "#000055",
          }}
          chartType="mixed"
          items={[
            {
              title: "COGS",
              value: "totalCOGS",
              borderColor: "primaryBlue",
            },
            {
              title: "Sales",
              value: "totalSales",
              borderColor: "purple-300",
            },
            {
              title: "COGS %",
              value: "totalCOGSPercentage",
              borderColor: "red-600",
            },
            {
              title: "COG spent %",
              value: "totalCOGSpentPercentage",
              borderColor: "teal-500",
            },
          ]}
          datasets={[
            {
              label: "COGS",
              dataName: "totalCOGS",
              data: [],
              borderColor: "white",
              pointBackgroundColor: "#5C5C92",
              backgroundColor: "#5C5C92",
              yAxisID: "y",
              fill: true,
              type: "bar",
              borderWidth: 2,
              order: 2,
            },
            {
              label: "COGS %",
              dataName: "totalCOGSPercentage",
              data: [],
              borderColor: "#FC3762",
              borderWidth: 2,
              pointBackgroundColor: "#FC3762",
              backgroundColor: "#FC3762",
              yAxisID: "y2",
              fill: false,
              type: "line",
              order: 0,
            },
          ]}
          //https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#common-options-to-all-cartesian-axes
          chartOptions={{
            scales: {
              y: {
                type: "linear",
                display: true,
                position: "left",
                // suggestedMax: 100,
                // suggestedMin: -100,
              },
              y2: {
                type: "linear",
                display: true,
                position: "right",
                // offset: true, // line will be shown behind the bar
                grid: {
                  drawOnChartArea: false,
                },
                // suggestedMax: 100,
                // suggestedMin: -100,
                ticks: {
                  callback: function (value) {
                    return value + "%"
                  },
                },
              },
            },
          }}
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
        />
      ),
    })
  }

  const components = Object.freeze(config)

  const [visibleCharts, setVisibleCharts] = useState(
    components.map(({ key, displayName, className, style }) => ({
      key,
      displayName,
      className,
      style,
      visible: true,
    }))
  )

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Dashboard" />
        <div className={styles.content}>
          {!loading &&
            onboardingSteps?.id &&
            countCompletedSteps(firstOnboardingSteps) !== "4/4" && (
              <CompleteProfileWidget
                user={currentUser}
                steps={firstOnboardingSteps}
                title="Get started with growyze"
                buttonText="Show me"
                actionHandler={showGetStartedModal}
                svgComponent={<Rocket className={styles.rocketIcon} />}
              />
            )}

          {!loading &&
            onboardingSteps?.id &&
            countCompletedSteps(firstOnboardingSteps) === "4/4" &&
            countCompletedSteps(secondOnboardingSteps) !== "3/3" && (
              <CompleteProfileWidget
                user={currentUser}
                steps={secondOnboardingSteps}
                title="What is next with growyze..."
                buttonText="Show me"
                actionHandler={showNextStepsModal}
                svgComponent={<Logo className={styles.growyzeIcon} />}
              />
            )}
          {/* <Widgets /> */}
          {!loading && (
            <>
              <ChartFilters
                setrefreshObserver={setrefreshObserver}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                options={options}
                visibleCharts={visibleCharts}
                setVisibleCharts={setVisibleCharts}
              />
              <div className="w-full flex flex-wrap">
                {visibleCharts
                  .filter((component) => component.visible)
                  .map((component) => {
                    return (
                      <div
                        className={component.className + " px-2"}
                        style={component.style}
                        key={component.key}
                      >
                        {components.find((x) => x.key === component.key)
                          ? components.find((x) => x.key === component.key)
                              .component
                          : null}
                      </div>
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard
