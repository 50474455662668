/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useMemo } from "react"
import { ModalContext } from "context/modal/ModalContext"
import { GlobalDispatchContext } from "context/global/GlobalContextProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Note from "components/stock/Note/Note"
import DraggableTable from "components/common/DraggableTable/DraggableTable"
import StockTakeSheetInput from "components/stock/StockTakeSheetInput/StockTakeSheetInput"
import SearchInput from "components/forms/SearchInput"
import * as styles from "./StockTakeSheetContent.module.css"
import RecipeSelectModal from "components/dishes/EditElements/modals/RecipeSelectModal"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import {
  faExclamationCircle,
  faPlus as faPlusLight,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons"
import { ColumnShape } from "react-base-table"
import ReactTooltip from "react-tooltip"
import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"

interface ProductRowData {
  barcode: string
  category: string
  id: string
  measure: string
  name: string
  size: number
  subCategory: string
  unit: string
  countedInCase?: boolean
  caseSize?: number
}

interface RecipeRowData {
  name: string
  recipeId: string
}

type RowData = ProductRowData | RecipeRowData

interface Props {
  data: { products: ProductRowData[]; recipes: RecipeRowData[] }
  onClose: () => void
  onAddProductCallback?: (items: ProductRowData) => void
  onAddRecipeCallback?: (items: RecipeRowData) => void
}

const StockTakeSheetContent = ({
  data,
  onClose,
  onAddProductCallback,
  onAddRecipeCallback,
}: Props) => {
  const [tab, setTab] = useState("products")
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Stocktakes") as Permission
  const [sheetData, setSheetData] = useState<RowData[]>(
    data.products ? data.products : []
  )
  const [query, setQuery] = useState("")

  const filteredData = useMemo(
    () =>
      sheetData.filter((element) => {
        return element["name"].toLowerCase().includes(query.toLowerCase())
      }),
    [query, sheetData]
  )

  const handleReorder = (newOrderData) => {
    setSheetData(newOrderData)
    setSheet({ [tab]: newOrderData })
  }

  const setSheet = (stockTakeSheetData) => {
    if (!permissionObj?.permissions.modify) {
      return
    } else {
      dispatch({
        type: "UPDATE_NEW_STOCKTAKE_SHEET",
        payload: {
          stockTakeSheetData: { ...stockTakeSheetData, unsynced: true },
        },
      })
    }
  }

  const onAddProduct = async (productsRaw, startIndex) => {
    const products = productsRaw.map((product) => {
      const prod = {
        barcode: product.barcode,
        category: product.category,
        id: product.id,
        measure: product.measure,
        name: product.name,
        size: product.size,
        subCategory: product.subCategory,
        unsynced: true,
      }
      if (product.packaging === "multiple") {
        prod["countedInCase"] = true
        prod["caseSize"] = product.productCase?.size || 1
      }
      return prod
    })
    if (onAddProductCallback) {
      onAddProductCallback(products)
    }
    const productArray = [...sheetData]
    productArray.splice(startIndex, 0, products)

    setSheet({ products: productArray.flat() })
  }

  const addProduct = (index: number) => {
    modal.showModal(StockTakeSheetInput, {
      startIndex: index + 1,
      onSave: onAddProduct,
    })
  }

  const addRecipe = (index) => {
    modal.showModal(RecipeSelectModal, {
      onSelect: (recipe) => {
        const currentRecipes = [...(data.recipes || [])]
        const startIndex = index + 1

        currentRecipes.splice(startIndex, 0, {
          ...recipe,
          recipeId: recipe.id,
        })

        if (onAddRecipeCallback) {
          onAddRecipeCallback(currentRecipes)
        }
        setSheet({
          recipes: currentRecipes,
          unsynced: true,
        })
      },
    })
  }

  const columns: ColumnShape<RowData>[] = [
    {
      key: "description",
      title: "Item",
      dataKey: "description",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="flex items-start py-3">
            {tab === "products" && (
              <FontAwesomeIcon
                icon={rowData.countedInCase ? faCube : faWineBottle}
                className="text-primaryBlue self-start mt-1 mr-2 text-sm"
              />
            )}
            <div className="flex flex-col">
              <span className="font-bold text-sm font-sansBold text-primaryBlue">
                <span className={rowData.isDeleted ? "opacity-50" : ""}>
                  {rowData.name}
                </span>
                {rowData.isDeleted && (
                  <>
                    <span
                      data-for="deleted_product_tooltip"
                      data-tip="This product is deleted."
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="lg"
                        className="text-error ml-2"
                      />
                    </span>

                    <ReactTooltip
                      id={"deleted_product_tooltip"}
                      type="light"
                      place="right"
                      effect="float"
                      border={true}
                      borderColor="#e2e8f0"
                    />
                  </>
                )}
              </span>
              <span
                className={
                  "text-sm font-sansSemiBold font-semibold text-gray-700 " +
                  (rowData.isDeleted ? "opacity-50" : "")
                }
              >
                {rowData.countedInCase && rowData.caseSize
                  ? `${rowData.caseSize} x `
                  : ""}
                {rowData.size} {rowData.measure}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      sheetData, // add state here to prevent rowData from becoming stale
      tab,
      key: "action",
      title: "",
      dataKey: "action",
      width: 50,
      flexGrow: 1,
      flexShrink: 0,
      className: "justify-end relative",
      cellRenderer: ({ rowData, rowIndex }) => {
        return (
          <div className="flex flex-col absolute right-0 top-0 h-full w-8">
            <button
              type="button"
              className="flex h-1/2 bg-primaryPinkLighter bg-opacity-0 hover:bg-opacity-10 border-l text-primaryPink flex-col items-center justify-center self-stretch"
              disabled={!permissionObj?.permissions.modify}
              onClick={() => {
                setSheet({
                  [tab]: sheetData.filter((item) => item.id !== rowData.id),
                })
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>

            <div className="relative inline-block h-1/2 bg-primaryGreenLighter bg-opacity-20 hover:bg-opacity-30 border-l rounded-br self-stretch w-full">
              <button
                type="button"
                disabled={!permissionObj?.permissions.modify}
                onClick={() =>
                  tab === "products"
                    ? addProduct(rowIndex)
                    : addRecipe(rowIndex)
                }
                className={
                  "text-primaryGreenDarker h-full w-full flex flex-col items-center justify-center"
                }
              >
                <FontAwesomeIcon icon={faPlusLight} />
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  const tabContentProductsAndRecipes = (
    <>
      <div className="flex">
        <SearchInput
          initialValue={query}
          label="Search by name"
          placeholder="Search by name"
          className="form-control"
          onSearchChange={(value) => {
            setQuery(value)
          }}
          resetVal=""
        />
      </div>
      <div className="h-full">
        <DraggableTable<RowData>
          data={filteredData}
          columns={columns}
          setData={handleReorder}
          emptyRenderer={
            <div className="flex flex-col items-center justify-center h-full">
              <h3 className="text-lg mb-4 font-semibold">
                {`No ${tab} in this sheet yet`}
              </h3>

              {permissionObj?.permissions.modify && (
                <button
                  className={"button button--autoWidth button--primaryGreen"}
                  onClick={tab === "products" ? addProduct : addRecipe}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add {tab === "products" ? "product" : "recipe"}
                </button>
              )}
            </div>
          }
        />
      </div>
    </>
  )

  const amountOfItems = data[tab] ? data[tab].length : 0

  useEffect(() => setSheetData(data[tab] ? data[tab] : []), [data, tab])

  return (
    <div className={styles.contentContainer}>
      <Tabs
        onTabChange={(tab) => setTab(tab)}
        initialTab={"products"}
        className="growyze-tabs"
      >
        <Tab title="Items" tabKey="products">
          {tabContentProductsAndRecipes}
        </Tab>
        <Tab title="Recipes" tabKey="recipes">
          {tabContentProductsAndRecipes}
        </Tab>
        <Tab title="Notes" tabKey="notes">
          <Note note={data.note} onChange={(note) => setSheet({ note })} />
        </Tab>
      </Tabs>

      <footer className={styles.footer}>
        <button className={styles.cancelButton} onClick={onClose}>
          Close
        </button>
        {tab !== "notes" && <span>{amountOfItems} items</span>}
      </footer>
    </div>
  )
}

export default StockTakeSheetContent
