import React, { ReactNode } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//@ts-ignore
import * as styles from "./ItemIcon.module.css"
import { IconProps } from "services/types"
import ServerImage from "components/common/ServerImage/ServerImage"

interface ItemIconProps {
  fileId?: string
  fileAlt?: string
  mainIcon: IconProps
  subIcon?: IconProps
  linkURL?: string
  onClick?(): void
  disabled?: boolean
}

const CompWrapperComponent = ({
  linkURL,
  onClick,
  children,
  className,
  disabled = false,
}: {
  linkURL?: string
  onClick?(): void
  children: ReactNode
  className: string
  disabled?: boolean
}) => {
  const extendedClassName = `${className} ${
    onClick || (linkURL && "cursor-pointer")
  } ${disabled && "cursor-not-allowed"}`

  if (linkURL && !disabled) {
    return (
      <Link to={linkURL} className={extendedClassName}>
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        type="button"
        className={extendedClassName}
      >
        {children}
      </button>
    )
  } else {
    return <div className={extendedClassName}>{children}</div>
  }
}

const ItemIcon = ({
  fileId,
  fileAlt,
  mainIcon,
  subIcon,
  linkURL,
  onClick,
  disabled,
}: ItemIconProps) => {
  const { icon, color, backgroundColor } = mainIcon

  return (
    <CompWrapperComponent
      className={styles.thumbnailWrapper}
      disabled={disabled}
      linkURL={linkURL}
      onClick={onClick}
    >
      <div className={styles.thumbnail}>
        <div
          className={styles.iconWrapper}
          style={{
            backgroundColor,
            color,
          }}
        >
          {fileId ? (
            <ServerImage fileId={fileId} alt={fileAlt || "Click to view more"}>
              <FontAwesomeIcon icon={icon} />
            </ServerImage>
          ) : (
            <FontAwesomeIcon icon={icon} />
          )}
          {subIcon && (
            <span
              className={styles.thumbnailBadge}
              style={{
                color: subIcon.color || "#35ccc3",
                ...(subIcon.backgroundColor && {
                  backgroundColor: subIcon.backgroundColor,
                }),
              }}
            >
              <FontAwesomeIcon size="xs" icon={subIcon.icon} />
            </span>
          )}
        </div>
      </div>
    </CompWrapperComponent>
  )
}

export default ItemIcon
