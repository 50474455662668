import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextInput from "components/forms/TextInput"
import { ModalContext } from "context/modal/ModalContext"
import { ArrayHelpers, FieldArray } from "formik"
import React, { useContext } from "react"

//@ts-ignore
import * as styles from "./EditElements.module.css"
import MeasureSelect from "./MeasureSelect"
import OtherIngredientInfo from "./ElementInfo/OtherIngredientInfo"
import ProductInfo from "./ElementInfo/ProductInfo"
import { formatCurrencyValue } from "services/helpers"
import ProductSelectModal from "./modals/ProductSelectModal"
import RecipeSelectModal from "./modals/RecipeSelectModal"
import RecipeInfo from "./ElementInfo/RecipeInfo"
import {
  DishElementType,
  RecipeContainer,
  DishElement,
} from "services/dishes/types"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import {
  faExclamationCircle,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons"
import { findValueLabel } from "../../../services/helpers"
import { allergens } from "services/constants"
import ReactTooltip from "react-tooltip"

const itemTypes: Record<string, any> = {
  INGREDIENT: {
    type: "INGREDIENT",
    ingredient: {
      cost: undefined,
      measure: "L",
      product: {
        name: "",
        barcode: "",
        category: "",
        subCategory: "",
        measure: "",
        size: 0,
        price: 0,
        supplierName: "",
        supplierId: "",
      },
      usedQty: undefined,
      usedQtyInProductMeasure: 0,
    },
  },
  RECIPE: {
    type: "RECIPE",
    recipe: {
      measure: "L",
      recipe: {
        name: "recipe",
        barcode: "",
        category: "",
        subCategory: "",
        measure: "",
        size: 0,
        totalCost: 0,
      },
      usedQty: undefined,
      usedQtyInProductMeasure: 0,
      cost: undefined,
    },
  },
  OTHER_INGREDIENT: {
    type: "OTHER_INGREDIENT",
    otherIngredient: {
      usedQtyInProductMeasure: 0,
      cost: 0,
      measure: "L",
      name: "",
      usedQty: undefined,
    },
  },
}

const PriceSelect = ({ element, path, disabled = false }) => (
  <div>
    {/*<p>Cost</p>*/}
    <div className="form-group">
      <div className="input-container">
        <TextInput
          required={true}
          name={`${path}.cost`}
          label="Cost"
          type="text"
          disabled={disabled}
          value={element.cost}
          showValidations={false}
          placeholder="Cost"
          className="form-control form-control--first form-control--last"
        />
      </div>
    </div>
  </div>
)

const PriceDisplay = ({ element }) => {
  const cost = element.cost ?? element.totalCost
  if (cost !== undefined) {
    return (
      <div>
        <div className={styles.costField}>{formatCurrencyValue(cost)}</div>
      </div>
    )
  } else {
    return (
      <div className="text-xs text-orange-600 table-cel flex flex-col justify-center">
        Save to see the cost
      </div>
    )
  }
}
const typesToProps: Record<DishElementType, string> = {
  INGREDIENT: "ingredient",
  RECIPE: "recipe",
  OTHER_INGREDIENT: "otherIngredient",
}

const elementPickers: Record<
  DishElementType,
  React.FunctionComponent<any> | undefined
> = {
  RECIPE: RecipeSelectModal,
  INGREDIENT: ProductSelectModal,
  OTHER_INGREDIENT: undefined,
}

export default ({
  name,
  elements,
  disabled,
}: {
  name: string
  elements: Array<DishElement>
  disabled?: boolean
}) => {
  const modal = useContext(ModalContext)

  const pick = (type, multiSelectEnabled = true): Promise<any> =>
    new Promise((onSelect) =>
      modal.showModal(elementPickers[type], {
        onSelect,
        multiSelect:
          multiSelectEnabled !== undefined ? multiSelectEnabled : false,
        qtyPicker: false,
      })
    )

  const updateProduct = (
    arrayHelpers: ArrayHelpers,
    index,
    fullProduct,
    updatedProduct
  ) => {
    const item = JSON.parse(JSON.stringify(fullProduct))
    item.ingredient.product = updatedProduct
    arrayHelpers.replace(index, item)
  }

  const changeProduct =
    (arrayHelpers: ArrayHelpers, index, fullProduct) => () =>
      pick("INGREDIENT", false).then((product) => {
        updateProduct(arrayHelpers, index, fullProduct, product[0])
      })

  const changeRecipe = (arrayHelpers: ArrayHelpers, index, fullProduct) => () =>
    pick("RECIPE", false).then((product) => {
      const item = JSON.parse(JSON.stringify(fullProduct))
      item.recipe.recipe = product
      arrayHelpers.replace(index, item)
    })

  const CreateButtons = ({
    arrayHelpers,
    index,
    label = undefined,
    buttonClass,
    title = undefined,
    dropDirection = "top-right",
    containerClass = "m-0",
    disabled = false,
  }: {
    arrayHelpers: ArrayHelpers
    index: number
    label?: string | React.JSX.Element
    buttonClass: string
    title?: string
    dropDirection?: "top-right" | "top-left" | undefined
    containerClass?: string
    disabled?: boolean
  }) => {
    const functions = {
      addOtherIngredient: (arrayHelpers, index) => {
        arrayHelpers.insert(index, itemTypes.OTHER_INGREDIENT)
      },
      addRecipe: (arrayHelpers, index) =>
        pick("RECIPE").then((recipes) => {
          recipes.forEach((recipe, innerIndex) => {
            const item: RecipeContainer = JSON.parse(
              JSON.stringify(itemTypes.RECIPE)
            )
            item.recipe.recipe = recipe
            arrayHelpers.insert(index + innerIndex, item)
          })
        }),

      addProduct: (arrayHelpers, index) =>
        pick("INGREDIENT").then((products) => {
          products.forEach((product, innerIndex) => {
            const item = JSON.parse(JSON.stringify(itemTypes.INGREDIENT))
            item.ingredient.product = product
            //Setting original product measure to object property
            if (product.measure) item.ingredient.measure = product.measure
            arrayHelpers.insert(index + innerIndex, item)
          })
        }),
    }
    return (
      <DropdownButton
        containerClass={containerClass}
        options={[
          {
            title: "Product",
            key: "addProduct",
            disabled: disabled,
          },
          {
            title: "Recipe",
            key: "addRecipe",
            disabled: disabled,
          },
          {
            title: "Other ingredient",
            key: "addOtherIngredient",
            disabled: disabled,
          },
        ]}
        label={label}
        title={title}
        dropDirection={dropDirection}
        buttonClass={buttonClass}
        mobileIcon={faPlus}
        onActionClick={({ type }) => {
          functions[type](arrayHelpers, index)
        }}
      />
    )
  }

  return (
    <FieldArray
      name={`${name}.elements`}
      render={(arrayHelpers) => (
        <div className="w-full pl-0 md:pl-4 pt-2">
          {elements.map((element: any, index) => {
            const type = element.type
            const elementPropsPath = typesToProps[type]
            const elementPath = [
              name,
              "elements",
              index,
              elementPropsPath,
            ].join(".")
            const elementProps = element[elementPropsPath]
            const objectKey = type === "RECIPE" ? "recipe" : "product"

            const dishHasAllergens = [
              ...(Array.isArray(elementProps[objectKey]?.allergens)
                ? elementProps[objectKey]?.allergens
                : []),
              ...(Array.isArray(elementProps[objectKey]?.mayContainAllergens)
                ? elementProps[objectKey]?.mayContainAllergens
                : []),
            ].length

            return (
              <div
                className={styles.ingredientContainer}
                key={`Product-${element?.ingredient?.product?.id}-${index}`}
              >
                <div className={`${styles.info} ${styles.column}`}>
                  <div className="flex items-center">
                    {element?.ingredient?.product?.isDeleted && (
                      <>
                        <span
                          data-for="deleted_product_tooltip"
                          data-tip="This ingredient is deleted."
                        >
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            size="lg"
                            className="text-error mr-px"
                          />
                        </span>

                        <ReactTooltip
                          id={"deleted_product_tooltip"}
                          type="light"
                          place="right"
                          effect="float"
                          border={true}
                          borderColor="#e2e8f0"
                        />
                      </>
                    )}

                    {
                      {
                        INGREDIENT: (
                          <ProductInfo
                            element={elementProps}
                            change={() =>
                              changeProduct(arrayHelpers, index, element)
                            }
                          />
                        ),
                        RECIPE: (
                          <RecipeInfo
                            element={elementProps}
                            change={() =>
                              changeRecipe(arrayHelpers, index, element)
                            }
                          />
                        ),
                        OTHER_INGREDIENT: (
                          <OtherIngredientInfo
                            path={elementPath}
                            disabled={disabled}
                            element={elementProps}
                          />
                        ),
                      }[type]
                    }
                  </div>
                </div>
                <div className={`${styles.measure} ${styles.column}`}>
                  <span className="text-sm mb-1 text-gray-700">Quantity</span>
                  <MeasureSelect
                    disabled={disabled}
                    path={elementPath}
                    element={elementProps}
                    onUpdate={(data) => {
                      updateProduct(arrayHelpers, index, element, data)
                    }}
                  />
                </div>

                {type !== "OTHER_INGREDIENT" && (
                  <div
                    className={`flex flex-col justify-center px-4 py-2 mr-auto w-40`}
                  >
                    <span className="text-sm mb-1 text-gray-700">
                      Allergens
                    </span>
                    {!dishHasAllergens ? (
                      <p className="text-xs">No allergens</p>
                    ) : (
                      <>
                        {elementProps[objectKey].allergens?.length > 0 && (
                          <div className="text-xs mb-2">
                            <div className="text-gray-700">Contains:</div>
                            <div className="flex flex-wrap capitalize">
                              {elementProps[objectKey].allergens
                                .map((allergen) =>
                                  findValueLabel(allergens, allergen)
                                )
                                .join(", ")}
                            </div>
                          </div>
                        )}
                        {elementProps[objectKey].mayContainAllergens?.length >
                          0 && (
                          <div className="text-xs">
                            <div className="text-gray-700">May contain:</div>

                            <div className="flex flex-wrap text-xs capitalize">
                              {elementProps[objectKey].mayContainAllergens
                                .map((allergen) =>
                                  findValueLabel(allergens, allergen)
                                )
                                .join(", ")}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {type === "OTHER_INGREDIENT" ? (
                  <div className={`${styles.costEdit} ${styles.column}`}>
                    <span className="text-sm mb-1 text-gray-700">Cost</span>
                    <PriceSelect
                      disabled={disabled}
                      path={elementPath}
                      element={elementProps}
                    />
                  </div>
                ) : (
                  <div className={`${styles.costDisplay} ${styles.column}`}>
                    <span className="text-xs text-right font-semibold font-sansSemiBold uppercase mb-1 text-gray-600">
                      Cost
                    </span>
                    <PriceDisplay element={elementProps} />
                  </div>
                )}

                <div className={styles.itemActions}>
                  <button
                    type="button"
                    className={styles.itemRemoveButton}
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={disabled}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>

                  <CreateButtons
                    arrayHelpers={arrayHelpers}
                    index={index + 1}
                    buttonClass={styles.itemActionsButton}
                    disabled={disabled}
                    title={"Add item below:"}
                    containerClass={styles.itemActionsButtonContainer}
                  />
                </div>
              </div>
            )
          })}
          {elements.length === 0 && (
            <div>
              <div className={styles.noIngredients}>No ingredients yet.</div>
              <CreateButtons
                arrayHelpers={arrayHelpers}
                index={0}
                label={
                  <>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                    Ingredient
                  </>
                }
                dropDirection="top-left"
                disabled={disabled}
                buttonClass="button--lightBlue button--smaller"
              />
            </div>
          )}

          {/*
           */}
        </div>
      )}
    />
  )
}
