import React, { useState } from "react"
import { SearchFilterOption } from "services/types"
import FilterSelect from "./FilterSelect"
import SearchInput from "./SearchInput"

interface FilteredSearchInputProps {
  filterOptions: SearchFilterOption[]
  onChange(params: {
    value: string
    queryParamName: SearchFilterOption["value"]
  }): void
}

const FilteredSearchInput = ({
  filterOptions,
  onChange,
}: FilteredSearchInputProps) => {
  const [queryParameter, setQueryParameter] = useState<SearchFilterOption>(
    filterOptions[0]
  )

  return (
    <>
      <SearchInput
        label={`Search by ${queryParameter.label}`}
        placeholder={`Search by ${queryParameter.label}`}
        className={`form-control rounded text-sm md:text-base mr-4 md:mr-6 border-r-0`}
        onSearchChange={(val) =>
          onChange({ value: val, queryParamName: queryParameter.value })
        }
      />
      <FilterSelect
        options={filterOptions}
        value={queryParameter && queryParameter.value}
        onChange={(val) => {
          setQueryParameter(val)
        }}
        stylesOverride={{
          container: (provided) => ({
            ...provided,
            position: "static",
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            borderLeft: "none",
            borderRadius: "0 0.25rem 0.25rem 0",
            width: "fit-content",
            borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
            boxShadow: "none",
            zIndex: state.isFocused ? 2 : null,
            minHeight: 45,
            ":hover": {
              ...provided[":hover"],
              borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: "0px",
            width: "0px",
          }),
          menu: (provided) => ({
            ...provided,
            left: "0px",
          }),
        }}
      />
    </>
  )
}

export default FilteredSearchInput
