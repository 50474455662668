import React, { useEffect, useState } from "react"

import { faSalad } from "@fortawesome/pro-duotone-svg-icons"
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getDishesByIds } from "services/dishes/dishes"
import { Link } from "@reach/router"
import Modal from "react-modal"
import ServerImage from "components/common/ServerImage/ServerImage"

import { productCategories } from "services/constants"

Modal.setAppElement("body")

function DishListModal({ recipe }) {
  // State
  const [dishes, setDishes] = useState([])
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [loading, setloading] = useState<boolean>(true)

  // Locals
  const dishesIds: string[] = recipe?.dishes?.map((dish) => dish.id)

  // Mount
  useEffect(() => {
    if (dishesIds.length) {
      //Fetch only if ids are available to prevent unecessary GET request
      getDishesByIds({
        dishesIds,
      }).then((data: any) => {
        setloading(false)
        setDishes(data)
      })
    } else {
      //Nothing to fetch so stop loading
      setloading(false)
    }
  }, [])

  return (
    isOpen && (
      <Modal
        isOpen
        onRequestClose={() => setIsOpen(false)}
        portalClassName="dishListModal"
      >
        <div className="h-full overflow-hidden flex flex-col">
          {/* Header */}
          <div className="p-4 px-5 flex-shrink-0 border-b">
            <div className="flex w-full justify-between items-center">
              <h1 className="text-xl">Dish list</h1>
              <button
                className="cursor-pointer rounded-full w-10 h-10 hover:bg-gray-200 flex items-center justify-center"
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon className="text-xl" icon={faTimes} />
              </button>
            </div>
            <div className="text-base flex space-x-2 items-center">
              <h1 className="leading-none text-primaryBlue">
                {recipe?.name || "Recipe name"}
              </h1>
              {recipe?.posId && (
                <div className="space-x-1">
                  <span>•</span>
                  <span className="text-gray-500">
                    {recipe?.posId?.toUpperCase() || "Recipe ID is missing"}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Content */}
          {loading === true && (
            <div className="flex justify-center items-center p-10 text-primaryBlue">
              <FontAwesomeIcon icon={faSpinner} spin className="text-6xl" />
            </div>
          )}
          {loading === false && (
            <div className="overflow-y-scroll overflow-x-hidden flex-1">
              {dishes.map((dish: any, dishIndex) => {
                // Locals
                const matchedCat = productCategories.find(
                  (c) => c.value === dish.category
                )

                return (
                  <Link
                    to={`/dashboard/products/cookbook/dishes/${dish.id}`}
                    key={`${dish.id}-${dishIndex}`}
                    onClick={() => setIsOpen(false)}
                  >
                    <div
                      className={`border-t first:border-b-0 last:border-b p-4 px-7 flex space-x-4 items-center cursor-pointer hover:bg-gray-100 transition-colors`}
                    >
                      <div className="relative">
                        <div className="w-11 h-11 border border-gray-300 rounded-full bg-white flex items-center justify-center overflow-hidden">
                          {dish.featuredFile ? (
                            <ServerImage
                              fileId={dish?.featuredFile?.fileId}
                            ></ServerImage>
                          ) : (
                            <FontAwesomeIcon
                              icon={faSalad}
                              className={`text-2xl text-primaryBlueLighter`}
                            />
                          )}
                        </div>
                        {matchedCat && (
                          <div
                            className="absolute -right-2 bottom-0 w-5 h-5 rounded-full bg-white flex justify-center items-center border"
                            style={{
                              fontSize: 10,
                            }}
                          >
                            <FontAwesomeIcon icon={matchedCat.icon} />
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="text-primaryBlue">
                          <span className="font-semibold text-base">
                            {dish.name}
                          </span>
                        </div>
                        <div className="flex space-x-2 items-center text-sm mt-1">
                          <span className="">{dish.category}</span>
                          {dish.posId && (
                            <div className="space-x-1">
                              <span>•</span>
                              <span className="text-gray-500">
                                {dish.posId?.toUpperCase() ||
                                  "PosId is missing"}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}{" "}
              {/* End Map */}
              {dishes.length === 0 && (
                <div className="flex justify-center items-center">
                  <h4 className="text-gray-500 p-8">
                    This recipe is not a part of any dish.
                  </h4>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    )
  )
}

export default DishListModal
